import React, { useState, useEffect, useRef } from 'react'
import FetchApi from '../../customhooks/functionFetchApi';
import PdfpartA from './viewdrarftcomponentpdf/pdfpartA';
import PdfpartB from './viewdrarftcomponentpdf/pdfpartB';
import PdfPrintingColor from './viewdrarftcomponentpdf/pdfprintingColor';
import PdfNewcolor from './viewdrarftcomponentpdf/pdfNewcolor';
import PdfNewItem from './viewdrarftcomponentpdf/pdfNewItem';
import PdfPartAll from './viewdrarftcomponentpdf/pdfPartAll';
import { useParams } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from 'react-bootstrap/Spinner';
import ReportJournalPDF from './reportpdf/reportJournalPDF';
import { PDFViewer } from '@react-pdf/renderer'

const DraftjournalPDFAll = () => {
    const FetchApis = new FetchApi()
    let { id } = useParams();
    const [key, setKey] = useState('jouranl');
    const [loadingdata, setLoadingdata] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoadingdata(false)
        }, 1300);
    }, [])


    return (
        <div className='form-body-page'>
            {loadingdata ? <div className='body-center-page'><Spinner
                as="span"
                animation="grow"
                variant="success"
                size={200}
                role="status"
                aria-hidden="true" /> LOADIN ....</div> :
                <form >
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="jouranl" title="TECHNICAL DATA SHEET A" style={{ backgroundColor: 'aliceblue' }}>
                            <PdfpartA idjour={id} />
                        </Tab>
                        <Tab eventKey="journaldetailpartb" title="TECHNICAL DATA SHEET B" style={{ backgroundColor: 'aliceblue' }}>
                            <PdfpartB idjour={id} />
                        </Tab>
                        <Tab eventKey="printingcolor" title="COLOR PRINTING SYSTEM" style={{ backgroundColor: 'aliceblue' }}>
                            <PdfPrintingColor idjour={id} />
                        </Tab>
                        <Tab eventKey='colorlist' title='เอกสารหมีกผสม แนบท้าย TDS By printing ' >

                            <PdfNewcolor journalId={id} />
                        </Tab>
                        <Tab eventKey='NewItem' title='Create New Item' >

                            <PdfNewItem jourId={id} />
                        </Tab>
                        <Tab eventKey='laodall' title='all part' >
                            <PdfPartAll jourId={id} typejour={'draft'}/>
                          
                        </Tab>

                    </Tabs>

                </form>}
        </div>
    )
}

export default DraftjournalPDFAll