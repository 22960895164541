import React, { useState, useEffect } from 'react'
import { Table, TD, TH, TR } from "@ag-media/react-pdf-table";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'
import FetchApi from '../../../../customhooks/functionFetchApi';
// import { styles } from "../../../../css/style";
import { color2, color6, color7, color8 } from '../../../printing/printingcomponent/objectcolor';

function Colorprinting1({ tecbid, sequenece }) {
    const FetchApis = new FetchApi();

    const [datacolor2, setDatacolor2] = useState(color2)
    const [datacolor6, setDatacolor6] = useState(color6)
    const [datacolor7, setDatacolor7] = useState(color7)
    const [datacolor8, setDatacolor8] = useState(color8)
    const[loading,setLoading]=useState(false)
  

  
    useEffect(()=>{
        getdatacolor2(tecbid) 

    },[])
    

    function getdatacolor2(tecbid) {

        FetchApis.FethcGet(`/reportjournal/getcolornewAll/${tecbid}`).then((res) => {
            if (res) {
             
           
            if(res.data.data.datacolorall2[0]!=null){
                setDatacolor2(res.data.data.datacolorall2[0])
            }
            if(res.data.data.datacolorall6[0]!=null){
                setDatacolor6(res.data.data.datacolorall6[0])
            }
            setLoading(true)
            }
        })
    }
    return (
        <>
               {loading==true ?<Table style={{ border: '0.5', width: '100%' }}>
                    <TR>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center', backgroundColor: '#e5e7e9' }} weighting={0.1}>
                            <Text style={{ fontWeight: 'bold' }}>#{sequenece} </Text>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                            <Text style={{ fontWeight: 'bold' }}>Color shade </Text>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD
                                style={{ border: '0' }}
                            >
                                <Text>{datacolor2.colorshqde_unit1}</Text>
                            </TD>
                            <TD style={{ border: '0' }}>
                                <Text>{datacolor2.colorshqde_unit2}</Text>
                            </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0' }}>
                               <Text>{datacolor6.colorshqde_unit1}</Text> 
                            </TD>
                            <TD style={{ border: '0' }}>
                               <Text>{datacolor6.colorshqde_unit2}</Text> 
                            </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0' }}>
                                {datacolor7.colorshqde_unit1}
                            </TD>
                            <TD style={{ border: '0' }}>
                                {datacolor7.colorshqde_unit2}
                            </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                            <Text>{datacolor8.colorshqde_unit1}</Text>
                        </TD>
                    </TR>

                    <TR>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                          
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                            <Text style={{ fontWeight: 'bold' }}>Item Id </Text>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0px solid #f3f4f6' }}>{datacolor2.Idcolor_unit1}</TD>
                            <TD style={{ border: '0px solid #f3f4f6' }}>{datacolor2.Idcolor_unit2}</TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0px solid #f3f4f6' }}>{datacolor6.Idcolor_unit1}</TD>
                            <TD style={{ border: '0px solid #f3f4f6' }}>{datacolor6.Idcolor_unit2}</TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0px solid #f3f4f6' }}>{datacolor7.Idcolor_unit1}</TD>
                            <TD style={{ border: '0px solid #f3f4f6' }}>{datacolor7.Idcolor_unit2}</TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                            <Text>{datacolor8.Idcolor_unit1}</Text>
                        </TD>
                    </TR>

                    <TR>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>

                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                            <Text style={{ fontWeight: 'bold' }}>FM. Code </Text>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0px solid #f3f4f6' }}>
                                {datacolor2.fmcode_unit1}
                            </TD>
                            <TD style={{ border: '0px solid #f3f4f6' }}>
                                {datacolor2.fmcode_unit2}
                            </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0px solid #f3f4f6' }}>
                                {datacolor6.fmcode_unit1}
                            </TD>
                            <TD style={{ border: '0px solid #f3f4f6' }}>
                                {datacolor6.fmcode_unit2}
                            </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0px solid #f3f4f6' }}>
                                {datacolor7.fmcode_unit1}
                                </TD>
                            <TD style={{ border: '0px solid #f3f4f6' }}>
                                {datacolor7.fmcode_unit2}
                                </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                            <Text>{datacolor8.fmcode_unit1}</Text>
                        </TD>
                    </TR>

                    <TR>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>

                        </TD>

                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                            <Text style={{ fontWeight: 'bold' }}>Thcikness / Density </Text>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0' }}>
                                {datacolor2.thicknes_unit1 ? <Text>{datacolor2.thicknes_unit1} / {datacolor2.density_unit1}</Text> : <Text></Text>}
                            </TD>
                            <TD style={{ border: '0' }}>
                                {datacolor2.thicknes_unit2 ? <Text>{datacolor2.thicknes_unit2} / {datacolor2.density_unit2}</Text> : <Text></Text>}
                            </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0' }}>
                                {datacolor6.thicknes_unit1 ? <Text>{datacolor6.thicknes_unit1} / {datacolor6.density_unit1}</Text> : <Text></Text>}
                            </TD>
                            <TD style={{ border: '0' }}>
                                {datacolor6.thicknes_unit2 ? <Text>{datacolor6.thicknes_unit2} / {datacolor6.density_unit2}</Text> : <Text></Text>}
                            </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0' }}>
                                {datacolor7.thicknes_unit1 ? <Text>{datacolor7.thicknes_unit1} / {datacolor7.density_unit1}</Text> : <Text></Text>}
                            </TD>
                            <TD style={{ border: '0' }}>
                                {datacolor7.thicknes_unit2 ? <Text>{datacolor7.thicknes_unit2} / {datacolor7.density_unit2}</Text> : <Text></Text>}
                            </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                            <Text>
                                {datacolor8.thicknes_unit1 ? <Text>{datacolor8.thicknes_unit1} / {datacolor8.density_unit1}</Text> : <Text></Text>}
                            </Text>
                        </TD>
                    </TR>

                    <TR>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>

                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                            <Text style={{ fontWeight: 'bold' }}>Temp / UV lamp </Text>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0' }}>
                                {datacolor2.temp_unit1 ? <Text>{datacolor2.temp_unit1} / {datacolor2.uvlamp_unit1}</Text> : <Text></Text>}
                            </TD>
                            <TD style={{ border: '0' }}>
                                {datacolor2.temp_unit2 ? <Text>{datacolor2.temp_unit2} / {datacolor2.uvlamp_unit2}</Text> : <Text></Text>}
                            </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0' }}>
                                {datacolor6.temp_unit1 ? <Text>{datacolor6.temp_unit1} / {datacolor6.uvlamp_unit1}</Text> : <Text></Text>}
                            </TD>
                            <TD style={{ border: '0' }}>
                                {datacolor6.temp_unit2 ? <Text>{datacolor6.temp_unit2} / {datacolor6.uvlamp_unit2}</Text> : <Text></Text>}
                            </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0' }}>
                                {datacolor7.temp_unit1 ? <Text>{datacolor7.temp_unit1} / {datacolor7.uvlamp_unit1}</Text> : <Text></Text>}
                            </TD>
                            <TD style={{ border: '0' }}>
                                {datacolor7.temp_unit2 ? <Text>{datacolor7.temp_unit2} / {datacolor7.uvlamp_unit2}</Text> : <Text></Text>}
                            </TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                            {datacolor8.temp_unit1 ? <Text>{datacolor8.temp_unit1} / {datacolor8.uvlamp_unit1}</Text> : <Text></Text>}
                        </TD>
                    </TR>

                    <TR>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>

                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                            <Text style={{ fontWeight: 'bold' }}>Speed (sph) </Text>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0' }}>{datacolor2.speed_unit1}</TD>
                            <TD style={{ border: '0' }}>{datacolor2.speed_unit2}</TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0' }}>{datacolor6.speed_unit1}</TD>
                            <TD style={{ border: '0' }}>{datacolor6.speed_unit2}</TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                            <TD style={{ border: '0' }}>{datacolor7.speed_unit1}</TD>
                            <TD style={{ border: '0' }}>{datacolor7.speed_unit2}</TD>
                        </TD>
                        <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                            <Text>{datacolor8.speed_unit1}</Text>
                        </TD>
                    </TR>
                </Table>:<View>
                    </View>}
        </>
    )
}

export default Colorprinting1