import React, { useState, useEffect } from 'react'
import PlanningAddspec from './planningAddspec'
import PreprassEditspec from './planningEditspec'
import FetchApi from '../../../customhooks/functionFetchApi'
import PlanApprove from './planApprove';

const Actionplanning = ({ journalId, codeid, stateflow }) => {
    const FetchApis = new FetchApi();
    const [checkdata, setCheckdata] = useState(false);

    useEffect(() => {
        checkdataplanning();
       
    }, [])


    async function checkdataplanning() {

        await FetchApis.FethcGet(`/specplanning/specplanningByJournalId/${journalId}`).then((res) => {
               
            if (res.data.data.length > 0) {
                setCheckdata(true)
            } else {
                setCheckdata(false)
            }
        })

    }
    return (
        <div>
            <div className='row-between-page'>
                <div>
                    {checkdata == true && <PlanApprove idjournal={journalId} stateflow={stateflow} />}</div>
                <div style={{ marginRight: 5 }}>
                    {/* {checkdata != true && <PlanningAddspec journalId={journalId} />} */}
                    <PlanningAddspec journalId={journalId} />
                </div>
                <div>
                    {checkdata == true && <PreprassEditspec journalId={journalId} stateflow={stateflow}/>}
                </div>
            </div>
        </div>
    )
}

export default Actionplanning