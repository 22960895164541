import DataTable from 'react-data-table-component';
import React from 'react'
// import Showdetailjournalpdf from '../../veiwpdf/showdetailjournalpdf';
import { FaFilePdf, FaFileSignature, FaCheckCircle } from "react-icons/fa";
import { AiOutlineFileDone,AiOutlineFileExclamation } from "react-icons/ai";
import Statusall from '../componentallstatus/statusall';
import ViewJournaldetail from './viewJournaldetail';
// import { Button } from 'react-bootstrap';
const columns = [
    {
        name: 'TDS code',
        sortable: true,
        grow: 1,
        selector: row => <div>
        <div>{row.tdscode} || v.{row.revision}</div>
        {row.salerunnumber!=""?<div>({row.salerunnumber})</div>:<div></div>}</div>,
    },
    // {
    //     name: 'Journal Type',
    //     selector: row => row.journaltype,
    // },
    {
        name: 'customer/product',
        grow: 2,
        selector: row => <div>
            <div>: {row.journaltype}</div>
            <div>: {row.customername}</div>
            <div>: {row.productname}</div>
        </div>
            ,
    },
    // {
    //     name: 'revision',
    //     selector: row => row.revision,
    // },
    {
        name: 'film code',
        selector: row => row.filmcode,
    },
    {
        name: 'status flow',
        sortable: true,
        selector: row => <Statusall id={row.journalId} statusNow={row.stateflow} stateSuccesfull={row.stateSuccesfull} />,
        grow: 1.5,
    },

    // {
    //     name: 'item sample',
    //     selector: row => row.itemsample == '1' ? <div><label style={{ color: 'orchid' }}><FaFileSignature /> มอบตัวอย่าง</label></div> : "",
    // },
    {
        name: 'job status',
        selector: row => <div>{row.statusprove_Tds == 1 && <label style={{ color: '#0e9a07' }}><FaCheckCircle /> proof job</label>}</div>,
    },
    {
        name: 'confirm job',
        selector: row =><div>{row.statusConfirm==1&&<label style={{color:'#0e9a07'}}><FaCheckCircle />Approve</label>}</div> ,
    },
    // {
    //     name: 'timeap prove',
    //     selector: row => row.timeapprove,
    //     grow: 1.5,
    // },
    {
        name: 'action',
        grow: 1.5,
        cell: row => <div className='row-page'>
            <ViewJournaldetail jourId={row.journalId} codeId={row.tdscode} />

            {row.stateflow > row.stateSuccesfull ?
                <div style={{ marginLeft: 10 }}>
                    <center>
                        <a href={`/journalPDFAll/${row.journalId}`}><AiOutlineFileDone size={25} color='#08ab54' /></a>
                    </center>
                </div> :
                <div>
                    <div  style={{ marginLeft: 10 }}>
                        <center>
                            <a href={`/draftjournalpdfAll/${row.journalId}`}><AiOutlineFileExclamation size={25} color='#EF5635' /></a>
                        </center>
                    </div>
                </div>
            }

        </div>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Tablelistallprovebyuser = ({ datatable }) => {
    var dataresult = datatable;
    return (
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Tablelistallprovebyuser