import React from 'react'
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';
// import { tableData, totalData } from "./data";
import { styles } from "../../../css/style";
// import checkboxicon from '../images/check-box_48127.png';

const ReportJournalPDF = () => {
  // var data1 = 'Revised Printing Design (งานไม่พิมพ์)';

  // const styles = StyleSheet.create({
  //   page: {
  //     flexDirection: 'row',
  //     backgroundColor: '#E4E4E4'
  //   },
  //   section: {
  //     margin: 10,
  //     padding: 10,
  //     flexGrow: 1
  //   }
  // });

  return (
        <Document>
          <Page size="A4" style={styles.page}>
            <View >
              <Text style={styles.textBoldfonts95}>Section #1</Text>
            </View>
            <View >
              <Text>Section #2</Text>
            </View>

            <Table style={{border:"1px"}}>
              <TH style={{border:"1px"}}>
                <TD >Header 1</TD>
                <TD  >Header 2</TD>
              </TH >
              <TR style={{border:"1px"}}>
                <TD >Data 1</TD>
                <TD >Data 2</TD>
              </TR>
            </Table>
          </Page>
        </Document>
  )
}

export default ReportJournalPDF