import DataTable from 'react-data-table-component';
import React from 'react'
import Actionall from './actionall';
import Statusall from './statusallByuser';
import { FaFileSignature, FaCheckCircle } from "react-icons/fa";
import Flowuserapprove from '../componentallstatus/flowuserapprove';

const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row =><div>
        <div>{row.tdscode} || v.{row.revision}</div>
        {row.salerunnumber!=""?<div>({row.salerunnumber})</div>:<div></div>}</div>,
    },
    // {
    //     name: 'Journal Type',
    //     selector: row => row.fname,
    // },
    {
        name: 'customer / product',
        grow: 2,
        selector: row =><div>
            <div>: {row.fname}</div>
           <div>: {row.customername}</div> 
           <div>: {row.productname}</div>
            </div>,
    },
    // {
    //     name: 'revision',
    //     selector: row => row.revision,
    //     grow: 0.5,
    // },
    {
        name: 'status all',
        selector: row =>
            <Statusall id={row.journalId} statusflownow={row.statusflow}
                stateflow={row.stateflow} stateSuccesfull={row.stateSuccesfull} />,
        grow: 1,
    },
    {
        name: 'รออนุมัติ',
        grow: 1.5,
        selector: row => row.flowSuccesfull != "1" ? <Flowuserapprove 
        id={row.journalId} 
        stateflow={row.stateflow} 
        departments={row.departments}
        accountapprove={row.accountapprove}
        /> :"",
    },
    // {
    //     name: 'item sample',
    //     selector: row => <div>
    //         <div>{row.itemsample=='1'&&<label style={{color:'orchid'}}><FaFileSignature/> ส่งมอบ</label>}</div>

    //         </div>,
    // },
    {
        name: 'confirm job',
        selector: row => <div>
            <div>{row.statusConfirm == '1' ? <label style={{ color: 'green' }}><FaCheckCircle /> Approve </label> : <label></label>}</div>
        </div>,
    },
    {
        name: 'createdate',
        sortable: true,
        grow: 1,
        selector: row => row.createdate,
    },
    {
        name: 'Action',
        grow: 1,
        cell: row => <Actionall id={row.journalId} journalcode={row.tdscode}
            stateflow={row.stateflow}
            stateSuccesfull={row.stateSuccesfull}
            revision={row.revision}
        />,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Jornallistbyuser = (datatable) => {
    var dataresult = datatable.datatable;

    return (

        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Jornallistbyuser