import React,{ useState, useEffect } from 'react'
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'
import { Table, TD, TH, TR } from "@ag-media/react-pdf-table";

import { styles } from "../../../../css/style";
import FetchApi from'../../../../customhooks/functionFetchApi';

const CreteNewItem = (props) => {
  const FetchApis =new FetchApi();

   const [itemproductall, setItemproductall] = useState([])
    const[jouranlheader,setJournalhead]=useState([])
 
   useEffect(() => {
     CheckItemCreate()
     getjournalbyid()
   }, [])
 
   function CheckItemCreate() {
 
     FetchApis.FethcGet(`/newitem/getnewitemByJournalId/${props.jourId}`).then((res) => {
     
       if (res.status == 200) {
         if (res.data.data.length > 0) {
           var itemlist = res.data.data;
         
 
           setItemproductall(itemlist);
         }
       }
     })
   }

   function getjournalbyid() {
    FetchApis.FethcGet(`/journal/journalbyId/${props.jourId}`).then(res => {
        if (res) {
            setJournalhead(res.data.data[0])
        }

    })
}

  return (
   
      // <Document>
        // <Page size="A4" orientation="landscape" style={styles.page}>
        <>
          <View style={[styles.textBoldfonts9, { width: '100%' }]}>
            <Table style={{ marginBottom: 5,border:'0.5' }}>
              <TH>
                <TD weighting={0.6} style={[{ flexDirection: 'column', justifyContent: 'center' }]}>
                  <Text style={{ fontWeight: 'bold', fontSize: 12 }}>TECHNICAL DATA SHEET PART ( A ) </Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12 }}>Create New Item List </Text>
                </TD>
                <TD weighting={0.2}>
                  <Text style={{ marginLeft: 3 }}>Ref. MK. NO : {jouranlheader.tdscode}</Text>
                </TD>
                <TD weighting={0.2}>
                  <Text style={{ marginLeft: 3 }}>Date : {jouranlheader.createdatetime}</Text>
                </TD>
              </TH>
            </Table>
            <View>
              <Text style={{ fontWeight: 'bold' }}>รายการ New Item {itemproductall.length} รายการ</Text>
              <Table style={{border:'0.5'}}>
                <TH>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>#</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>Body Type</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>Type Item</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>Item Group</TD>

                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>Item</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>Item Name</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>Dimension Group</TD>

                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>Packing Group</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>RAF Jouranl</TD>
                  <TD style={{ justifyContent: 'center', flexDirection: 'column' }} weighting={0.1}>
                    <Text>Serail Number </Text>
                    <Text>Qty / Tag</Text></TD>

                  <TD style={{ justifyContent: 'center', flexDirection: 'column' }} weighting={0.1}>
                    <Text> Inventory </Text>
                    <Text> Unit </Text></TD>
                  <TD style={{ justifyContent: 'center', flexDirection: 'column' }} weighting={0.1}>
                    <Text>Purchase</Text>
                    <Text> Unit</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center', flexDirection: 'column' }} weighting={0.1}>
                    <Text> Sele </Text>
                    <Text> Unit </Text>
                  </TD>
                </TH>
                {itemproductall.map((item, i) => (
                  <TR>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>{i+1}</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>{item.bodytype}</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>{item.typeItem}</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>{item.itemegroup}</TD>

                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>{item.itemId}</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>{item.itemname}</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>{item.dimensiongroup}</TD>

                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>{item.packinggroup}</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>{item.raf_journalname}</TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{item.qtypertag}</Text>
                  </TD>

                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text> {item.inventtoryUnit} </Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text> {item.purchaseUnit}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{item.saleUnit}</Text>
                  </TD>
                </TR>
                ))
                }
              </Table>

            </View>

          </View>

          <Text style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
            )} fixed />
        </>

      // </Document>
  )
}

export default CreteNewItem