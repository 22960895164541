import React, { useState, useEffect, useRef } from 'react'
// import Showdetailjournal from '../../../form01/components/showdetailjournal';
import AllDetailpartb from '../../components/allDetailpartb';
import generatePDF from 'react-to-pdf';
import { Button } from 'react-bootstrap';
import FetchApi from '../../../customhooks/functionFetchApi';
import { FaFileDownload } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { PDFViewer, Document } from '@react-pdf/renderer'
import Reportv3 from '../reportpdf/reportv3';

function PreViewPrinting({ id }) {
    return (
        <div style={{ height: 750 }}>
            <PDFViewer width="100%" height="100%" >
                <Document>
                    <Reportv3 jourId={id} />
                </Document>
            </PDFViewer>
        </div>
    )
}

const PdfpartB = ({ idjour }) => {
    const targetRef = useRef();
    const FetchApis = new FetchApi();
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);



    function getjournalbyid() {
        FetchApis.FethcGet(`/journal/journalbyId/${idjour}`).then(res => {
            if (res) {
                let tdscodeid = res.data.data[0].tdscode;

                generatePDF(targetRef, { filename: `${tdscodeid}_partB_page.pdf` })
            }

        })
    }

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }

    return (
        <div>
            <Button onClick={() => handleShow()} size='sm' variant="light"><FaFileDownload /> Preview Printing</Button>
            <div ref={targetRef}>
                <AllDetailpartb journalId={idjour} />
                <img src={require('../../../images/draft-icon-27.jpg')} style={{ width: 5, height: 'auto', borderRadius: 3 }} />
            </div>

            <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview part B</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <PDFViewer width="100%" height="100%"> */}
                    <PreViewPrinting id={idjour} />
                    {/* </PDFViewer> */}


                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PdfpartB