import React, { useState, useEffect, useRef } from 'react'

import generatePDF from 'react-to-pdf';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from "react-icons/fa";
import ViewNewItem from '../../components/viewNewItem';
import FetchApi from '../../../customhooks/functionFetchApi';

import Modal from 'react-bootstrap/Modal';
import { PDFViewer, Document } from '@react-pdf/renderer'
import Reportv6 from '../reportpdf/reportv6';

function PreViewPrinting({ id }) {
    return (
        <div style={{ height: 750 }}>
            <PDFViewer width="100%" height="100%" >
                <Document>
                    <Reportv6 jourId={id} />
                </Document>
            </PDFViewer>
        </div>
    )
}



const PdfNewItem = ({ jourId }) => {

    const targetRef = useRef();
    const FetchApis = new FetchApi();

    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function getjournalbyid() {
        FetchApis.FethcGet(`/journal/journalbyId/${jourId}`).then(res => {
            if (res) {
                let tdscodeid = res.data.data[0].tdscode;

                generatePDF(targetRef, { filename: `${tdscodeid}_NewItem_page.pdf` })
            }

        })
    }

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }

    return (
        <div>
            <Button onClick={() => handleShow()} size='sm' variant="light">
                <FaFileDownload /> PreVeiw Printing</Button>
            <div ref={targetRef}>
                <ViewNewItem jourId={jourId} />
                <img src={require('../../../images/draft-icon-27.jpg')} style={{ width: 50, height: 'auto', borderRadius: 3 }} />
            </div>
            <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview part B (Color System printing)</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <PDFViewer width="100%" height="100%"> */}
                    <PreViewPrinting id={jourId} />
                    {/* </PDFViewer> */}


                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PdfNewItem