import React, { useState } from "react";
import axios from "axios";
// export const host = `http://localhost:3001/api/tds`

 export  const host = `http://swannet.net/tds/call/api/tds` 
// http://swannet.net/tds/call/api/tds/account/accountall
export default class FetchApi {

    FethcPost(url, values) {
        return axios.post(host + url, values).then((response) => {
            if (response.data) {
                return response.data;
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    FethcPartbPost(url, sequences,otherdetail, tdscodes,journalId, _body, _top, _bottom) {

        const dataresult = {
            sequence: sequences,
                bodyside: _body.side,
                bodycolor: _body.color,
                bodyfm: _body.fm,
                bodysolid:_body.solid,
                bodydfw_min:_body.dfw_min,
                bodydfw_max:_body.dfw_max,
                bodywfw:_body.wfw,
                bodytemp: _body.temp,
                bodyspeed: _body.speed,
                bodyrubber:_body.rubber,
                    topside: _top.side,
                    topcolor: _top.color,
                    topfm: _top.fm,
                    topsolid:_top.solid,
                    topdfw_min:_top.dfw_min,
                    topdfw_max:_top.dfw_max,
                    topwfw:_top.wfw,
                    toptemp: _top.temp,
                    topspeed: _top.speed,
                    toprubber:_top.rubber,
                        bottomside: _bottom.side,
                        bottomcolor: _bottom.color,
                        bottomfm: _bottom.fm,
                        bottomsolid:_bottom.solid,
                        bottomdfw_min:_bottom.dfw_min,
                        bottomdfw_max:_bottom.dfw_max,
                        bottomwfw:_bottom.wfw,
                        bottomtemp: _bottom.temp,
                        bottomspeed: _bottom.speed,
                        bottomrubber:_bottom.rubber,
                            rfFgbody:_body.rfFgbody,
                            rfFgtop:_body.rfFgtop,
                            rfFgbottom:_body.rfFgbottom,
                                otherdetail:otherdetail,
                                journalId:journalId,
                                tdscode: tdscodes
        }
            return axios.post(host + url, dataresult).then((response) => {
                if (response.data) {
                    return response.data;
                }
            }).catch((err) => {
                console.log(err)
            })
        

    }
    
    FethcPartbPut(url,tdscodes,otherdetail, _body, _top, _bottom){

        const dataresult = {
            bodyside: _body.side,
            bodycolor: _body.color,
            bodyfm: _body.fm,
            bodysolid:_body.solid,
            bodydfw_min:_body.dfw_min,
            bodydfw_max:_body.dfw_max,
            bodywfw:_body.wfw,
            bodytemp: _body.temp,
            bodyspeed: _body.speed,
            bodyrubber:_body.rubber,
                topside: _top.side,
                topcolor: _top.color,
                topfm: _top.fm,
                topsolid:_top.solid,
                topdfw_min:_top.dfw_min,
                topdfw_max:_top.dfw_max,
                topwfw:_top.wfw,
                toptemp: _top.temp,
                topspeed: _top.speed,
                toprubber:_top.rubber,
                    bottomside: _bottom.side,
                    bottomcolor: _bottom.color,
                    bottomfm: _bottom.fm,
                    bottomsolid:_bottom.solid,
                    bottomdfw_min:_bottom.dfw_min,
                    bottomdfw_max:_bottom.dfw_max,
                    bottomwfw:_bottom.wfw,
                    bottomtemp: _bottom.temp,
                    bottomspeed: _bottom.speed,
                    bottomrubber:_bottom.rubber,
                    otherdetail:otherdetail,
                    tdscode: tdscodes
        }
        // console.log(dataresult)

        return axios.put(host + url, dataresult).then((response) => {
            if (response.data) {
                return response.data;
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    FethcGet(url) {

        return axios.get(host + url)
            .then((response) => {
                return response
            })
            .catch((err) => {

            })
            .finally(() => {

            });
    }


    FethcDelete(url) {

        return axios.delete(host + url)
            .then((response) => {
                return response
            })
            .catch((err) => {

            })
            .finally(() => {

            });
    }

    FethcUpdate(url, values) {

        return axios.put(host + url, values).then((response) => {
            if (response.data) {
                return response.data;
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    FetchUploadfile(url,values){

        const fd = new FormData();
        var newFileName = `${Date.now()}`+values.name;
        fd.append('file', values, newFileName);
      
        return fetch(host + url, {
          method: 'POST',
          body: fd
        }).then(res => res.json())
          .then(json => { return json })
          .catch(err => console.log(err));
    }

}