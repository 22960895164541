import React, { useState, useEffect } from 'react'
import FetchApi from '../../../customhooks/functionFetchApi';
import Dropdown from 'react-bootstrap/Dropdown';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Spinner from 'react-bootstrap/Spinner';


function Viewdetail({ datalist }) {
    return(
      <div>
        <table style={{width:'100%'}}>
          <tr>
            <th style={{width:'10%'}}>ลำดับ</th>
            <th style={{width:'20%'}}>ชื่อ ผู้อนุมัติ</th>
            <th style={{width:'20%'}}>แผนก</th>
            <th style={{width:'15%'}}>ผ่านอนุมัติ</th>
            <th style={{width:'15%'}}>สถานะ</th>
            <th style={{width:'30%'}}>เวลา</th>
          </tr>
        {datalist.map((item,i)=>(
          <tr style={{fontSize:12}}>
            <td>{i+1}</td>
            <td>{item.approveby}</td>
            <td>{item.departments}</td>
            <td>ขั้นตอน {item.stateflow}</td>
            <td>{item.active==0?<label style={{color:'red'}}>รออนุมัติ</label>:<label style={{color:'green'}}>อนุมัติแล้ว</label>}</td>
            <td>{item.timecommit}</td>
          </tr>
        ))}
        </table>
      </div>
    )
  
  }


const Flowuserapprove = (props) => {
    const FetchApis = new FetchApi();
    const [userlist, setUserlist] = useState([])
    const [show, setShow] = useState(false);
    const[loading,setLoading]=useState(true);

      const [journallist, setJournallist] = useState([])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        
        setLoading(true)
        historyapprove(props.id)
        setTimeout(() => {
            // listAccount()
            setLoading(false)
        }, 1000);
       
    }, [props])

    function listAccount() {
        handleShow()

        // setLoading(true)
        var dataobject = {
            journalId: props.id,
            stateflow: props.stateflow
        }

        FetchApis.FethcPost(`/runflow/getRunflowUserApprove`, dataobject).then((res) => {
            if(res){
                // list data history arpprove by id journal
                historyapprove(props.id)

                setUserlist(res.data)
                setTimeout(() => {
                        // setLoading(false)
                }, 500);
            }

        })
    }

    function historyapprove(idjour){
      
            FetchApis.FethcGet(`/journal/getStatejournalnow/${idjour}`).then((res) => {
              if (res) {
                setJournallist(res.data.data)
              }
        
            })     
    }

    return (<>
  
        {props.accountapprove != null &&<div>
            {loading ==true?<div><Spinner animation="border" variant="info" /></div>:
            <Dropdown>
                <Dropdown.Toggle variant="warning" id="dropdown-basic" size='sm' onClick={()=>listAccount()}>
                    <label style={{ fontSize: 10 }}>{props.accountapprove + "(" + props.departments + ")"}</label>
                </Dropdown.Toggle>
            </Dropdown>}
            
            </div>}
        
        <Modal show={show} onHide={handleClose}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title>รออนุมัติ แสดงรายชื่อผู้อนุมัติ</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <table style={{width:'100%'}}>
                        <tr>
                            <th>ชื่อ</th>
                            <th>แผนก</th>
                        </tr>
                        {userlist.map((item, i) => (

                            <tr>
                                <td>{item.approveby}</td>
                                <td>{item.department}</td>
                            </tr>

                        ))}
                    </table>
                    <hr/>
                    <div>ประวัติอนุมัติ</div>
                    {journallist.length>0&&<Viewdetail datalist={journallist}/>}
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {/* <Button variant="primary" onClick={handleClose}>
                Save Changes
            </Button> */}
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Flowuserapprove