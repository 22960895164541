import React, { useEffect, useState } from 'react'
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'
import { Table, TD, TH, TR } from "@ag-media/react-pdf-table";
import FetchApi from '../../../../customhooks/functionFetchApi';
import { styles } from "../../../../css/style";
import Newcolerprinting from './../component/newcolerprinting';

const Addnewcolorprinting = (props) => {
  const FetchApis = new FetchApi();
  // var data1 = 'Revised Printing Design (งานไม่พิมพ์)'
  const [JourColorHeader, setJourColorHeader] = useState([])
  const [jouranlheader, setJournalhead] = useState([])

  useEffect(() => {
    getColorhaeader()
    getjournalbyid();
  }, [])

  function getColorhaeader() {

    FetchApis.FethcGet(`/productcolor/getproductByjournal/${props.jourId}`).then((res) => {

      setJourColorHeader(res.data.data)
      // console.log(res.data.data);
    })

  }

  function getjournalbyid() {
    FetchApis.FethcGet(`/journal/journalbyId/${props.jourId}`).then(res => {
      if (res) {
        setJournalhead(res.data.data[0])
      }

    })
  }

  return (
    <>
      <View style={[styles.textBoldfonts10, { width: '100%' }]}>
        <Table style={{ marginBottom: 5, border: '0.5' }}>
          <TH>
            <TD weighting={0.6} style={[{ flexDirection: 'column', justifyContent: 'center' }]}>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>TECHNICAL DATA SHEET PART ( B ) </Text>
              <Text style={{ fontWeight: 'bold', fontSize: 12 }}>เอกสารหมีกผสม แนบท้าย Printing </Text>
            </TD>
            <TD weighting={0.2}>
              <Text style={{ marginLeft: 3 }}>Ref. MK. NO : {jouranlheader.tdscode}</Text>
            </TD>
            <TD weighting={0.2}>
              <Text style={{ marginLeft: 3 }}>Date : {jouranlheader.createdatetime}</Text>
            </TD>
          </TH>
        </Table>
        <View>

          {JourColorHeader.map((item, i) => (
            <View>
              <Text style={{ fontWeight: 'bold', backgroundColor: '#e5e7e9' }}>{i + 1}.Color :{item.colorname}</Text>
              <Newcolerprinting colorId={item.colorId} />
              <Text style={{ fontWeight: 'bold' }}>Remarks : {JourColorHeader.remark} </Text>
            </View>
          ))
          }

        </View>
      </View>

      <Text style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
    </>


  )
}

export default Addnewcolorprinting