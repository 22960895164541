import React, { useState, useEffect, useRef } from 'react'
import PdfpartAsuccess from '../viewSuccessfull/pdfpartAsuccess'
import Showdetailjournal from '../../../form01/components/showdetailjournal';
import AllDetailpartb from '../../components/allDetailpartb';
import FetchApi,{host} from '../../../customhooks/functionFetchApi';
import Colordetaillist from '../../components/colordetaillist';
import ViewColorlist from '../../components/viewColorlist';
import ViewNewItem from '../../components/viewNewItem';
import generatePDF from 'react-to-pdf';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import { styles } from "../../../css/style";
import { PDFViewer, Document, Page, Text } from '@react-pdf/renderer';
import Journalparta from '../reportpdf/component/journalparta';
import Journalpartb from '../reportpdf/component/journalpartb';
import Systemprinting from '../reportpdf/component/systemprinting';
import Addnewcolorprinting from '../reportpdf/component/addnewcolorprinting';
import CreteNewItem from '../reportpdf/component/creteNewItem';

// import Reportv4 from '../reportpdf/reportv4';


function PreViewPrinting(props) {


    return (
        <div style={{ height: 750 }}>
            <PDFViewer width="100%" height="100%" >
                <Document>
                    <Page size="A4" style={styles.page}>
                        <Journalparta jourId={props.id} />
                    </Page>
                    {props.checkdata==true&&<Page size="A4" style={styles.page}>
                        <Journalpartb jourId={props.id} />
                    </Page>}
                    {props.checkdata==true&&<Page size="A4" orientation="landscape"  style={styles.page}>
                    <Systemprinting jourId={props.id} />
                    </Page>}
                    {props.checkProductcolor==true&&<Page size="A4" orientation="landscape"  style={styles.page}>
                        <Addnewcolorprinting jourId={props.id} />
                    </Page>}
                    {props.checkItemNew==true&&<Page size="A4" orientation="landscape"  style={styles.page}>
                        <CreteNewItem jourId={props.id} />
                    </Page>}
                </Document>
            </PDFViewer>
        </div>
    )
}



const objectPartB = {
    tecbid: "", sequence: "", bodyside: "", bodycolor: "", bodyfm: "", bodydfw: "",
    bodywfw: "", bodytemp: "", bodyspeed: "", bodyrubber: "", topside: "", topcolor: "",
    topfm: "", topdfw: "", topwfw: "", toptemp: "", topspeed: "", toprubber: "",
    bottomside: "", bottomcolor: "", bottomfm: "", bottomdfw: "", bottomwfw: "", bottomtemp: "", bottomspeed: "", bottomrubber: "",
    otherdetail: "",
    tdscode: ""
}

const PdfPartAll = ({ jourId, typejour }) => {
    const FetchApis = new FetchApi()
    const targetRef = useRef();

    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [datafetch, setDatafetch] = useState([]);

    const [coanting1st, setCoanting1st] = useState(objectPartB);
    const [coanting2nd, setCoanting2nd] = useState(objectPartB);
    const [coanting3rd, setCoanting3rd] = useState(objectPartB);
    const [coanting4th, setCoanting4th] = useState(objectPartB);
    const [coanting5th, setCoanting5th] = useState(objectPartB);
    const [coanting6th, setCoanting6th] = useState(objectPartB);
    const [coanting7th, setCoanting7th] = useState(objectPartB);
    const [checkdata, setCheckdata] = useState(false);
    const [checkProductcolor, setCheckProdcutcolor] = useState(false)
    const [checkItemNew, setCheckItemNew] = useState(false)

    useEffect(() => {
        checkTechnical()
        getcheckProductcolor()
        getcheckItem()
        getFileNameAll()
    }, [])

    function checkTechnical() {
        FetchApis.FethcGet(`/technical/techniaclByJournalId/${jourId}`).then((res) => {

            if (res.status == 200) {
                if (res.data.data.length != 0) {

                    Coatinglistbytype(res.data.data)
                    setCheckdata(true)
                }

            }
        })
    }

    function getcheckProductcolor() {
        FetchApis.FethcGet(`/productcolor/getproductByjournal/${jourId}`).then((res) => {
            if (res) {
                let colorallss = res.data.data;
                if (colorallss.length >= 1) {
                    setCheckProdcutcolor(true)
                }
            }
        })
    }

    function getcheckItem() {
        FetchApis.FethcGet(`/newitem/getnewitemByJournalId/${jourId}`).then((res) => {

            if (res.status == 200) {
                if (res.data.data.length > 0) {

                    setCheckItemNew(true)

                }
            }
        })
    }

    function getFileNameAll() {
        FetchApis.FethcGet(`/journal/journalbyId/${jourId}`).then((res) => {

            if (res.data.status == 200) {
                setDatafetch(res.data.data[0])
            }
        })
        
    }


    function Coatinglistbytype(datalist) {
        const coating1st = datalist.find((item) => item.sequence == '1st');
        setCoanting1st(coating1st);

        const coating2nd = datalist.find((item) => item.sequence == '2nd');
        setCoanting2nd(coating2nd);

        const coating3rd = datalist.find((item) => item.sequence == '3rd');
        setCoanting3rd(coating3rd);

        const coating4th = datalist.find((item) => item.sequence == '4th');
        setCoanting4th(coating4th);

        const coating5th = datalist.find((item) => item.sequence == '5th');
        setCoanting5th(coating5th);

        const coating6th = datalist.find((item) => item.sequence == '6th');
        setCoanting6th(coating6th);

        const coating7th = datalist.find((item) => item.sequence == '7th');
        setCoanting7th(coating7th);
    }

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }
    return (
        <div style={{ width: '100%' }}>
            <Button onClick={() => handleShow()} size='sm' variant="light"><FaFileDownload /> PreView Printing</Button>
            
            {datafetch.uploadfilename !=""&&
            <a href={host + `/images/files/${datafetch.uploadfilename}`}  >
            <Button variant="light" size='sm' style={{marginLeft:10}}>
            <FaFileDownload /> 
            <label>Load file acttench</label>                                         
            </Button>
            </a> }

            <div ref={targetRef}>
                <div style={{ height: 1800, padding: 10 }}>
                    <hr></hr>
                    <div className='end-flex-row'>
                        {typejour != 'draft' ? <img src={require('../../../images/approve_8622624.png')} style={{ width: 50, height: 'auto', borderRadius: 3 }} /> :
                            <img src={require('../../../images/draft-icon-27.jpg')} style={{ width: 50, height: 'auto', borderRadius: 3 }} />}
                    </div>
                    <Showdetailjournal id={jourId} />
                </div>
                {checkdata == true && <div style={{ height: 1800, padding: 10 }} >
                    <hr></hr>
                    <AllDetailpartb journalId={jourId} />
                </div>}
                {checkdata == true &&
                    <div style={{ height: 1800, padding: 10 }}>
                        <hr></hr>
                        <b style={{ fontSize: 16 }}>System Color </b>
                        <Colordetaillist tecbid={coanting1st.tecbid} typetecb={'1st'} />
                        <Colordetaillist tecbid={coanting2nd.tecbid} typetecb={'2nd'} />
                        <Colordetaillist tecbid={coanting3rd.tecbid} typetecb={'3rd'} />
                        <Colordetaillist tecbid={coanting4th.tecbid} typetecb={'4th'} />
                        <Colordetaillist tecbid={coanting5th.tecbid} typetecb={'5th'} />
                        <Colordetaillist tecbid={coanting6th.tecbid} typetecb={'6th'} />
                        <Colordetaillist tecbid={coanting7th.tecbid} typetecb={'7th'} />
                    </div>}
                {checkProductcolor == true && <div style={{ height: 1800, padding: 10 }}>
                    <hr></hr>
                    <ViewColorlist journalId={jourId} />
                </div >}

                {checkItemNew == true && <div style={{ height: 1800, padding: 10 }}>
                    <hr></hr>
                    <ViewNewItem jourId={jourId} />
                </div>}

            </div>

            <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview Printing All</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <PreViewPrinting
                        id={jourId}
                        checkdata={checkdata}
                        checkProductcolor={checkProductcolor}
                        checkItemNew={checkItemNew}
                    />
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default PdfPartAll