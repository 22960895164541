import React, { useState, useEffect } from 'react'
import Tablejournalallstatus from './components/componentallstatus/tablejournalallstatus'
import { Button } from 'react-bootstrap';
import FetchApi from '../customhooks/functionFetchApi';
import Spinner from 'react-bootstrap/Spinner';

import { FaList, FaFileSignature, FaArchive, FaExclamationTriangle, FaCheck } from "react-icons/fa";

const Journalallstatus = () => {
    const FetchApis = new FetchApi();
    const emcode = sessionStorage.getItem('EmCode');

    const [loading, setLoading] = useState(false);
    const [datajournal, setDatajournal] = useState([]);
    const [journalList, setJournalList] = useState([]);


    useEffect(() => {
        loadingdata();
        // sumjournallist()
    }, [])


    const loadingdata = () => {
        setLoading(true)

        setTimeout(() => {
            FetchApis.FethcGet('/journal/journalbyStatusall').then((resultdata) => {
                if (resultdata) {
                    setDatajournal(resultdata.data.data);
                   
                    setJournalList(resultdata.data.data);
                    if(resultdata.data.data.length!=0){
                        
                    }
                    

                }
                setLoading(false);
                
            })
        }, 1000);

    }

    //ค้นหาข้อมูลทั้งหมด
    function heandleSearch(e) {
        const { value, name } = e.target;

        const filteredData = datajournal.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
        })

        setJournalList(filteredData)
    }

    function filterSuccesfull(e){
        setLoading(true)
        const filterData = datajournal.filter((item) => item.flowSuccesfull == '1');
        setJournalList(filterData)
    
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      }
    
      function filterNotSuccesfull(e){
        setLoading(true)
        const filterData = datajournal.filter((item) => item.flowSuccesfull == null);
       
        setJournalList(filterData)
    
        setTimeout(() => {
          setLoading(false)
        }, 1000);
       
      }
    
      function allListData() {
        setLoading(true)
        setJournalList(datajournal)
    
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      }

      const  sumapprove = datajournal.filter((item) => item.flowSuccesfull == '1');
        const sumpreapprove = datajournal.filter((item) => item.flowSuccesfull == null);

    return (
        <div>
            <div className='row-between-page' style={{ backgroundColor: '#E5E7E7' }}>
                <div >
                    <label style={{ fontSize: 20, margin: 5, padding: 5 }}>
                        <FaFileSignature /> หน้ารวมสถานะทั้งหมด ( {datajournal.length} )</label>
                    <div className='row-page'>
                        <div><label style={{ fontSize: 14, color: 'blue', margin: 5 }}><Button onClick={() => allListData()}><FaList /> เอกสารทั้งหมด ( {datajournal.length} )</Button></label></div>
                        <div><label style={{ fontSize: 14, color: 'green', margin: 5 }}> <Button variant="success" onClick={() => filterSuccesfull()}><FaCheck /> อนุมัติแล้ว ( {sumapprove!=null&&sumapprove.length} )</Button></label></div>
                        <div><label style={{ fontSize: 14, color: 'orange', margin: 5 }}><Button variant="warning" onClick={() => filterNotSuccesfull()}><FaArchive /> รออนุมัติ ( {sumpreapprove !=null&&sumpreapprove.length} )</Button></label></div>
                    </div>
                </div>
                <div>
                    <input type='text' name='TextSearch' placeholder='ค้นหาข้อมูล' onChange={(e) => heandleSearch(e)} />
                </div>
            </div>

            <div>
                {loading == true ? <div className='body-center-page'>
                    <Spinner
                    as="span"
                    animation="grow"
                    variant="success"
                    size={200}
                    role="status"
                    aria-hidden="true" /> LOADIN ....</div> : <div>
                    <Tablejournalallstatus datatable={journalList} />
                </div>}
            </div>
        </div>
    )
}

export default Journalallstatus