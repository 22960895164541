import React, { useState, useEffect } from 'react';
import { AiOutlineExclamationCircle,AiOutlineFileProtect } from "react-icons/ai";
import Badge from 'react-bootstrap/Badge'; 
import {
    FaTh,
    FaBars,
    FaFileInvoice,
    FaFileSignature,
    FaAlignLeft,
    FaTasks,
    FaFileMedical,
    FaReply,
    FaListUl,
    FaFolderOpen,
    FaClipboardCheck,
    FaHandsHelping,
    FaChartBar,
    FaUserPlus,
    FaClipboardList
}
    from "react-icons/fa";
    import { LuFileEdit } from "react-icons/lu";

import { NavLink } from 'react-router-dom';
import FetchApi from './customhooks/functionFetchApi';


const Sidebar = ({ children, datastatus }) => {
    
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    const FetchApis = new FetchApi();
    const Emcode = sessionStorage.getItem("EmCode");
    const [getMenuList, setGetMenuList] = useState([]);
   

    var arrey = [];
    var menuItem = [];
    menuItem = [
        {
            path: "/journalallstatus",
            name: "เอกสารทั้งหมด",
            icon: FaTh,
            active:``
        },
        {
            path: "/reportstatusjournalall",
            name: "สถานะรอรับเอกสาร",
            icon: FaChartBar,
            active:``
        },
        {
            path: "/journalmanagement",
            name: "เอกสารของฉัน",
            icon: FaListUl,
            active:``
        },
        {
            path: "/journallistrecieved",
            name: `เอกสารใหม่`,
            icon: FaFolderOpen,
            active:`${datastatus.preRecievd}`
        },
        {
            path: "/journallistconfirmproduct",
            name: `เอกสาร comfirm`,
            icon: FaFolderOpen,
            active:`${datastatus.preconfirm}`
        },
        {
            path: "/journallistbyapprove",
            name: `รออนุมัติ`,
            icon: FaClipboardCheck,
            active:`${datastatus.preReSend}`
        },
        {
            path: "/journallistrejectstatus",
            name: `เอกสารตีกลับ `,
            icon: FaReply,
            active:`${datastatus.preReject}`
        },
        {
            path: "/journalrejectdetail",
            name: `ฉันตีกลับเอกสาร`,
            icon: FaClipboardList,
            active:`${datastatus.CountReject}`
        },
        {
            path: "/journalrecallstatus",
            name: `แจ้งแก้ไขข้อมูล `,
            icon: LuFileEdit,
            active:`${datastatus.recallsend}`
        },
        {
            path: "/journallistdedicate",
            name: `อนุมัติแทน`,
            icon: FaHandsHelping,
            active:`${datastatus.countdedicate}`
        },
        {
            path: "/newjournal",
            name: "สร้างเอกสาร",
            icon: FaFileMedical,
            active:`${datastatus.newjournal}`
        },
        {
            path: "/journallistparta",
            name: `เอกสารร่าง`,
            icon: FaFileSignature,
            active:`${datastatus.draftjournal}`
        },
        {
            path: "/journallistprepass",
            name: `เอกสาร Prepress`,
            icon: FaFileSignature,
            active:`${datastatus.preReSend}`
        },
        {
            path: "/journallistaddspec",
            name: `เอกสาร Planning`,
            icon: FaAlignLeft,
            active:`${datastatus.preReSend}`
        },
        {
            path: "/journallistnewpartb",
            name: `เอกสาร RD `,
            icon: FaFileInvoice,
            active:`${datastatus.preReSend}`
        },
        {
            path: "/journallistfactory1",
            name: `เอกสาร Factory 1`,
            icon: FaFileInvoice,
            active:`${datastatus.preReSend}`
        },
        {
            path: "/journalprintinglist",
            name: `เอกสาร Printing`,
            icon: FaFileInvoice,
            active:`${datastatus.preReSend}`
        },
        {
            path: "/journalstatusapprove",
            name: `สถานะเอกสาร`,
            icon: FaTasks,
            active:``,
            
        },
        {
            path: "/Journallistallpprovebyuser",
            name: `เอกสารอนุมัติแล้ว`,
            icon: FaTasks,
            active:``,
            
        },

        {
            path: "/flowsystem",
            name: "สร้าง Flows ",
            icon: FaFileSignature,
            active:``
        },
        {
            path: "/createuser",
            name: "สร้างผู้ใช้งาน ",
            icon: FaUserPlus,
            active:``
        },


    ]

    useEffect(() => {
        loadingmenu()
    }, [])

    const loadingmenu = async () => {

        await FetchApis.FethcGet(`/groupmenusub/getmenulistByaccount/${Emcode}`).then((res) => {
            if (res.status == 200) {
                setGetMenuList(res.data.data);

            }
        })
    }

    function iconshow(data) {
        var dataicon = menuItem.find(item => item.path == data);
        if (dataicon != undefined) {
            
            arrey.push(dataicon)
        }
    }


    return (
        <div className="container-sidebar">
            <div style={{ width: isOpen ? "200px" : "50px", background: '#F4F4F4' }} className="sidebar">
                <div className="top_section">
                    <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
                    </h1>
                    <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
                        <FaBars onClick={toggle} />
                    </div>
                </div>
                {/* {

                  getMenuList.map((item, index) => (
                        <NavLink to={item.path} key={index} className="link" activeclassName="active">
                            <div className="icon">
                             {item.icon}
                             {<item.icon/>}
                            </div>
                            <div style={{ display: isOpen ? "block" : "none", fontSize: 12 }} className="link_text">{item.name}</div>
                        </NavLink>
                    ))
                } */}

                {
                    getMenuList.map((item, index) => {
                        { iconshow(item.path) }
                    })
                }
                {
                    arrey.length > 1 && arrey.map((item, index) =>
                    (<NavLink to={item.path} key={index} className="link" activeclassName="active">
                        <div className="icon">
                            {item.name!='เอกสารอนุมัติแล้ว'?<item.icon />:<AiOutlineFileProtect color='green'size={20}/>}
                        </div>
                        <div style={{ display: isOpen ? "block" : "none", fontSize: 12 }} className="link_text"> 
                            {item.name} {item.active>0 &&<Badge bg="danger">{item.active}</Badge>}
                            </div>
                    </NavLink>)
                    )}

            </div>
            <main>{children}</main>
        </div>
    );
};

export default Sidebar;