import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { addbody, removebody, addtop, removetop, addbottom, removebottom }
    from '../../../../reduxSlice/coatingpartbSlice/coating1StSlice';
import FetchApi from '../../../customhooks/functionFetchApi';

const datalist = {sequence: "1st",type: "",side: "",color: "",fm: "",
    solid: "",
    dfw_min: "",
    dfw_max: "",
    wfw: "",
    temp: "",
    speed: "",
    rubber: "",
    other: ""
}

const Edit1stcoating = ({ coating1st,cansize }) => {
    const FetchApis = new FetchApi();
    const dispatch = useDispatch();

    const [coatingall, setCoatingall] = useState({ ...datalist })
    const [coatingbody, setCoatingbody] = useState({ ...datalist })
    const [coatingtop, setCoatingtop] = useState({ ...datalist })
    const [coatingbotton, setCoatingbotton] = useState({ ...datalist })
    const [notedetail,setNotedetail]=useState("")


    useEffect(() => {
            // console.log(coating1st)
        setNotedetail(coating1st.otherdetail)
        var databody = {
            sequence: "1st",type: "body",
            side: coating1st.bodyside,
            color: coating1st.bodycolor,
            fm: coating1st.bodyfm,
            solid: coating1st.bodysolid,
            dfw_min: coating1st.bodydfw_min,
            dfw_max: coating1st.bodydfw_max,
            // dfw: coating1st.bodydfw,
            wfw: coating1st.bodywfw,
            temp: coating1st.bodytemp,
            speed: coating1st.bodyspeed,
            rubber: coating1st.bodyrubber,
            other: coating1st.otherdetail
        }
        dispatch(addbody(databody));
        setCoatingbody(databody);

        var datatop = {
            sequence: "1st",type: "top",
            side: coating1st.topside,
            color: coating1st.topcolor,
            fm: coating1st.topfm,
            solid: coating1st.topsolid,
            dfw_min: coating1st.topdfw_min,
            dfw_max: coating1st.topdfw_max,
            // dfw: coating1st.topdfw,
            wfw: coating1st.topwfw,
            temp: coating1st.toptemp,
            speed: coating1st.topspeed,
            rubber: coating1st.toprubber,
            other:coating1st.otherdetail
        }
        dispatch(addtop(datatop));
        setCoatingtop(datatop);

        var databottom = {
            sequence: "1st",type: "bottom",
            side: coating1st.bottomside,
            color: coating1st.bottomcolor,
            fm: coating1st.bottomfm,
            solid: coating1st.bottomsolid,
            dfw_min: coating1st.bottomdfw_min,
            dfw_max: coating1st.bottomdfw_max,
            // dfw: coating1st.bottomdfw,
            wfw: coating1st.bottomwfw,
            temp: coating1st.bottomtemp,
            speed: coating1st.bottomspeed,
            rubber: coating1st.bottomrubber,
            other: coating1st.otherdetail
        }
        dispatch(addbottom(databottom));
        setCoatingbotton(databottom)

    }, [])

    const addcoating = () => {
        if (coatingall.type == 'body') {
            setCoatingbody(coatingall)
            dispatch(addbody(coatingall))
        }
        else if (coatingall.type == 'top') {
            setCoatingtop(coatingall)
            dispatch(addtop(coatingall))


        }
        else if (coatingall.type == 'bottom') {
            setCoatingbotton(coatingall)
            dispatch(addbottom(coatingall))


        }
        else {
            alert("คุณยังไม่ได้เลือก ประเภทการบันทึก")
        }

    }


    const UpdateCoating = () => {
        Swal.fire({
            title: "บันทึกเอกสาร",
            text: "คุณแน่ใจที่บันทึกเอกสาร ใช่หรือไม่ ! ",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ใช่",
            cancelButtonText: 'ยกเลิก'
        }).then((result) => {
            if (result.isConfirmed) {
                FetchApis.FethcPartbPut(`/technical/updatetechnicalByid/${coating1st.tecbid}`,coating1st.tdscode,notedetail, coatingbody, coatingtop, coatingbotton)
                    .then((res) => {
                        if (res.status!=200) {
                            alert(res.message)
                        }
                    })
            }

        })


    }

    const removecoating = () => {

        if (coatingall.type == 'body') {
            setCoatingbody({ ...datalist })

            dispatch(removebody(datalist))

        }
        else if (coatingall.type == 'top') {
            setCoatingtop({ ...datalist })

            dispatch(removetop(datalist))
        }
        else if (coatingall.type == 'bottom') {
            setCoatingbotton({ ...datalist })

            dispatch(removebottom(datalist))
        }
    }

    const onChange_type = (e) => {

        const { name, value } = e.target;
        const ischeck = e.target.checked;

        if (ischeck) {
            if (value == 'body') {

                setCoatingall({ ...coatingbody, type: 'body' })
            } else if (value == 'top') {

                setCoatingall({ ...coatingtop, type: 'top' })

            } else if (value == 'bottom') {
                setCoatingall({ ...coatingbotton, type: 'bottom' })
            }
        }
    }

    return (
        <div>
            <div className='end-flex-row ' ><Button variant='primary'style={{fontSize:12}} onClick={() => UpdateCoating()}>อัพเดทข้อมูล</Button></div>
            <div style={{ width: 700 }} >
                <div className='row-between-page'>
                    <div className='row-between-page' style={{ width: 200, backgroundColor: '#E9E7E2', padding: 5, borderRadius: 4 }}>
                        
                       {coatingbody.side!=""&& <div> <input type='radio' name='typecoating' value={'body'}
                            onClick={(e) => onChange_type(e)} /> Body</div>}

                        {coatingtop.side!=""&&<div><input type='radio' name='typecoating' value={'top'}
                            onClick={(e) => onChange_type(e)} /> Top </div>}
                        {coatingbotton.side!==""&&<div><input type='radio' name='typecoating' value={'bottom'}
                            onClick={(e) => onChange_type(e)} /> Bottom </div>}
                    
                    </div>

                    <div className='row-between-page' >
                        <div style={{ marginRight: 10 }}>
                            <Button size='sm' variant="primary" onClick={() => addcoating()}>เพิ่มข้อมูล</Button>
                        </div>
                        <div>
                            <Button size='sm' variant="warning" onClick={() => removecoating()}>ยกเลิก</Button>
                        </div>
                    </div>

                </div>
            </div>

            <div style={{ width: 700 }} className='border-green-page'>
                <div><b>Can Size /Dia  : </b> {cansize}</div>
                <hr/>
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>Side</label></b></div>
                    <div className='row-between-page' style={{ width: '25%' }}>
                        <div><input type='radio' name='radioside' value={'Interanl'} onClick={(e) => setCoatingall({ ...coatingall, side: e.target.value })} disabled/> Internal</div>
                        <div><input type='radio' name='radioside' value={'External'} onClick={(e) => setCoatingall({ ...coatingall, side: e.target.value })} disabled/> External</div>
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>Color shade</label></b></div>
                    <div>
                        <input type='text' name='ColorShade' style={{ width: 200 }} value={coatingall.color}
                            onChange={(e) => setCoatingall({ ...coatingall, color: e.target.value })} disabled/>
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>FM Code</label></b></div>
                    <div>
                        <input type='text' name='FMCode' style={{ width: 200 }} value={coatingall.fm}
                            onChange={(e) => setCoatingall({ ...coatingall, fm: e.target.value })} disabled/>
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>Solid</label></b></div>
                    <div>
                        <input type='number' name='Solid' 
                        value={coatingall.solid}
                        style={{ width: 150 }} onChange={(e) => setCoatingall({ ...coatingall, solid: e.target.value })} disabled/>
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div><b><label>DFW (gsm) / wfw (Kg /1000 Sht)</label></b></div>
                    <div style={{ width: 300 }}>
                        <div className='row-between-page'>
                            <label>DFW MIN : </label>
                            <input type='number' name='DFW' style={{ width: 150 }}
                                value={coatingall.dfw_min}
                                onChange={(e) => setCoatingall({ ...coatingall, dfw_min: e.target.value })} disabled/>
                        </div>
                        <div className='row-between-page'>
                            <label>DFW MAX : </label>
                            <input type='number' name='DFW' style={{ width: 150 }}
                                value={coatingall.dfw_max}
                                onChange={(e) => setCoatingall({ ...coatingall, dfw_max: e.target.value })} disabled/>
                        </div>
                        <div className='row-between-page'>
                            <label>wfw ( kg /100 Sht ) : </label>
                            <input type='number' name='wfw' 
                            value={coatingall.wfw}
                            style={{ width: 150 }} step='0.01' onChange={(e) => setCoatingall({ ...coatingall, wfw: e.target.value })} disabled/>
                        </div>
                    </div>
                </div>
               
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>Temp ( C )</label></b></div>
                    <div>
                        <input type='text' name='Temp' style={{ width: 250 }} value={coatingall.temp}
                            onChange={(e) => setCoatingall({ ...coatingall, temp: e.target.value })} disabled/>
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%', marginTop: 10, marginBottom: 10 }}>
                    <div><b><label>Speed (sph)/ No Ruble</label></b></div>
                    <div style={{ width: 300 }}>
                        <div className='row-between-page'>
                            <label>Speed (sph) : </label>
                            <input type='number' name='Speed' style={{ width: 150 }} value={coatingall.speed}
                                onChange={(e) => setCoatingall({ ...coatingall, speed: e.target.value })} />
                        </div>
                        <div className='row-between-page'>
                            <label>No Ruble : </label>
                            <input type='text' name='NoRuble' style={{ width: 150 }} value={coatingall.rubber}
                                onChange={(e) => setCoatingall({ ...coatingall, rubber: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <div className='row-between-page' style={{ width: '100%' }}>
                    <div><b><label>Other </label></b></div>
                    <div>
                        <textarea style={{ width: 250, height: 50 }} name='Other' value={notedetail}
                            onChange={(e) => setNotedetail(e.target.value)} disabled>

                        </textarea>
                    </div>
                </div>
            </div>

            <hr></hr>

            <table style={{ width: '100%' }}>
                <tr>
                    <th style={{ width: '10%' }}><center>sequence</center></th>
                    <th style={{ width: '30%' }}><center>Coating system</center></th>
                    <th style={{ width: '20%' }}><center>Body</center></th>
                    <th style={{ width: '20%' }}><center>Top end</center></th>
                    <th style={{ width: '20%' }}><center>Bottom end</center></th>
                </tr>
                <tr>
                    <td style={{ width: '5%' }}><center><b>1 st </b></center></td>
                    <td style={{ width: '30%' }}>Side</td>
                    <td style={{ width: '20%' }}>{coatingbody.side}</td>
                    <td style={{ width: '20%' }}>{coatingtop.side}</td>
                    <td style={{ width: '20%' }}>{coatingbotton.side}</td>
                </tr>
                <tr>
                    <td style={{ width: '5%' }}></td>
                    <td style={{ width: '30%' }}>Color shade</td>
                    <td style={{ width: '20%' }}>{coatingbody.color}</td>
                    <td style={{ width: '20%' }}>{coatingtop.color}</td>
                    <td style={{ width: '20%' }}>{coatingbotton.color}</td>
                </tr>
                <tr>
                    <td style={{ width: '5%' }}></td>
                    <td style={{ width: '30%' }}>FM.code</td>
                    <td style={{ width: '20%' }}>{coatingbody.fm}</td>
                    <td style={{ width: '20%' }}>{coatingtop.fm}</td>
                    <td style={{ width: '20%' }}>{coatingbotton.fm}</td>
                </tr>
                <tr>
                    <td style={{ width: '5%' }}></td>
                    <td style={{ width: '30%' }}>DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                    <td style={{ width: '20%' }}> {coatingbody.dfw_min.length > 0 && <div>{coatingbody.dfw_min}-{coatingbody.dfw_max} / {coatingbody.wfw}</div>}</td>
                    <td style={{ width: '20%' }}> {coatingtop.dfw_min.length > 0 && <div>{coatingtop.dfw_min}-{coatingtop.dfw_max} / {coatingtop.wfw}</div>}</td>
                    <td style={{ width: '20%' }}> {coatingbotton.dfw_min.length > 0 && <div>{coatingbotton.dfw_min}-{coatingbotton.dfw_max} / {coatingbotton.wfw}</div>}</td>
                </tr>
                {/* <tr>
                    <td style={{ width: '5%' }}></td>
                    <td style={{ width: '30%' }}>DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                    <td style={{ width: '20%' }}> {coatingbody.dfw.length > 0 && <div>{coatingbody.dfw} / {coatingbody.wfw}</div>}</td>
                    <td style={{ width: '20%' }}> {coatingtop.dfw.length > 0 && <div>{coatingtop.dfw} / {coatingtop.wfw}</div>}</td>
                    <td style={{ width: '20%' }}> {coatingbotton.dfw.length > 0 && <div>{coatingbotton.dfw} / {coatingbotton.wfw}</div>}</td>
                </tr> */}
                <tr>
                    <td style={{ width: '5%' }}></td>
                    <td style={{ width: '30%' }}>Temp ( C )</td>
                    <td style={{ width: '20%' }}>{coatingbody.temp}</td>
                    <td style={{ width: '20%' }}>{coatingtop.temp}</td>
                    <td style={{ width: '20%' }}>{coatingbotton.temp}</td>
                </tr>
                <tr>
                    <td style={{ width: '5%' }}></td>
                    <td style={{ width: '30%' }}>Speed ( sph) / No .Rubber</td>
                    <td style={{ width: '20%' }}> {coatingbody.speed.length > 0 && <div>{coatingbody.speed} / {coatingbody.rubber}</div>}</td>
                    <td style={{ width: '20%' }}> {coatingtop.speed.length > 0 && <div>{coatingtop.speed} / {coatingtop.rubber}</div>}</td>
                    <td style={{ width: '20%' }}> {coatingbotton.speed.length > 0 && <div>{coatingbotton.speed} / {coatingbotton.rubber}</div>}</td>
                </tr>
            </table>
            <div>
                <label><b>OTHER DETAIL</b></label>
                <div style={{ marginLeft: '5%', marginTop: 10 }}>
                    {notedetail}
                </div>
            </div>
        </div>
    )
}

export default Edit1stcoating