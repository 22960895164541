import React from 'react'
import DataTable from 'react-data-table-component';
import Recievedjournal from './recievedjournal';
import { FaFilePdf, FaFileSignature, FaCheckCircle } from "react-icons/fa";


const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => <div>
            <div>{row.tdscode} || v.{row.revision}</div>
            {row.salerunnumber != "" ? <div>({row.salerunnumber})</div> : <div></div>}</div>,
        grow: 0.7,
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltype,
        grow: 0.5,
    },
    {
        name: 'customer / product',
        selector: row => <div>
            <div>: {row.customername}</div>
            <div>: {row.productname}</div>
            </div>,
    },
    // {
    //     name: 'product name',
    //     sortable: true,
    //     selector: row => row.productname,
    // },

    {
        name: 'film code',
        selector: row => row.filmcode,
        grow: 0.5,
    },
    // {
    //     name: 'revision',
    //     selector: row => row.revision,
    //     grow: 0.3,
    // },
    // {
    //     name: 'product name',
    //     sortable: true,
    //     selector: row => row.productname,
    // },
    // {
    //     name: 'last film code',
    //     sortable: true,
    //     selector: row => row.filmcode,
    //     grow: 1,
    // },
    {
        name: 'confirm job',
        selector: row => <div>
            <div>{row.statusConfirm == '1' ? <label style={{ color: 'green' }}><FaCheckCircle /> Approve </label> : <label></label>}</div>
        </div>,
    },
    {
        name: 'createdate',
        selector: row => row.createdate,
        grow: 0.5,
    },
    {
        name: 'Action',
        grow: 1,
        cell: row => <Recievedjournal Id={row.journalId} stateflow={row.stateflow} statusflow={row.statusflow} />,
    },

];

//tasknote

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Tablejournallistrecieved = ({ values }) => {
    var data = values;
    return (
        <DataTable
            columns={columns}
            data={data}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Tablejournallistrecieved