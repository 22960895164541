import React,{useState,useEffect} from 'react'
import FetchApi from '../customhooks/functionFetchApi'
import Tablelistrejectstatus from './components/componenetrejectstatus/tablelistrejectstatus';
import Spinner from 'react-bootstrap/Spinner';
const Journalrejectdetail = () => {
  const FetchApis=new FetchApi();

  const[jourlist,setJournalList]=useState([])
  const[reloading,setReloading]=useState(false)
  const emcode = sessionStorage.getItem('EmCode');
  
  useEffect(()=>{
    setReloading(true)
    getlistReject()
  },[])

  function getlistReject(){
    

    FetchApis.FethcGet(`/journal/rejectDetialbyAccount/${emcode}`).then((res)=>{
      if(res){
        setJournalList(res.data.data)
        console.log(res.data.data)
        console.log(res.data)
        setTimeout(() => {
          setReloading(false)
        }, 1000);
       
      }
      
    })
  }


  return (
    <div>
      <div>{reloading==true ?
       <div className='body-center-page'><Spinner
       as="span"
       animation="grow"
       variant="success"
       size={200}
       role="status"
       aria-hidden="true" /> LOADIN ....</div> :<div><Tablelistrejectstatus  values={jourlist}/></div>
    }

      </div>
    </div>
  )
}

export default Journalrejectdetail