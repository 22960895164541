import DataTable from 'react-data-table-component';
import React from 'react'
import Statusall from './statusall';
import Flowuserapprove from './flowuserapprove';
import { FaList,FaFileSignature,FaArchive,FaExclamationTriangle,FaCheck,FaCheckCircle } from "react-icons/fa";

import Actionallstatus from'./actionallstatus';


const columns = [
    {
        name: 'TDS code',
        sortable: true,
        grow: 0.5,
        selector: row => <div>
        <div>{row.tdscode} || v.{row.revision}</div>
        {row.salerunnumber!=""?<div>({row.salerunnumber})</div>:<div></div>}
        </div>,
    },
    {
        name: 'createdate',
        grow: 0.5,
        selector: row => row.createdate,
    },
    // {
    //     name: 'Type',
    //     grow: 0.5,
    //     selector: row => row.journaltype,
    // },
    {
        name: 'customer/product',
        grow: 1,
        selector: row => <div>
            <di>: { row.journaltype}</di>
           <div> : {row.customername}</div> 
            <div> : {row.productname}</div>
            
        </div>,
    },
    {
        name: 'film code',
        grow: 0.5,
        selector: row => row.filmcode,
    },
    // {
    //     name: 'revision',
    //     grow: 0.2,
    //     selector: row => row.revision,
    // },
    {
        name: 'status flow',
        sortable: true,
        selector: row => <Statusall id={row.journalId} statusNow={row.stateflow} stateSuccesfull={row.stateSuccesfull}/>,
        grow: 0.5,
    },
    {
        name: 'รออนุมัติ',
        grow: 0.7,
        selector: row =><Flowuserapprove id={row.journalId} 
        stateflow={row.stateflow} 
        departments={row.departments}
        accountapprove={row.accountapprove} />,
    },
    {
        name: 'confirm job',
        grow: 0.5,
        selector: row =><div>{row.statusConfirm==1&&<label style={{color:'#0e9a07'}}><FaCheckCircle />Approve</label>}</div> ,
    },
    // { 
    //     name: 'action',
    //      grow: 1.5,
    //     cell: row =><Actionallstatus id={row.journalId}/>,
    // },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Tablejournalallstatus = (datatable) => {
    var dataresult = datatable.datatable;
    
    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )
}

export default Tablejournalallstatus