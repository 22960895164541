import React, { useState, useEffect, useMemo } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdPageview } from "react-icons/md";
import { host } from '../../customhooks/functionFetchApi';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

function ViewImages({ urlImages }) {


  const [modalShow, setModalShow] = useState(false);
  const [typefile, setTypefile] = useState("")

  const docs = [
    { uri: `http://localhost:3001/api/tds/images/files/${urlImages}`,
      fileType:'pdf',
      fileName:'ประวัติสมัครงาน'
     }, // Remote file
    // { uri: require("./example-files/pdf.pdf") }, // Local File
  ]

  function showmodal(v) {

    if (v == true) {
      var datastplit = urlImages.split(".");
      var i = datastplit.length - 1;
      setTypefile(datastplit[i])
      //  console.log(datastplit[i]);
    }
    setModalShow(v)
  }

  return (
    <>
      <div style={{ marginLeft: 10 }}>
        <MdPageview onClick={() => showmodal(true)} size={30} />
      </div>

      <Modal
        show={modalShow}
        onHide={() => showmodal(false)}
        // size="lg"
        Full screen
        aria-labelledby="example-modal-sizes-title-lg"
      // centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            ตัวอย่าง File...
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
          {typefile=='pdf'&&<div style={{height:'auto'}}>
            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} style={{height:'auto'}}/>
            </div>}
         
          </div>

          <div>
            {typefile == 'png' && <img src={host + '/images/files/' + urlImages}  style={{width:'100%'}}/>}
          </div>
          <div>
            {typefile == 'jpg' && <img src={host + '/images/files/' + urlImages} style={{width:'100%'}}/>}
          </div>

        </Modal.Body>

        <Modal.Footer>
          <Button onClick={() => showmodal(false)}>Close</Button>
        </Modal.Footer>

      </Modal>

    </>
  )
}

export default ViewImages