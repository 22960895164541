import React, { useEffect, useState } from 'react'
import FetchApi from '../customhooks/functionFetchApi'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';


const columns = [
  {
      name: 'TDS code',
      sortable: true,
      grow: 0.5,
      selector: row => <div>
      <div>{row.tdscode} || v.{row.revision}</div>
      {row.salerunnumber!=""?<div>({row.salerunnumber})</div>:<div></div>}
      </div>,
  },
  {
      name: 'createdate',
      grow: 0.5,
      selector: row => row.createdate,
  },
  {
      name: 'Type',
      grow: 1,
      selector: row => row.journaltype,
  },
  {
      name: 'customer/product',
      grow: 1,
      selector: row => <div>
         <div> : {row.customername}</div> 
          <div> : {row.productname}</div>
          
      </div>,
  },
  {
      name: 'ผู้สร้างเอกสาร',
      grow: 0.5,
      selector: row => row.createby,
  },
  {
      name: 'สถานะ',
      grow: 0.5,
      selector: row => row.statusflow,
  },
  {
    name: 'ลำดับอนุมัติ',
    grow: 0.5,
    selector: row =><div>
      อนุมัติที่ .{row.stateflow}
    </div> ,
},

];

const customStyles = {
  headRow: {
      style: {
          border: 'none',
          backgroundColor: '#28B463'
      },
  },
  headCells: {
      style: {
          color: '#ffff',
          fontSize: '14px',
      },
  },
  rows: {
      highlightOnHoverStyle: {
          backgroundColor: 'rgb(230, 244, 244)',
          borderBottomColor: '#FFFFFF',
          borderRadius: '25px',
          outline: '1px solid #FFFFFF',
      },
  },
  pagination: {
      style: {
          border: 'none',
      },
  },
};



function ShowDetailJournalById({Id}) {
  const FetchApis =new FetchApi()
  
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const[datalist,setDatalist]=useState([])

  function handleShow(breakpoint) {
    DetaillistById(Id)
    setFullscreen(breakpoint);
    setShow(true);
   
  }

  function DetaillistById(Id){
    console.log(Id)
    FetchApis.FethcGet(`/reportjournal/getjournalPrerecievbyId/${Id}`).then((res)=>{
      if(res){
        setDatalist(res.data.data)
      
      }
    })
  }

    return(
      <>
        <Button  size='sm' onClick={() => handleShow(true)} style={{fontSize:12}}>
          ข้อมูล
        </Button>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ข้อมูลเอกสาร รอรับเอกสาร</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        {datalist.length>0?<DataTable
            columns={columns}
            data={datalist}
            // selectableRows
            pagination
             customStyles={customStyles}
            highlightOnHover

        />:<div></div>}
        </Modal.Body>
      </Modal>
      </>
    )
}





const Reportstatusjournalall = () => {
  const FetcApis = new FetchApi()
  const [dataprestatus, setDataprestatus] = useState([]);
  const [datarecievstatus, setDatarecievstatus] = useState([])
  const [colorcode, setColorcode] = useState('')
  const [reloading, setReloading] = useState(false)

  useEffect(() => {
    setReloading(true)
    getapistatusall()
  }, [])

  function getapistatusall() {
    FetcApis.FethcGet(`/reportjournal/getstautsjournalall`).then((res) => {
      if (res) {

        setDataprestatus(res.data.data.dataprereciev[0])
        setDatarecievstatus(res.data.data.datareciev[0])
        console.log(res)
        setTimeout(() => {
          setReloading(false)
        }, 700);
      }
    })
  }



  return (
    <div>
      {reloading == true ? <div className='body-center-page'>
        <Spinner
          as="span"
          animation="grow"
          variant="success"
          size={200}
          role="status"
          aria-hidden="true" /> LOADIN ....</div> :

        <div style={{ flexDirection: 'row', display: 'flex', width: '100%' }}>
          <div style={{ margin: 5 }}>
            <label>ข้อมูลเอกสาร ค้างรับ</label>
            <Card style={{ width: 500 }}>
              <Table style={{ fontSize: 12, width: '100%' }}>
                <thead >
                  <tr >
                    <th style={{ backgroundColor: '#ece9e8' }}>#</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>ชื่อผุ้ใช้งาน</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>แผนก</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>ระดับการอุมัติ</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>จำนวนเอกสารที่รอรับ</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>#</th>
                  </tr>
                </thead>
                <tbody>
                  {dataprestatus.map((item, i) => (
                    <tr >
                      <td style={{ borderBottomColor: '#cbc8c8' }}>{i + 1}</td>
                      <td style={{ borderBottomColor: '#cbc8c8' }}>{item.approveby}</td>
                      <td style={{ borderBottomColor: '#cbc8c8' }}>{item.departments}</td>
                      <td style={{ borderBottomColor: '#cbc8c8' }}>อนุมัติที่ .{item.stateflow}</td>
                      <td style={{ borderBottomColor: '#cbc8c8' }}>{item.total}</td>
                      <td style={{ borderBottomColor: '#cbc8c8' }}><ShowDetailJournalById Id={item.stateflow}/></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
          <div style={{ margin: 5 }}>
            <label>ข้อมูลเอกสาร รับเรียบแล้ว</label>
            <Card style={{ width: '100%' }}>
              <Table style={{ fontSize: 12, width: '100%' }}>
                <thead >
                  <tr >
                    <th style={{ backgroundColor: '#ece9e8' }}>#</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>TDS Code</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>รหัส อ้างอิง (Sale)</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>ระดับอนุมัติ</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>ชื่อผู้รับ</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>แผนก</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>วันที่รับ</th>
                    <th style={{ backgroundColor: '#ece9e8' }}>เวลาที่ใช้ / วัน</th>
                  </tr>
                </thead>
                <tbody>
                  {datarecievstatus.map((item, i) => (
                    <tr >
                      <td style={{ borderBottomColor: '#cbc8c8' }}>{i + 1}</td>
                      <td style={{ borderBottomColor: '#cbc8c8' }}>{item.tdscode}</td>
                      <td style={{ borderBottomColor: '#cbc8c8' }}>{item.salerunnumber}</td>
                      <td style={{ borderBottomColor: '#cbc8c8' }}>อนุมัติที่ .{item.stateflow}</td>
                      <td style={{ borderBottomColor: '#cbc8c8' }}>{item.empcode}</td>
                      <td style={{ borderBottomColor: '#cbc8c8' }}>{item.departments}</td>
                      <td style={{ borderBottomColor: '#cbc8c8' }}>{item.recievTime}</td>
                      <td style={{ borderBottomColor: '#cbc8c8', backgroundColor: item.sumdate > 7 ? '#f96546' : '' }} >{item.sumdate} . วัน </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </div>
      }

    </div>
  )
}

export default Reportstatusjournalall