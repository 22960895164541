import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FetchApi from '../../../customhooks/functionFetchApi';

const PopUpDetailReject = (props) => {
    const FetchApis = new FetchApi();

    const [show, setShow] = useState(false);
    const[dataDetail,setDataDetail]=useState({
        taskid:"",
        journalid:"",
        emcode:"",
        stateflow:"",
        approvecode:"",
        dedicatecode:"",
        rejectcode:"",
        tasknote:"",
        datetime:"",
        rejectName:""
    })


    function getdetailReject(){

        var data_object={
            journalId:props.journalId,
            emcode:props.emcode,
            statusFlow:props.statusFlow
    }
    console.log(data_object);
    
        FetchApis.FethcPost(`/taskflow/getRejectDetail`,data_object).then((res)=>{
            if(res){

                if(res.data.length !=0){
                     setDataDetail(res.data[0])
                }
               console.log(res.data)
                
            }
        })
    }

    function showmodal(v){
        if(v==true){
            getdetailReject()
        }
        setShow(v)
    }

    return (
        <>
            <Button variant="warning" size='sm'
                style={{ fontSize: 12 }}
                onClick={() => showmodal(true)}>
                สาเหตุตีกลับ
            </Button>

            <Modal
                show={show}
                onHide={() => showmodal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                       รายละเอียด แจ้งตีกลับ   : {props.tdscode}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
               <div>   
                    <div> <label>ตีกลับ คุณ : {dataDetail.rejectName}</label></div>
                    <div> <label>แผนก : {dataDetail.departments}</label></div>
                    <div style={{padding:10,backgroundColor:'#f1efee'}}>
                        <label>
                            {dataDetail.tasknote}
                        </label>
                    </div>
                    <div>
                      <label> เวลา : {dataDetail.datetime}</label>
                    </div>
               </div> 
               
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => showmodal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PopUpDetailReject