import React, { useState, useEffect } from 'react'
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'
import { Table, TD, TH, TR } from "@ag-media/react-pdf-table";
import Colorprinting from './component/colorprinting1';
// import { tableData, totalData } from "./data";
import { styles } from "../../../css/style";
import FetchApi from '../../../customhooks/functionFetchApi';
// import checkboxicon from '../images/check-box_48127.png';
// import Reportv3 from './reportv3';

const objectPartB = {
  tecbid: "", sequence: "", bodyside: "", bodycolor: "", bodyfm: "", bodydfw: "",
  bodywfw: "", bodytemp: "", bodyspeed: "", bodyrubber: "", topside: "", topcolor: "",
  topfm: "", topdfw: "", topwfw: "", toptemp: "", topspeed: "", toprubber: "",
  bottomside: "", bottomcolor: "", bottomfm: "", bottomdfw: "", bottomwfw: "", bottomtemp: "", bottomspeed: "", bottomrubber: "",
  otherdetail: "",
  tdscode: ""
}

const Reportv4 = (props) => {
  const FetchApis = new FetchApi();

  const [coanting1st, setCoanting1st] = useState(objectPartB);
  const [coanting2nd, setCoanting2nd] = useState(objectPartB);
  const [coanting3rd, setCoanting3rd] = useState(objectPartB);
  const [coanting4th, setCoanting4th] = useState(objectPartB);
  const [coanting5th, setCoanting5th] = useState(objectPartB);
  const [coanting6th, setCoanting6th] = useState(objectPartB);
  const [coanting7th, setCoanting7th] = useState(objectPartB);
  const [checkdata, setCheckdata] = useState(false);
   const[jouranlheader,setJournalhead]=useState([])

  useEffect(() => {
    getjournalbyid()
    FetchApis.FethcGet(`/technical/techniaclByJournalId/${props.jourId}`).then((res) => {

      if (res.status == 200) {
        if (res.data.data.length != 0) {

          Coatinglistbytype(res.data.data)
          setCheckdata(true)
        }

      }
    })
  }, [])

  function Coatinglistbytype(datalist) {
    const coating1st = datalist.find((item) => item.sequence == '1st');
    setCoanting1st(coating1st);

    const coating2nd = datalist.find((item) => item.sequence == '2nd');
    setCoanting2nd(coating2nd);

    const coating3rd = datalist.find((item) => item.sequence == '3rd');
    setCoanting3rd(coating3rd);

    const coating4th = datalist.find((item) => item.sequence == '4th');
    setCoanting4th(coating4th);

    const coating5th = datalist.find((item) => item.sequence == '5th');
    setCoanting5th(coating5th);

    const coating6th = datalist.find((item) => item.sequence == '6th');
    setCoanting6th(coating6th);

    const coating7th = datalist.find((item) => item.sequence == '7th');
    setCoanting7th(coating7th);
  }


  function getjournalbyid() {
    FetchApis.FethcGet(`/journal/journalbyId/${props.jourId}`).then(res => {
        if (res) {
            setJournalhead(res.data.data[0])
        }

    })
}


  return (  
      // <Document>

        <Page size="A4" orientation="landscape" style={styles.page}>

          <View style={[styles.textBoldfonts95, { width: '100%' }]}>
            <Table style={{ marginBottom: 5, border: '0.5' }}>
              <TH styles={{ border: '0.5' }}>
                <TD weighting={0.6} style={[{ flexDirection: 'column', justifyContent: 'center', border: '0.5' }]}>
                  <Text style={{ fontWeight: 'bold', fontSize: 12 }}>TECHNICAL DATA SHEET PART ( B ) </Text>
                  <Text style={{ fontWeight: 'bold', fontSize: 12 }}>System Printing </Text>
                </TD>
                <TD weighting={0.2} style={{ border: '0.5' }}>
                  <Text style={{ marginLeft: 3 }}>Ref. MK. NO : {jouranlheader.tdscode}</Text>
                </TD>
                <TD weighting={0.2} style={{ border: '0.5' }}>
                  <Text style={{ marginLeft: 3 }}>Date : {jouranlheader.createdatetime}</Text>
                </TD>
              </TH>
            </Table>
            {/* technical data 1st*/}
            <Table style={{ border: '0.5px' }}>
              <TH
                style={{
                  backgroundColor: '#e5e7e9'
                }}
              >
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                  <Text>sequence</Text>
                </TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                  <Text>System Printing</Text>
                </TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                  <Text> Convent Ink 2 Color(PT 1-5) </Text>
                </TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                  <Text>Convent Ink 6 Color(PT-6)</Text>
                </TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                  <Text> Convent Ink 6 Color(PT-7)</Text></TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                  <Text> Digital Printing(PT-8) </Text>
                </TD>
              </TH>
              <TH style={{ backgroundColor: '#e5e7e9' }}>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                  <Text></Text>
                </TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                  <Text style={{ fontWeight: 'bold' }}></Text>
                </TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                  <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text> Unit1</Text>
                  </TD>
                  <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text> Unit2 </Text>
                  </TD>
                </TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                  <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text>Pass1</Text>
                  </TD>
                  <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text> Pass2</Text>
                  </TD>
                </TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.3}>
                  <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text>Pass1</Text>
                  </TD>
                  <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>
                    <Text> Pass2</Text>
                  </TD>
                </TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                  <Text>Pass1</Text>
                </TD>
              </TH>
            </Table>
            {checkdata == true ? <Colorprinting tecbid={coanting1st.tecbid} sequenece={'1st'} /> : ""}
            {checkdata == true ? <Colorprinting tecbid={coanting2nd.tecbid} sequenece={'2nd'} /> : ""}
            {checkdata == true ? <Colorprinting tecbid={coanting3rd.tecbid} sequenece={'3rd'} /> : ""}
            {checkdata == true ? <Colorprinting tecbid={coanting4th.tecbid} sequenece={'4th'} /> : ""}
            {checkdata == true ? <Colorprinting tecbid={coanting5th.tecbid} sequenece={'5th'} /> : ""}
            {checkdata == true ? <Colorprinting tecbid={coanting6th.tecbid} sequenece={'6th'} /> : ""}
            {checkdata == true ? <Colorprinting tecbid={coanting7th.tecbid} sequenece={'7th'} /> : ""}
          </View>

          <Text style={styles.pageNumber}
            render={({ pageNumber, totalPages }) => (
              `${pageNumber} / ${totalPages}`
            )} fixed />

          {/* <View render={({ pageNumber }) => (
          pageNumber % 2 === 0 && (
            <View style={{ background: 'red', }}>
              <Text>I'm only visible in odd pages!</Text>
            </View>
          )
        )} /> */}

        </Page>

      // </Document>

  )
}

export default Reportv4