import React,{useEffect,useState} from 'react'
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'
import { Table, TD, TH, TR } from "@ag-media/react-pdf-table";
import FetchApi from '../../../../customhooks/functionFetchApi';

function Newcolerprinting(props) {
        const FetchApis = new FetchApi();
        const [colorall, setColorall] = useState([])
    
        useEffect(() => {
            FetchApis.FethcGet(`/productcolordetail/getproductByColorId/${props.colorId}`).then((res) => {
                if (res.status == 200) {
                    setColorall(res.data.data)
                    console.log(res.data.data)
                }
            })
        }, [])
        function sumkgformulate() {
            let kg1 = 0;
            for (let index = 0; index < colorall.length; index++) {
                kg1 += parseFloat(colorall[index].formulate)
    
            }
    
            if (colorall.length > 0) {
                if (kg1 >= 99.99) {
                    kg1 = 100;
                }
    
            }
    
            return kg1.toFixed(2)
        }
    
        function sumkg() {
    
            let kg2 = 0;
            for (let index = 0; index < colorall.length; index++) {
                kg2 += parseFloat(colorall[index].kg1)
    
            }
    
            if (kg2 >= parseFloat(colorall[0].Kg_params)) {
                kg2 = parseFloat(colorall[0].Kg_params)
    
            } else {
                let data1 = kg2 - parseFloat(colorall[0].Kg_params);
    
                if (data1.toFixed(3) == -0.001) {
                    kg2 = parseFloat(colorall[0].Kg_params);
                }
    
            }
            return kg2.toFixed(3)
        }

  return (
   <>
   <Table style={{ marginBottom: 5 ,border:'0.5'}}>
            <TH style={{backgroundColor:'#e5e7e9'}}>
              <TD style={{justifyContent:'center'}}>Ink Name</TD>
              <TD style={{justifyContent:'center'}}>Formulate</TD>
              <TD style={{justifyContent:'center'}}>{colorall.length>0&&colorall[0].Kg_params} kg/1,000 sht</TD>
            </TH>
            {
                colorall.map((item,i)=>(
            <TR>
              <TD ><Text style={{marginLeft:3}}>{i+1}. {item.inkname}</Text></TD>
              <TD style={{justifyContent:'center'}}>{item.formulate}</TD>
              <TD style={{justifyContent:'center'}}>{item.kg1}</TD>
              
            </TR>
                ))
            }
            <TR>
              <TD style={{justifyContent:'center'}}><Text style={{marginLeft:3,fontWeight:'bold'}}>Total </Text></TD>
              <TD style={{justifyContent:'center',fontWeight:'bold'}}>{colorall.length > 1 && sumkgformulate()}</TD>
              <TD style={{justifyContent:'center',fontWeight:'bold'}}>{colorall.length > 1 && sumkg()}</TD>
            </TR>
          </Table>
        </>
  )
}

export default Newcolerprinting