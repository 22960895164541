import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import 'bootstrap/dist/css/bootstrap.min.css';
import formA1 from '../json-data/datatypeformA1.json';
import formA2 from '../json-data/datatypeformA2.json';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from 'react-bootstrap/Spinner';
import FetchApi, { host } from '../../customhooks/functionFetchApi';
import AddComponentCode from './addComponentCode';
import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';
import ViewImages from './viewImages';
import { useSelector, useDispatch } from 'react-redux';
import PopupFGcomponent from './popupComponent/popupFGcomponent';

import {
    addcomponentbody,
    addcomponenttop,
    addcomponentbottom
} from '../../../reduxSlice/itemcomponentSlice/itemcomponentSlice';

import {
    objectcoatingbody, objectcoatingtop, objectcoatingbottom,
    objectforming, objectguideline, objectrequest, statusflowall, objectcoatingring,
    objectcoatingear, objectcomponentcode
} from '../object-usestate/objectdefaults';


function FormNew({ handleShow, ids }) {
    const dispatch = useDispatch();

    const compoData = useSelector((state) => state.itemcomponent.datacomponent)
    const FetchApis = new FetchApi(); //fatch data
    const [key, setKey] = useState('Coating');

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const [coatingbody, setCoatingbody] = useState({ ...objectcoatingbody });
    const [coatingtop, setCoatingtop] = useState({ ...objectcoatingtop });
    const [coatingbottom, setCoatingbottom] = useState({ ...objectcoatingbottom });
    const [coatingring, setCoatingring] = useState({ ...objectcoatingring });
    const [coatingear, setCoatingear] = useState({ ...objectcoatingear });

    const [forming, setForming] = useState({ ...objectforming });
    const [guideline, setGuideline] = useState({ ...objectguideline })
    const [requestsample, setRequestsample] = useState({ ...objectrequest });
    // const [componentCodeall, setComponentCode] = useState({ ...objectcomponentcode });
    const [regulationcheck, setRegulationcheck] = useState([]) // ค่อยปรับ
    const [regulationOter, setregulationOther] = useState([])  // ค่อยปรับ
    const [datafetch, setDatafetch] = useState([])

    // create error length select over field  coating system
    const [BodyError, setBodyError] = useState(false); // body
    const [TopError, setTopError] = useState(false); // top
    const [BottomError, setBottomError] = useState(false); //botton
    const [RingError, setRingError] = useState(false);
    const [EarError, setEarError] = useState(false);

    // create error length select over field can end & forming
    const [formingError, setFormingError] = useState(false);
    //create error length select over field Guideline
    const [ProductgorupError, setProductgorupError] = useState(false);

    //กำหนดประเภทเอกสาร . . .
    const [dataDocument, setDataDocument] = useState(null);
    const [typedocument, setTypedocument] = useState(null);


    useEffect(() => {
        FetchApis.FethcGet(`/journal/journalbyId/${ids.id}`).then((res) => {

            var tdsall = "";
            if (res.data.status == 200) {

                setDatafetch(res.data.data)
                tdsall = res.data.data[0].tdscode;
                var journalIds = res.data.data[0].journalId;
                setTypedocument(res.data.data[0].typeproduct)
                if (res.data.data[0].typeproduct == 'A1') {
                    setDataDocument(formA1)
                } else if (res.data.data[0].typeproduct == 'A2') {
                    setDataDocument(formA2)
                }

                // ชุดข้อมูลเดียวกัน
                setCoatingbody({
                    ...coatingbody,
                    tdscode: tdsall,
                    journalId: journalIds
                });
                setCoatingtop({
                    ...coatingtop,
                    tdscode: tdsall,
                    journalId: journalIds
                });
                setCoatingbottom({
                    ...coatingbottom,
                    tdscode: tdsall,
                    journalId: journalIds
                });
                setCoatingring({
                    ...coatingring,
                    tdscode: tdsall,
                    journalId: journalIds
                });
                setCoatingear({
                    ...coatingear,
                    tdscode: tdsall,
                    journalId: journalIds
                });
                setForming({
                    ...forming,
                    tdscode: tdsall,
                    journalId: journalIds
                });
                setGuideline({
                    ...guideline,
                    tdscode: tdsall,
                    journalId: journalIds
                });
                setRequestsample({
                    ...requestsample,
                    tdscode: tdsall,
                    journalId: journalIds
                });

            }

        })
    }, [])


    async function submitSave(e) {
        //check error length body
        e.preventDefault();
        const dataregu = {
            regulationtype: regulationcheck,
            regulationother: regulationOter,
            tdscode: datafetch[0].tdscode,
            journalId: datafetch[0].journalId
        }

        let datacomponentCodeall = {
            ear: compoData.ear,
            earwelding: compoData.earwelding,
            ring: compoData.ring,
            innerlid: compoData.innerlid,
            outerlid: compoData.outerlid,
            toplid: compoData.toplid,
            bottomlid: compoData.bottomlid,
            plasticlid: compoData.plasticlid,
            aluminumlid: compoData.aluminumlid,
            journalId: datafetch[0].journalId,
            tdscode: datafetch[0].tdscode
        }

        if (coatingbody.intcoating.length > 1 || coatingbody.extcoating.length > 1) {
            setBodyError(true);

            Swal.fire({
                icon: "error",
                title: "แจ้งเตือน Coating system ",
                text: "Body ไม่สามารถบันทึกได้ มีรายการมากกว่า 1 รายการ กรุณาตรวจสอบข้อมูล!",
            });

            return
        }
        else {
            setBodyError(false);
        }

        if (coatingtop.substrate.length > 1 || coatingtop.shape.length > 1 || coatingtop.intcoating.length > 1 || coatingtop.extcoating.length > 1) {
            setTopError(true);
            Swal.fire({
                icon: "error",
                title: "แจ้งเตือน Coating system ",
                text: "TOP ไม่สามารถบันทึกได้ มีรายการมากกว่า 1 รายการ กรุณาตรวจสอบข้อมูล!",
            });
            return
        }
        else {
            setTopError(false);
        }
        if (coatingbottom.substrate.length > 1 || coatingbottom.shape.length > 1 || coatingbottom.intcoating.length > 1 || coatingbottom.extcoating.length > 1) {
            setBottomError(true);
            Swal.fire({
                icon: "error",
                title: "แจ้งเตือน Coating system ",
                text: "Bottom end ไม่สามารถบันทึกได้ มีรายการมากกว่า 1 รายการ กรุณาตรวจสอบข้อมูล!",
            });
            return
        }
        else {
            setBottomError(false);
        }

        if (coatingring.dia.length > 1 || coatingring.intcoating.length > 1 || coatingring.extcoating.length > 1) {
            setRingError(true)
            Swal.fire({
                icon: "error",
                title: "แจ้งเตือน Coating system ",
                text: "Ring ไม่สามารถบันทึกได้ มีรายการมากกว่า 1 รายการ กรุณาตรวจสอบข้อมูล!",
            });
            return

        } else {
            setRingError(false)
        }


        // if (coatingring.ring_eartype.length > 1 || coatingring.intcoating.length > 1 || coatingring.extcoating.length > 1) {
        //     setRingError(true)

        // } else {
        //     setRingError(false);
        // }

        if (coatingear.ring_eartype.length > 1 || coatingear.intcoating.length > 1) {
            setEarError(true)
            Swal.fire({
                icon: "error",
                title: "แจ้งเตือน Coating system ",
                text: "Ear ไม่สามารถบันทึกได้ มีรายการมากกว่า 1 รายการ กรุณาตรวจสอบข้อมูล!",
            });
            return
        } else {
            setEarError(false)
        }


        if (forming.internalside.length > 1 || forming.seaming.length > 1) {
            setFormingError(true)
            Swal.fire({
                icon: "error",
                title: "แจ้งเตือน Can & end forming ",
                text: "ไม่สามารถบันทึกได้ มีรายการมากกว่า 1 รายการ กรุณาตรวจสอบข้อมูล!",
            });
            return

        }
        else {
            setFormingError(false);
        }

        if (forming.packaging == "") {
            alert('คุณยังไม่ได้ระบุ Packaging กรุณาป้อนข้อมูล')
            // Swal.fire({
            //     icon: "error",
            //     title: "แจ้งเตือน Can & end forming ",
            //     text: "คุณยังไม่ได้ระบุ Packaging กรุณาป้อนข้อมูล",
            // });
            // return
        }


        if (guideline.groupother.length > 1 || guideline.groupdryfood.length > 1 || guideline.ph.length > 1 || guideline.ingredients.length > 1
            || guideline.seamercondition.length > 1 || guideline.sterilization.length > 1 || guideline.typegroup.length > 1) {
            setProductgorupError(true)
            Swal.fire({
                icon: "error",
                title: "แจ้งเตือน Guideline for product pack ",
                text: "ไม่สามารถบันทึกได้ มีรายการมากกว่า 1 รายการ กรุณาตรวจสอบข้อมูล!",
            });
            return

        }
        else {
            setProductgorupError(false);
        }


        if (guideline.groupother.length == 1 && guideline.typegroup.length == 0) {
            Swal.fire({
                icon: "error",
                title: "แจ้งเตือน",
                text: "ไม่สามารถบันทึกได้ กรุณาเลือก หัวข้อ Product group   1 รายการ !",
                // footer: '<a href="#">Why do I have this issue?</a>'
            });
            return
        }

        if (coatingbody.intcoating.length > 1 || coatingbody.extcoating.length > 1 ||
            coatingtop.substrate.length > 1 || coatingtop.shape.length > 1 || coatingtop.intcoating.length > 1 || coatingtop.extcoating.length > 1 ||
            coatingbottom.substrate.length > 1 || coatingbottom.shape.length > 1 || coatingbottom.intcoating.length > 1 || coatingbottom.extcoating.length > 1 ||
            coatingring.ring_eartype.length > 1 || coatingring.intcoating.length > 1 || coatingring.extcoating.length > 1 ||
            coatingear.ring_eartype.length > 1 || coatingear.intcoating.length > 1 ||
            forming.internalside.length > 1 || forming.seaming.length > 1 ||
            guideline.groupother.length > 1 || guideline.groupdryfood.length > 1 || guideline.ph.length > 1 || guideline.ingredients.length > 1 ||
            guideline.typegroup.length > 1
            || guideline.seamercondition.length > 1 || guideline.sterilization.length > 1
        ) {
            // alert('มีรายการมากกว่า 1 รายการ กรุณาตรวจสอบข้อมูล')

            Swal.fire({
                icon: "error",
                title: "แจ้งเตือน",
                text: "ไม่สามารถบันทึกได้ มีรายการมากกว่า 1 รายการ กรุณาตรวจสอบข้อมูล!",
                // footer: '<a href="#">Why do I have this issue?</a>'
            });
        }
        else {

            Swal.fire({
                title: "บันทึกเอกสาร",
                text: "คุณแน่ใจบันทึกเอกสาร ใช่หรือไม่ ! ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ใช่",
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {


                // console.log(guideline)

                if (result.isConfirmed) {
                    Swal.fire({
                        title: "บันทึกเอกสาร!",
                        text: "บันทึกเอกสารเรียบแล้ว",
                        icon: "success"
                    });
                    //  Coating system tab
                    // var data;
                    FetchApis.FethcPost(`/coating/createcoating`, coatingbody).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })

                    FetchApis.FethcPost(`/coating/createcoating`, coatingtop).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })


                    FetchApis.FethcPost(`/coating/createcoating`, coatingbottom).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })

                    FetchApis.FethcPost(`/coating/createcoating`, coatingring).then((res) => {
                        if (res.status != 200) {
                            alert(res.message)
                        }
                    })

                    FetchApis.FethcPost(`/coating/createcoating`, coatingear).then((res) => {
                        if (res.status != 200) {
                            alert(res.message)
                        }
                    })


                    // Can & End forming tab
                    FetchApis.FethcPost(`/forming/createforming`, forming).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })


                    //Guideline tab
                    FetchApis.FethcPost(`/guideline/createguideline`, guideline).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })

                    // regulation tab
                    FetchApis.FethcPost(`/regulation/createregulation`, dataregu).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })


                    // request sample tab
                    FetchApis.FethcPost(`/requestsample/createrequestsample`, requestsample).then((res) => {

                        if (res.status != 200) {
                            alert(res.message)
                        }
                    }).catch((err) => { console.log(err) })

                    // component code
                    FetchApis.FethcPost(`/componentcode/createcomponentcode`, datacomponentCodeall).then((res) => {
                        console.log(res)
                    })


                    var stateflowall = {
                        statusflow: statusflowall.newjournal,
                        stateflow: "0"
                    }

                    // update state flow  new journal 
                    FetchApis.FethcUpdate(`/journal/updatestateflow/${datafetch[0].journalId}`, stateflowall)
                        .then((res) => { })


                    toast.success('บันทึกเรียบร้อยแล้ว', {
                        position: "top-right",
                        autoClose: 1800,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        // transition: Bounce,
                    });
                    setTimeout(() => {
                        handleShow(false);
                        window.location.reload();
                    }, 1800);

                }

            });
        }

    }

    // set show type product group topic guideline for prpudct pack
    const showProductType = (e) => {

        const { typegroup } = guideline
        const ischecked = e.target.checked;
        const value = e.target.value;

        if (ischecked) {
            if (e.target.name == 'grouptypeOther') {


                setGuideline({
                    ...guideline,
                    typegroup: [...typegroup, value],
                });


            } else if (e.target.name == 'grouptypeDryfood') {


                setGuideline({
                    ...guideline,
                    typegroup: [...typegroup, value],
                });

            }
            else if (e.target.name == 'grouptypeAerosol') {


                setGuideline({
                    ...guideline,
                    typegroup: [...typegroup, value],
                });

            } else if (e.target.name == 'grouptypeGeneral') {


                setGuideline({
                    ...guideline,
                    typegroup: [...typegroup, value],
                });
            }

        } else {

            if (e.target.name == 'grouptypeOther') {


                setGuideline({
                    ...guideline,
                    typegroup: typegroup.filter((e) => e !== value),
                });

            } else if (e.target.name == 'grouptypeDryfood') {


                setGuideline({
                    ...guideline,
                    typegroup: typegroup.filter((e) => e !== value),
                });
            } else if (e.target.name == 'grouptypeAerosol') {

                setGuideline({
                    ...guideline,
                    typegroup: typegroup.filter((e) => e !== value),
                });
            } else if (e.target.name == 'grouptypeGeneral') {

                setGuideline({
                    ...guideline,
                    typegroup: typegroup.filter((e) => e !== value),
                });
            }
        }
    }


    function handleChange(e) {

        const ischecked = e.target.checked;
        const istext = e.target.value;


        if (ischecked) {
            setRegulationcheck([...regulationcheck, e.target.value])

        }
        else {
            const index = regulationcheck.indexOf(e.target.value);
            regulationcheck.splice(index, 1);
            setRegulationcheck(regulationcheck);
        }

        if (e.target.type == 'text') {
            setregulationOther(e.target.value)
        }
    };

    const handle_bodychecked = (e) => {

        const { value, checked } = e.target;
        const { groupBobyType, intcoating, extcoating } = coatingbody;

        // Case 1 : The user checks the box
        if (checked) {
            if (e.target.name == "groupBobyType") {
                setCoatingbody({
                    ...coatingbody,
                    groupBobyType: [...groupBobyType, value],
                });
            }

            else if (e.target.name == "intcoatingbody") {
                setCoatingbody({
                    ...coatingbody,
                    intcoating: [...intcoating, value],
                });
            }
            else if (e.target.name == "extcoatingbody") {
                setCoatingbody({
                    ...coatingbody,
                    extcoating: [...extcoating, value],
                });
            }
            else if (e.target.value == 'bodyProof') {
                setCoatingbody({ ...coatingbody, statusProof: value })
            }

        }
        // Case 2 : The user unchecks the box
        else {
            if (e.target.name == 'groupBobyType') {
                setCoatingbody({
                    ...coatingbody,
                    groupBobyType: groupBobyType.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "intcoatingbody") {
                setCoatingbody({
                    ...coatingbody,
                    intcoating: intcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "extcoatingbody") {
                setCoatingbody({
                    ...coatingbody,
                    extcoating: extcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.value == 'bodyProof') {
                setCoatingbody({ ...coatingbody, statusProof: "" })
            }
        }

    }

    const handle_topchecked = (e) => {
        const { value, checked } = e.target;
        const { substrate, shape, intcoating, extcoating } = coatingtop;

        if (checked) {
            if (e.target.name == "substratetop") {
                setCoatingtop({
                    ...coatingtop,
                    substrate: [...substrate, value],
                });
            }
            else if (e.target.name == "Shapetop") {
                setCoatingtop({
                    ...coatingtop,
                    shape: [...shape, value],
                });
            }
            else if (e.target.name == "intcoatingtop") {
                setCoatingtop({
                    ...coatingtop,
                    intcoating: [...intcoating, value],
                });
            }
            else if (e.target.name == "extcoatingtop") {
                setCoatingtop({
                    ...coatingtop,
                    extcoating: [...extcoating, value],
                });

            }
            else if (e.target.name == 'topProof') {
                setCoatingtop({ ...coatingtop, statusProof: value })
            }
        }
        else {
            if (e.target.name == 'substratetop') {
                setCoatingtop({
                    ...coatingtop,
                    substrate: substrate.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "Shapetop") {

                setCoatingtop({
                    ...coatingtop,
                    shape: shape.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "intcoatingtop") {
                setCoatingtop({
                    ...coatingtop,
                    intcoating: intcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "extcoatingtop") {
                setCoatingtop({
                    ...coatingtop,
                    extcoating: extcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.name == 'topProof') {
                setCoatingtop({ ...coatingtop, statusProof: "" })
            }
        }
    }

    const handle_bottomchecked = (e) => {

        const { value, checked } = e.target;
        const { substrate, shape, intcoating, extcoating } = coatingbottom;
        if (checked) {
            if (e.target.name == "substratebottom") {
                setCoatingbottom({
                    ...coatingbottom,
                    substrate: [...substrate, value],
                });
            }
            else if (e.target.name == "Shapebottom") {
                setCoatingbottom({
                    ...coatingbottom,
                    shape: [...shape, value],
                });
            }
            else if (e.target.name == "intcoatingbottom") {
                setCoatingbottom({
                    ...coatingbottom,
                    intcoating: [...intcoating, value],
                });
            }
            else if (e.target.name == "extcoatingbottom") {
                setCoatingbottom({
                    ...coatingbottom,
                    extcoating: [...extcoating, value],
                });

            }
            else if (e.target.name == 'bottomProof') {
                setCoatingbottom({ ...coatingbottom, statusProof: value })
            }
        }
        else {
            if (e.target.name == 'substratebottom') {
                setCoatingbottom({
                    ...coatingbottom,
                    substrate: substrate.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "Shapebottom") {

                setCoatingbottom({
                    ...coatingbottom,
                    shape: shape.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "intcoatingbottom") {
                setCoatingbottom({
                    ...coatingbottom,
                    intcoating: intcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "extcoatingbottom") {
                setCoatingbottom({
                    ...coatingbottom,
                    extcoating: extcoating.filter((e) => e !== value),
                });
            }
            else if (e.target.name == 'bottomProof') {
                setCoatingbottom({ ...coatingbottom, statusProof: "" })
            }
        }
    }

    const handle_Ringchecked = (e) => {
        const { value, checked } = e.target;
        const { ring_eartype, intcoating, extcoating } = coatingring;

        if (checked) {
            if (e.target.name == 'coatingDia') {
                setCoatingring({
                    ...coatingring,
                    ring_eartype: [...ring_eartype, value]
                })
            } else if (e.target.name == 'intcoatingring') {
                setCoatingring({
                    ...coatingring,
                    intcoating: [...intcoating, value]
                })
            }
            else if (e.target.name == "extcoatingring") {
                setCoatingring({
                    ...coatingring,
                    extcoating: [...extcoating, value]
                })
            } else if (e.target.name == 'ringProof') {
                setCoatingring({
                    ...coatingring,
                    statusProof: value
                })
            }

        }
        else {
            if (e.target.name == 'coatingDia') {
                setCoatingring({
                    ...coatingring,
                    ring_eartype: ring_eartype.filter((e) => e !== value)
                })

            } else if (e.target.name == 'intcoatingring') {
                setCoatingring({
                    ...coatingring,
                    intcoating: intcoating.filter((e) => e !== value)
                })
            }
            else if (e.target.name == "extcoatingring") {
                setCoatingring({
                    ...coatingring,
                    extcoating: extcoating.filter((e) => e !== value)
                })
            } else if (e.target.name == 'ringProof') {
                setCoatingring({
                    ...coatingring,
                    statusProof: ""
                })
            }
        }
    }

    const handle_earCheck = (e) => {
        const { value, checked } = e.target;
        const { ring_eartype, intcoating } = coatingear;
        if (checked) {
            if (e.target.name == 'eartype') {
                setCoatingear({
                    ...coatingear,
                    ring_eartype: [...ring_eartype, value]
                })
            }
            else if (e.target.name == 'intcoatingear') {
                setCoatingear({
                    ...coatingear,
                    intcoating: [...intcoating, value]
                })
            }
            else if (e.target.name == 'earProof') {
                setCoatingear({
                    ...coatingear,
                    statusProof: value
                })
            }

        }
        else {

            if (e.target.name == 'eartype') {
                setCoatingear({
                    ...coatingear,
                    ring_eartype: ring_eartype.filter((e) => e !== value)
                })
            } else if (e.target.name == 'intcoatingear') {
                setCoatingear({
                    ...coatingear,
                    intcoating: intcoating.filter((e) => e !== value)
                })
            }
            else if (e.target.name == 'earProof') {
                setCoatingear({ ...coatingear, statusProof: "" })
            }
        }
    }

    const handle_formingchecked = (e) => {
        const { value, checked } = e.target;
        const { internalside, seaming } = forming;

        if (checked) {
            if (e.target.name == "internalside") {
                setForming({
                    ...forming,
                    internalside: [...internalside, value],
                });
            }
            else if (e.target.name == "seaming") {
                setForming({
                    ...forming,
                    seaming: [...seaming, value],
                });
            }

        } else {
            if (e.target.name == "internalside") {
                setForming({
                    ...forming,
                    internalside: internalside.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "seaming") {

                setForming({
                    ...forming,
                    seaming: seaming.filter((e) => e !== value),
                });
            }
        }
    }


    const handle_guidelinechecked = (e) => {
        const { value, checked } = e.target;
        const { groupother, groupdryfood, ph, ingredients, seamercondition, sterilization } = guideline;

        if (checked) {

            if (e.target.name == "productgroupother") {
                setGuideline({
                    ...guideline,
                    groupother: [...groupother, value],
                });
            }
            else if (e.target.name == "ph") {
                setGuideline({
                    ...guideline,
                    ph: [...ph, value],
                });
            }
            else if (e.target.name == "Ingredientstype") {
                setGuideline({
                    ...guideline,
                    ingredients: [...ingredients, value],
                });
            }
            else if (e.target.name == "seamercondition") {
                setGuideline({
                    ...guideline,
                    seamercondition: [...seamercondition, value],
                });
            }
            else if (e.target.name == "sterilization") {
                setGuideline({
                    ...guideline,
                    sterilization: [...sterilization, value],
                });
            }

        }
        else {
            if (e.target.name == "productgroupother") {

                setGuideline({
                    ...guideline,
                    groupother: groupother.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "ph") {
                setGuideline({
                    ...guideline,
                    ph: ph.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "Ingredientstype") {
                setGuideline({
                    ...guideline,
                    ingredients: ingredients.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "seamercondition") {
                setGuideline({
                    ...guideline,
                    seamercondition: seamercondition.filter((e) => e !== value),
                });
            }
            else if (e.target.name == "sterilization") {
                setGuideline({
                    ...guideline,
                    sterilization: sterilization.filter((e) => e !== value),
                });
            }

        }
    }

    const onchangeComponentbody = (values) => {
        setCoatingbody({ ...coatingbody, fgcomponent: values })
    }

    const onchangeComponenttop = (values) => {
        setCoatingtop({ ...coatingtop, fgcomponent: values })
    }
    const onchangeComponentbottom = (values) => {
        setCoatingbottom({ ...coatingbottom, fgcomponent: values })
    }

    return (
        <div className='form-body' >
            {dataDocument == null ?
                <div className='body-center-page'><Spinner
                    as="span"
                    animation="grow"
                    variant="success"
                    size={200}
                    role="status"
                    aria-hidden="true" /> LOADIN ....</div>
                :
                <form onSubmit={(e) => submitSave(e)}>
                    <div style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}>
                        <div style={{ marginRight: 10 }}>
                            <Button type='submit' size='sm' style={{ background: '#0DB48B', borderColor: '#0DB48B' }} >บันทึก รายละเอียด</Button>
                        </div>
                        <div>
                            <Button type='reset' size='sm' style={{ backgroundColor: '#F7B528', borderColor: '#F7B528', marginRight: 10 }}>ยกเลิก</Button>
                        </div>
                    </div>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="Coating" title="Coating System" style={{ backgroundColor: 'aliceblue' }}>

                            <div>
                                <div>
                                    <div style={{ marginLeft: 10 }}><b>Coating system</b></div>

                                    <div className='row-page' style={{ padding: 10 }}>

                                        <div style={{ width: 100 }}><b>Body</b></div>
                                        <div style={{ width: "50%" }}>

                                            <div className='row-between-form'>
                                                {dataDocument.coating.body.data.map((item, i) => (
                                                    <div>
                                                        <input type='checkbox' name='groupBobyType' value={item}

                                                            onClick={(e) => handle_bodychecked(e)}
                                                        /> {item}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ width: "70%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                                        <div className='row-between-form'>
                                            <b>Int {" : "}</b>
                                            {dataDocument.coating.int.data.map((item, i) => (
                                                <div>
                                                    {item == 'white-coat' ? <div>
                                                        <input type='text' name='intwhitebody' placeholder={item}
                                                            onChange={(e) => setCoatingbody({ ...coatingbody, intwhite: e.target.value })}

                                                        />
                                                    </div> :
                                                        <div>
                                                            <input type='checkbox' name='intcoatingbody' value={item}
                                                                onClick={(e) => handle_bodychecked(e)}
                                                            /> {item}
                                                        </div>
                                                            && item == 'Other' ? <div>
                                                            <input type='text' name='intotherbody' placeholder={item}
                                                                onChange={(e) => setCoatingbody({ ...coatingbody, intother: e.target.value })}

                                                            />
                                                        </div> :
                                                            <div>
                                                                <input type='checkbox' name='intcoatingbody' value={item}

                                                                    onClick={(e) => handle_bodychecked(e)}
                                                                /> {item}
                                                            </div>}
                                                </div>
                                            ))}
                                        </div>

                                        <div className='row-between-form'>
                                            <b>Ext {" : "}</b>
                                            {dataDocument.coating.ext.data.map((item, i) => (
                                                <div>{item == 'white-coat' ? <div>
                                                    <input type='text' name='extwhitebody' placeholder={item}
                                                        onChange={(e) => setCoatingbody({ ...coatingbody, extwhite: e.target.value })}

                                                    />
                                                </div> :
                                                    <div>
                                                        {item}<input type='checkbox' name='extcoatingbody' value={item}

                                                            onClick={(e) => handle_bodychecked(e)}
                                                        />
                                                    </div>
                                                        && item == 'Other' ? <div>
                                                        <input type='text' name='extotherbody' placeholder={item}
                                                            onChange={(e) => setCoatingbody({ ...coatingbody, extother: e.target.value })}
                                                        />
                                                    </div> :
                                                        <div>
                                                            <input type='checkbox' name='extcoatingbody' value={item}

                                                                onClick={(e) => handle_bodychecked(e)}
                                                            /> {item}
                                                        </div>}</div>
                                            ))}
                                        </div>
                                        <div className='row-page'>
                                            <label><b>FG Component :</b></label>
                                            <input type='text' name='bodyfgcomponent'
                                                value={coatingbody.fgcomponent}
                                                onChange={(e) => setCoatingbody({ ...coatingbody, fgcomponent: e.target.value })} style={{ width: 300 }} />
                                            <PopupFGcomponent typecom={'componentbody'} onchangeComponentbody={onchangeComponentbody} />
                                        </div>

                                        <div>
                                            <div>
                                                <label>Note : </label>
                                            </div>

                                            <textarea type='text' name='textnodebody' style={{ width: 450, height: 50 }}
                                                onChange={(e) => setCoatingbody({ ...coatingbody, note: e.target.value })}
                                                maxLength={350}
                                            />

                                        </div>

                                    </div>


                                    <div> {BodyError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""} </div>
                                </div>
                                <hr></hr>


                                <div className='row-page' style={{ padding: 10 }}>

                                    <div style={{ width: 100 }}>
                                        <b>Top end</b>
                                        <div><input type='number' name='numbertopendtop' style={{ width: 70 }}
                                            onChange={(e) => setCoatingtop({ ...coatingtop, number: e.target.value })}

                                        /></div>
                                    </div>
                                    <div style={{ width: "50%" }}>

                                        <div className='row-between-form'>
                                            <b>Substrate :</b>
                                            {dataDocument.coating.topend.substrate.map((item, i) => (
                                                <div>
                                                    <input type='checkbox' name='substratetop' value={item}
                                                        onClick={(e) => handle_topchecked(e)}
                                                    /> {item}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='row-between-form'>
                                            <b>Shape :</b>
                                            {dataDocument.coating.topend.shape.map((item, i) => (
                                                <div>
                                                    <div><input type='checkbox' name='Shapetop' value={item}
                                                        onClick={(e) => handle_topchecked(e)}
                                                    /> {item}

                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: 110 }}>
                                    <div><label><b>for sale order : </b></label></div>
                                    <textarea type='text' name='forsaleorder' onChange={(e) => setCoatingtop({ ...coatingtop, forsaleorder: e.target.value })}
                                        style={{ width: 450 }} maxLength={250} />
                                </div>

                                <div style={{ width: "70%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                                    <label><b>Coating system</b></label>
                                    <div className='row-between-form'>
                                        <b>Int {" : "}</b>
                                        {dataDocument.coating.int.data.map((item, i) => (
                                            <div>{item == 'white-coat' ? <div>
                                                <input type='text' name='intwhitetop' placeholder={item}
                                                    onChange={(e) => setCoatingtop({ ...coatingtop, intwhite: e.target.value })}

                                                />
                                            </div> :
                                                <div>
                                                    <input type='checkbox' name='intcoatingtop' value={item}

                                                        onClick={(e) => handle_topchecked(e)}
                                                    /> {item}
                                                </div>
                                                    && item == 'Other' ? <div>
                                                    <input type='text' name='intothertop' placeholder={item}
                                                        onChange={(e) => setCoatingtop({ ...coatingtop, intother: e.target.value })}
                                                    />
                                                </div> :
                                                    <div>
                                                        <input type='checkbox' name='intcoatingtop' value={item}
                                                            onClick={(e) => handle_topchecked(e)}
                                                        /> {item}
                                                    </div>}</div>
                                        ))}
                                    </div>

                                    <div className='row-between-form'>
                                        <b>Ext {" : "}</b>
                                        {dataDocument.coating.ext.data.map((item, i) => (
                                            <div>{item == 'white-coat' ? <div>
                                                <input type='text' name='extwhitetop' placeholder={item}
                                                    onChange={(e) => setCoatingtop({ ...coatingtop, extwhite: e.target.value })}

                                                />
                                            </div> :
                                                <div>
                                                    <input type='checkbox' name='extcoatingtop' value={item}
                                                        onClick={(e) => handle_topchecked(e)}
                                                    /> {item}
                                                </div>
                                                    && item == 'Other' ? <div>
                                                    <input type='text' name='extothertop' placeholder={item}
                                                        onChange={(e) => setCoatingtop({ ...coatingtop, extother: e.target.value })}

                                                    />
                                                </div> :
                                                    <div>
                                                        <input type='checkbox' name='extcoatingtop' value={item}
                                                            onClick={(e) => handle_topchecked(e)}
                                                        /> {item}
                                                    </div>}</div>
                                        ))}
                                    </div>
                                    <div className='row-page'>
                                        <label><b>FG Component :</b></label>
                                        <input type='text' name='topfgcomponent'
                                            value={coatingtop.fgcomponent}
                                            onChange={(e) => setCoatingtop({ ...coatingtop, fgcomponent: e.target.value })} style={{ width: 300 }} />
                                        <PopupFGcomponent typecom={'componenttop'} onchangeComponenttop={onchangeComponenttop} />
                                    </div>
                                    <div>
                                        <div>
                                            <label>Note : </label>
                                        </div>
                                        <textarea type='text' name='textnodetop' style={{ width: 450, height: 50 }}
                                            onChange={(e) => setCoatingtop({ ...coatingtop, note: e.target.value })}
                                            maxLength={350}
                                        />

                                    </div>
                                </div>


                                <div>{TopError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""}</div>
                            </div>

                            <hr></hr>

                            <div>
                                <div className='row-page' style={{ padding: 10 }}>
                                    <div style={{ width: 100 }}>
                                        <b>Bottom end</b>
                                        <div>
                                            <input type='number' name='numberbottom' style={{ width: 70 }}
                                                onChange={(e) => setCoatingbottom({ ...coatingbottom, number: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <div className='row-between-form'>
                                            <b>Substrate :</b>
                                            {dataDocument.coating.bottomend.substrate.map((item, i) => (
                                                <div><input type='checkbox' name='substratebottom' value={item}
                                                    onClick={(e) => handle_bottomchecked(e)}

                                                /> {item}</div>
                                            ))}
                                        </div>
                                        <div className='row-between-form'>
                                            <b>Shape :</b>
                                            {dataDocument.coating.bottomend.shape.map((item, i) => (
                                                <div>
                                                    <input type='checkbox' name='Shapebottom' value={item}
                                                        onClick={(e) => handle_bottomchecked(e)}
                                                    /> {item}</div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: 110 }}>
                                    <div>
                                        <label><b>for sale order : </b></label>
                                    </div>
                                    <textarea type='text' name='forsaleorder' onChange={(e) => setCoatingbottom({ ...coatingbottom, forsaleorder: e.target.value })}
                                        style={{ width: 450, height: 50 }} maxLength={250} />
                                </div>
                                <div style={{ width: "70%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                                    <div className='row-between-form'>
                                        <b>Int {" : "}</b>
                                        {dataDocument.coating.int.data.map((item, i) => (
                                            <div>{item == 'white-coat' ? <div>
                                                <input type='text' name='intwhitebottom' placeholder={item}
                                                    onChange={(e) => setCoatingbottom({ ...coatingbottom, intwhite: e.target.value })}

                                                />
                                            </div> :
                                                <div>
                                                    <input type='checkbox' name='intcoatingbottom' value={item}

                                                        onClick={(e) => handle_bottomchecked(e)}

                                                    /> {item}
                                                </div>
                                                    && item == 'Other' ? <div>
                                                    <input type='text' name='intotherbottom' placeholder={item}
                                                        onChange={(e) => setCoatingbottom({ ...coatingbottom, intother: e.target.value })}

                                                    />
                                                </div> :
                                                    <div>
                                                        <input type='checkbox' name='intcoatingbottom' value={item}
                                                            onClick={(e) => handle_bottomchecked(e)}
                                                        /> {item}
                                                    </div>}</div>
                                        ))}
                                    </div>
                                    <div className='row-between-form'>
                                        <b>Ext {" : "}</b>
                                        {dataDocument.coating.ext.data.map((item, i) => (
                                            <div>{item == 'white-coat' ? <div>
                                                <input type='text' name='extwhitebottom' placeholder={item}
                                                    onChange={(e) => setCoatingbottom({ ...coatingbottom, extwhite: e.target.value })}

                                                />
                                            </div> :
                                                <div>
                                                    <input type='checkbox' name='extcoatingbottom' value={item}
                                                        onClick={(e) => handle_bottomchecked(e)}
                                                    /> {item}
                                                </div>
                                                    && item == 'Other' ? <div>
                                                    <input type='text' name='extotherbottom' placeholder={item}
                                                        onChange={(e) => setCoatingbottom({ ...coatingbottom, extother: e.target.value })}

                                                    />
                                                </div> :
                                                    <div>
                                                        <input type='checkbox' name='extcoatingbottom' value={item}
                                                            onClick={(e) => handle_bottomchecked(e)}
                                                        /> {item}
                                                    </div>}</div>
                                        ))}
                                    </div>
                                    <div className='row-page'>
                                        <label><b>FG Component :</b></label>
                                        <input type='text' name='bottomfgcomponent'
                                            value={coatingbottom.fgcomponent}
                                            onChange={(e) => setCoatingbottom({ ...coatingbottom, fgcomponent: e.target.value })} style={{ width: 300 }} />
                                        <PopupFGcomponent typecom={'componentbottom'} onchangeComponentbottom={onchangeComponentbottom} />
                                    </div>
                                    <div>
                                        <div>
                                            <label>Note : </label>
                                        </div>
                                        <input type='text' name='textnodebottom' style={{ width: 450, height: 50 }}
                                            onChange={(e) => setCoatingbottom({ ...coatingbottom, note: e.target.value })}
                                            maxLength={350}
                                        />

                                    </div>
                                </div>

                                <div>{BottomError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""}</div>
                            </div>

                            <hr></hr>

                            {typedocument == 'A2' &&

                                <div>
                                    <div className='row-page' style={{ padding: 10 }}>

                                        <div style={{ width: 100 }}>
                                            <b>Ring</b>
                                        </div>
                                        <div style={{ width: "40%" }}>
                                            <div className='row-between-form'>
                                                <b>Dia : <input type='text' name='dia' onChange={(e) => setCoatingring({ ...coatingring, dia: e.target.value })} /></b>

                                                {dataDocument.coating.ring.dai.map((item, i) => (
                                                    <div><input type='checkbox' name='coatingDia' value={item}
                                                        onClick={(e) => handle_Ringchecked(e)}

                                                    /> {item}</div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 110 }} className='row-page'>
                                        <div style={{ marginRight: 20 }}>
                                            <label>Code :</label>
                                            <input type='text' name='ringcode' onChange={(e) => setCoatingring({ ...coatingring, ring_earcode: e.target.value })} />
                                        </div>
                                        <div>
                                            <label><b>for sale order : </b></label>
                                            <input type='text' name='forsaleorder' style={{ width: 350 }}
                                                onChange={(e) => setCoatingring({ ...coatingring, forsaleorder: e.target.value })} />
                                        </div>
                                    </div>
                                    <div style={{ width: "70%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                                        <div className='row-between-form'>
                                            <b>Int {" : "}</b>
                                            {dataDocument.coating.int.data.map((item, i) => (
                                                <div>{item == 'white-coat' ? <div>
                                                    <input type='text' name='intwhitebottom' placeholder={item}
                                                        onChange={(e) => setCoatingring({ ...coatingring, intwhite: e.target.value })}

                                                    />
                                                </div> :
                                                    <div>
                                                        <input type='checkbox' name='intcoatingring' value={item}

                                                            onClick={(e) => handle_Ringchecked(e)}

                                                        /> {item}
                                                    </div>
                                                        && item == 'Other' ? <div>
                                                        <input type='text' name='intotherring' placeholder={item}
                                                            onChange={(e) => setCoatingring({ ...coatingring, intother: e.target.value })}

                                                        />
                                                    </div> :
                                                        <div>
                                                            <input type='checkbox' name='intcoatingring' value={item}
                                                                onClick={(e) => handle_Ringchecked(e)}
                                                            /> {item}
                                                        </div>}</div>
                                            ))}
                                        </div>
                                        <div className='row-between-form'>
                                            <b>Ext {" : "}</b>
                                            {dataDocument.coating.ext.data.map((item, i) => (
                                                <div>{item == 'white-coat' ? <div>
                                                    <input type='text' name='extwhitebottom' placeholder={item}
                                                        onChange={(e) => setCoatingring({ ...coatingring, extwhite: e.target.value })}

                                                    />
                                                </div> :
                                                    <div>
                                                        <input type='checkbox' name='extcoatingring' value={item}
                                                            onClick={(e) => handle_Ringchecked(e)}
                                                        /> {item}
                                                    </div>
                                                        && item == 'Other' ? <div>
                                                        <input type='text' name='extcoatingring' placeholder={item}
                                                            onChange={(e) => setCoatingring({ ...coatingring, extother: e.target.value })}

                                                        />
                                                    </div> :
                                                        <div>
                                                            <input type='checkbox' name='extcoatingring' value={item}
                                                                onClick={(e) => handle_Ringchecked(e)}
                                                            /> {item}
                                                        </div>}</div>
                                            ))}
                                        </div>

                                        <div>{RingError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""}</div>
                                    </div>
                                </div>

                            }

                            {typedocument == 'A2' &&
                                <div>
                                    <div className='row-page' style={{ padding: 10 }}>

                                        <div style={{ width: 100 }}>
                                            <b>Ear</b>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 110, width: '40%' }} className='row-page'>

                                        <div style={{ marginRight: 20 }}>
                                            <label>Code :</label>
                                            <input type='text' name='earcode' onChange={(e) => setCoatingear({ ...coatingear, ring_earcode: e.target.value })} />
                                        </div>
                                        <div className='row-between-form' style={{ width: '30%' }}>
                                            {dataDocument.coating.ear.eartype.map((item, i) => (
                                                <div>
                                                    <input type='checkbox' name='eartype' value={item} onClick={(e) => handle_earCheck(e)}

                                                    /> {item}</div>
                                            ))}
                                        </div>


                                    </div>
                                    <div style={{ width: "70%", marginLeft: 110, backgroundColor: '#F1F0ED', padding: 5 }}>
                                        <div className='row-between-form'>
                                            <b>Int {" : "}</b>
                                            {dataDocument.coating.int.data.map((item, i) => (
                                                <div>{item == 'white-coat' ? <div>
                                                    <input type='text' name='intwhitear' placeholder={item}
                                                        onChange={(e) => setCoatingear({ ...coatingear, intwhite: e.target.value })}

                                                    />
                                                </div> :
                                                    <div>
                                                        <input type='checkbox' name='intcoatingear' value={item}

                                                            onClick={(e) => handle_earCheck(e)}

                                                        /> {item}
                                                    </div>
                                                        && item == 'Other' ? <div>
                                                        <input type='text' name='intotherear' placeholder={item}
                                                            onChange={(e) => setCoatingear({ ...coatingear, intother: e.target.value })}

                                                        />
                                                    </div> :
                                                        <div>
                                                            <input type='checkbox' name='intcoatingear' value={item}
                                                                onClick={(e) => handle_earCheck(e)}
                                                            /> {item}
                                                        </div>}</div>
                                            ))}
                                        </div>

                                        <div>{EarError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""}</div>
                                    </div>
                                </div>
                            }

                        </Tab>

                        <Tab eventKey="forming" title="Can & end forming" style={{ backgroundColor: 'aliceblue' }}>
                            <div style={{ marginLeft: 10 }}>
                                <div>
                                    <b>Can & End forming</b>
                                </div>

                                <div >
                                    <div>

                                        <div className='row-page' style={{ width: '100%' }}>
                                            <div style={{ width: 250 }}>
                                                <b>Internal side stripe: </b>
                                            </div>
                                            <div className='row-page' style={{ width: 1000 }}>
                                                {dataDocument.forming.internalside.map((item, i) => (
                                                    <div> {item == 'Other' ? <div style={{ marginRight: 100, width: 150 }}>
                                                        <input type='text' name='internalother' placeholder='other'
                                                            onChange={(e) => setForming({ ...forming, internalother: e.target.value })}

                                                        /></div> :
                                                        <div style={{ marginRight: 100, width: 150 }}>
                                                            <input type='checkbox' name='internalside' value={item}
                                                                onClick={(e) => handle_formingchecked(e)}
                                                            /> {item}</div>}</div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div >
                                    <div>
                                        <div className='row-page' style={{ width: '100%' }}>
                                            <div style={{ width: 250 }}>
                                                <b>Seaming : </b>
                                            </div>
                                            <div className='row-page' style={{ width: 1000 }}>
                                                {dataDocument.forming.seaming.map((item, i) => (
                                                    <div>{item == 'Other' ? <div style={{ marginRight: 100, width: 150 }}>
                                                        <input type='text' name='seamingother'
                                                            onChange={(e) => setForming({ ...forming, seamingother: e.target.value })}
                                                            placeholder='other'
                                                        />
                                                    </div> :
                                                        <div style={{ marginRight: 100, width: 150 }}>
                                                            <input type='checkbox' name='seaming' value={item}
                                                                onClick={(e) => handle_formingchecked(e)}
                                                            /> {item}
                                                        </div>}</div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row-page' style={{ width: '100%' }}>
                                    <div style={{ width: 250, height: 50 }}>
                                        <b>Packaging :</b>
                                    </div>
                                    <div>
                                        <div>Packaging : </div>
                                        <textarea type='text' name='packaging' style={{ width: 250, height: 50, marginRight: 10 }}
                                            onChange={(e) => setForming({ ...forming, packaging: e.target.value })}
                                            maxLength={250}
                                        // required
                                        />
                                    </div>
                                    <div>
                                        <div>Note :</div>
                                        <textarea type='text' name='PackagingNote' style={{ width: 450, height: 50 }}
                                            onChange={(e) => setForming({ ...forming, note: e.target.value })} maxLength={350}
                                        />
                                    </div>
                                </div>
                                <div>{formingError == true ? <label style={{ color: 'red' }}>* มีรายการเลือกเกิน 1 รายการ *</label> : ""}</div>
                            </div>
                        </Tab>

                        <Tab eventKey="Guideline" title="Guideline for product pack" style={{ backgroundColor: 'aliceblue' }}>
                            <div style={{ marginLeft: 10 }}>
                                <div><b>Guideline for product pack</b></div>
                                <br></br>

                                <div>
                                    <div className='row-page' style={{ width: '100%' }}>
                                        <div style={{ width: 150 }}><b>Product shelf-life</b> :</div>
                                        <div>
                                            <input type='text' name='productshelt'
                                                onChange={(e) => setGuideline({ ...guideline, shelfyears: e.target.value })}

                                            /> years / at
                                            {" "}<input type='text' name='degC'
                                                onChange={(e) => setGuideline({ ...guideline, shelfdeg: e.target.value })}

                                            /> degC</div>
                                    </div>
                                    <hr></hr>

                                    <div style={{ width: 1100, marginBottom: 5 }}>
                                        <div style={{ width: 300 }}>
                                            <label><b>1.Product group :</b></label>
                                        </div>


                                        <div className='row-page'>

                                            {typedocument == 'A1' ?
                                                <div style={{ marginLeft: 150 }}>

                                                    <center><input type='checkbox' name='grouptypeOther' value={'typefoods'} onClick={(e) => showProductType(e)} /> <label>Group foods </label> </center>
                                                    {dataDocument.guideline.productgroup.groupother.map((item, i) => (
                                                        <div>{item == 'Other' ? <div>
                                                            <input type='text' name='groupother_note' placeholder='other' style={{ width: 250 }}
                                                                onChange={(e) => setGuideline({ ...guideline, groupother_note: e.target.value })}
                                                            />
                                                        </div> :
                                                            <div>
                                                                <input type='checkbox' name='productgroupother' value={item}
                                                                    onClick={(e) => handle_guidelinechecked(e)}

                                                                /> {item}
                                                            </div>}</div>

                                                    ))}

                                                </div> :
                                                <div className='row-between-form'>

                                                    <div style={{ marginLeft: 100 }}>
                                                        <center><input type='checkbox' name='grouptypeDryfood' value={'dryfood'} onClick={(e) => showProductType(e)} /> <label>Dry foods</label> </center>
                                                        {dataDocument.guideline.productgroup.groupDryfoods.map((item, i) => (
                                                            <div>
                                                                {item == 'Other' ? <div>
                                                                    <input type='text' name='groupother_note' placeholder='other' style={{ width: 250 }}
                                                                        onChange={(e) => setGuideline({ ...guideline, groupother_note: e.target.value })}

                                                                    />
                                                                </div> :
                                                                    <div>
                                                                        <input type='checkbox' name='productgroupother' value={item}
                                                                            onClick={(e) => handle_guidelinechecked(e)}

                                                                        /> {item}
                                                                    </div>}</div>
                                                        ))}
                                                    </div>

                                                    <div style={{ marginLeft: 50 }}>
                                                        <center><input type='checkbox' name='grouptypeAerosol' value={'aerosol'} onClick={(e) => showProductType(e)} /> <label>Aerosol can</label> </center>
                                                        {dataDocument.guideline.productgroup.groupAerosolcan.map((item, i) => (
                                                            <div>
                                                                {item == 'Other' ? <div>
                                                                    <input type='text' name='groupother_note' placeholder='other' style={{ width: 250 }}
                                                                        onChange={(e) => setGuideline({ ...guideline, groupother_note: e.target.value })}

                                                                    />
                                                                </div> :
                                                                    <div>
                                                                        <input type='checkbox' name='productgroupother' value={item}
                                                                            onClick={(e) => handle_guidelinechecked(e)}

                                                                        /> {item}
                                                                    </div>}</div>
                                                        ))}
                                                    </div>

                                                    <div style={{ marginLeft: 50 }}>
                                                        <center><input type='checkbox' name='grouptypeGeneral' value={'general'} onClick={(e) => showProductType(e)} /> <label>General can</label> </center>
                                                        {dataDocument.guideline.productgroup.groupGeneralcan.map((item, i) => (
                                                            <div>
                                                                {item == 'Other' ? <div>
                                                                    <input type='text' name='groupother_note' placeholder='other' style={{ width: 250 }}
                                                                        onChange={(e) => setGuideline({ ...guideline, groupother_note: e.target.value })}

                                                                    />
                                                                </div> :
                                                                    <div>
                                                                        <input type='checkbox' name='productgroupother' value={item}
                                                                            onClick={(e) => handle_guidelinechecked(e)}

                                                                        /> {item}
                                                                    </div>}</div>
                                                        ))}
                                                    </div>

                                                </div>}

                                        </div>

                                    </div>

                                    <hr></hr>

                                    <div style={{ width: '100%', marginBottom: 5 }} className='row-page'>
                                        <div className='row-page'>
                                            <div style={{ width: 250 }}><b>2.Details of product :</b></div>
                                            <div>
                                                <textarea type='text' name='Detailsproduct' style={{ width: 500, height: 50 }}
                                                    onChange={(e) => setGuideline({ ...guideline, detailproduct: e.target.value })}
                                                    maxLength={350}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row-page' style={{ width: 1100, marginBottom: 5 }}>
                                        <div className='row-page'>
                                            <div style={{ width: 150 }}>
                                                <b>3. PH:</b>
                                            </div>
                                            <div style={{ width: 800 }} className='row-between-form'>
                                                {dataDocument.guideline.ph.data.map((item, i) => (
                                                    <div><input type='checkbox' name='ph' value={item}
                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                    /> {item}</div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row-page' style={{ width: '100%', marginBottom: 5 }}>

                                    <div className='row-page'>
                                        <div style={{ width: 150 }}>
                                            <b>4.Ingredients :</b>
                                        </div>

                                        <div className='row-between-form' style={{ width: 1200 }} >
                                            {dataDocument.guideline.ingredients.data.map((item, i) => (
                                                <div>{item == 'Other' ?
                                                    <div>
                                                        <input type='checkbox' name='Ingredientstype' value={item}
                                                            onClick={(e) => handle_guidelinechecked(e)}

                                                        /> {item}
                                                        <input type='text' name='ingredientsOther' placeholder='input detail'
                                                            onChange={(e) => setGuideline({ ...guideline, ingredientother: e.target.value })} style={{ marginLeft: 20 }}

                                                        />
                                                    </div> :
                                                    <div>
                                                        <input type='checkbox' name='Ingredientstype' value={item}
                                                            onClick={(e) => handle_guidelinechecked(e)}
                                                        /> {item}
                                                    </div>
                                                        && item == 'Acid added' ?
                                                        <div>
                                                            <input type='checkbox' name='Ingredientstype' value={item}
                                                                onClick={(e) => handle_guidelinechecked(e)}
                                                            /> {item}
                                                            <input type='text' name='Acid_added'
                                                                value={guideline.ingredients_acid}
                                                                onChange={(e) => setGuideline({ ...guideline, ingredients_acid: e.target.value })} />

                                                        </div> :
                                                        <div>
                                                            <input type='checkbox' name='Ingredientstype' value={item}
                                                                onClick={(e) => handle_guidelinechecked(e)}
                                                            /> {item}
                                                        </div>
                                                            && item == 'Chloride(as NaCI)' ?
                                                            <div>
                                                                <input type='checkbox' name='Ingredientstype' value={item}
                                                                    onClick={(e) => handle_guidelinechecked(e)}
                                                                /> {item}
                                                                <input type='text' name='Chloride'
                                                                    value={guideline.ingredients_chioride}
                                                                    onChange={(e) => setGuideline({ ...guideline, ingredients_chioride: e.target.value })} />

                                                            </div> :
                                                            <div>
                                                                <input type='checkbox' name='Ingredientstype' value={item}
                                                                    onClick={(e) => handle_guidelinechecked(e)}
                                                                /> {item}
                                                            </div>
                                                }</div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className='row-page' style={{ width: "100%", marginBottom: 5 }}>
                                    <div className='row-page'>
                                        <div style={{ width: 150 }}>
                                            <b>5.Seamer condition :</b>
                                        </div>
                                        <div className='row-page' style={{ width: 1000 }}>
                                            {dataDocument.guideline.seamer.data.map((item, i) => (
                                                <div style={{ width: 200 }}>
                                                    <input type='checkbox' name='seamercondition' value={item}
                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                    /> {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                {typedocument == 'A1' &&
                                    <div className='row-between-form' style={{ width: '100%', marginBottom: 5 }}>
                                        <div className='row-page'>
                                            <div style={{ width: 150 }}>
                                                <b>6.Sterilization process :</b>
                                            </div>
                                            <div className='row-between-form' style={{ width: 1000 }}>
                                                {dataDocument.guideline.sterilization.data.map((item, i) => (
                                                    <div style={{ width: "100%" }}>
                                                        {item == 'Retort Temp/Time' ? <div>
                                                            <input type='checkbox' name='sterilization' value={item}
                                                                onClick={(e) => handle_guidelinechecked(e)}
                                                            /> {item} <input type='retorttemp' name='retorttemp' value={guideline.sterilization_retort}
                                                                onChange={(e) => setGuideline({ ...guideline, sterilization_retort: e.target.value })} />

                                                        </div> :
                                                            <div>
                                                                <input type='checkbox' name='sterilization' value={item}
                                                                    onClick={(e) => setGuideline({ ...guideline, sterilization: e.target.value })}

                                                                /> {item}</div> &&
                                                                item == 'Cooker Temp/time' ? <div>
                                                                <input type='checkbox' name='sterilization' value={item}
                                                                    onClick={(e) => handle_guidelinechecked(e)}
                                                                /> {item} <input type='text' name='cooker_temp'
                                                                    onChange={(e) => setGuideline({ ...guideline, sterilization_cooker: e.target.value })} />

                                                            </div> :
                                                                <div>
                                                                    <input type='checkbox' name='sterilization'
                                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                                    /> {item}</div> &&
                                                                    item == 'Other' ? <div>
                                                                    <input type='checkbox' name='sterilization' value={item}
                                                                        onClick={(e) => handle_guidelinechecked(e)}
                                                                    /> {item}
                                                                    <input type='text' name='sterilization' placeholder='input detail'
                                                                        // onClick={(e) => handle_guidelinechecked(e)}
                                                                        onChange={(e) => setGuideline({ ...guideline, sterilizationother: e.target.value })}
                                                                    />
                                                                </div> :
                                                                    <div>
                                                                        <input type='checkbox' name='sterilization' value={item}
                                                                            onClick={(e) => handle_guidelinechecked(e)}
                                                                        /> {item} </div>
                                                        }
                                                    </div>
                                                ))}
                                            </div>

                                        </div>

                                    </div>
                                }

                                <div>{ProductgorupError == true ? <label style={{ color: 'red' }}>มีรายการเลือกเกิน 1 รายการ</label> : ""}</div>
                            </div>
                        </Tab>

                        <Tab eventKey="Regulation" title="Regulation" style={{ backgroundColor: 'aliceblue' }}>
                            <div style={{ marginLeft: 10, width: 1000 }} >

                                <div style={{ width: 150, fontSize: 16 }}>
                                    <b>Regulation :</b>
                                </div>

                                <div className='row-between-form'>
                                    {dataDocument.regulation.data.map((item, i) => (
                                        <div>
                                            <div><input type='checkbox' name={`Regulationtype`} value={item} onClick={(e) => handleChange(e)} /> {item}</div></div>
                                    ))}
                                    <div>
                                        <input type='checkbox' name={`Regulationtype`} value={"Other"} onChange={(e) => handleChange(e)} /> <label>Other</label>
                                        <input type='text' name='regulationother' onChange={(e) => setregulationOther(e.target.value)} />
                                    </div>
                                </div>
                                <div>

                                    <hr></hr>

                                    <div>

                                        <label style={{ fontSize: 16 }}><b>Request Sample : </b></label>

                                        <div className='row-page'>
                                            <div style={{ width: 100 }}>
                                                <label>Proof plate:</label>
                                            </div>
                                            <input type='text' name='proofplate' value={requestsample.proofplate}
                                                onChange={(e) => setRequestsample({ ...requestsample, proofplate: e.target.value })} style={{ width: 200 }} />
                                        </div>
                                        <div className='row-page'>
                                            <div style={{ width: 100 }}>
                                                <label>Can :</label>
                                            </div>
                                            <input type='text' name='sample_can' value={requestsample.requestcan}
                                                onChange={(e) => setRequestsample({ ...requestsample, requestcan: e.target.value })} style={{ width: 200 }} />
                                        </div>
                                        <div className='row-page'>
                                            <div style={{ width: 100 }}>
                                                <label>End :</label>
                                            </div>
                                            <input type='text' name='proofplate' value={requestsample.requestend}
                                                onChange={(e) => setRequestsample({ ...requestsample, requestend: e.target.value })} style={{ width: 200 }} />
                                        </div>
                                    </div>

                                    <div style={{ marginTop: 10 }}>
                                        <div><b>Note Detail :  </b>
                                        </div>
                                        <textarea name='regulationnote'
                                            maxlength="350"
                                            value={requestsample.requestnote}
                                            onChange={(e) => setRequestsample({ ...requestsample, requestnote: e.target.value })}
                                            style={{ width: 500, height: 100 }} />
                                    </div>
                                </div>

                            </div>
                        </Tab>

                        <Tab eventKey="Componentcode" title="Component code" style={{ backgroundColor: 'aliceblue' }}>
                            <div style={{ marginLeft: 10, width: 1000 }} >
                                <AddComponentCode journalId={ids.id} />
                            </div>
                        </Tab>




                        <Tab eventKey="jouranl" title="Form ( F-MK-009A1-1 ) ตรวจสอบข้อมูลก่อนบันทึก" style={{ backgroundColor: 'aliceblue' }}>
                            <div>
                                {datafetch.length > 0 && <div>
                                    <div><center><b>TECHNICAL DATA SHEET : Food can Product</b></center></div>
                                    <div>

                                        <table style={{ width: '100%' }} >
                                            <tr>
                                                <th><center>Create product</center></th>
                                                <th><center>Due Date</center></th>
                                                <th><center>MK NO</center></th>
                                                <th><center>Date Cretae</center></th>
                                            </tr>
                                            <tr>
                                                <th style={{ paddingLeft: 5 }}>{datafetch[0].journaltype}</th>
                                                <th style={{ paddingLeft: 5 }}>{datafetch[0].duedate}</th>
                                                <th style={{ paddingLeft: 5 }}>{datafetch[0].tdscode}</th>
                                                <th style={{ paddingLeft: 5 }}>{datafetch[0].createdate}</th>
                                            </tr>

                                        </table>
                                    </div>

                                    <div style={{ width: '100%' }} className='row-between-form'>
                                        <div style={{ width: '50%' }} className='border_green'>
                                            <table style={{ width: '100%' }} >
                                                <tr>
                                                    <td style={{ width: '30%' }}>Product name : </td>
                                                    <td>{datafetch[0].productname}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>Can Size /Dia : </td>
                                                    <td> {datafetch[0].cansize}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>Shape : </td>
                                                    <td>{datafetch[0].shape}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>Item name : </td>
                                                    <td>{datafetch[0].itemname}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>Item NO : </td>
                                                    <td>{datafetch[0].itemcode}</td>
                                                </tr>

                                            </table>
                                        </div>
                                        <div style={{ width: '50%', marginLeft: 3 }} className='border_green'>
                                            <table style={{ width: '100%' }} >
                                                <tr>
                                                    <td style={{ width: '30%' }}>Customer name : </td>
                                                    <td>{datafetch[0].customername}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>Contact : </td>
                                                    <td>{datafetch[0].contact} </td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: '30%' }}>Tel : </td>
                                                    <td>{datafetch[0].tel} </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div>
                                        <table style={{ width: '100%' }}>
                                            <tr>
                                                <th>Substrate</th>
                                                <th>Product group</th>
                                                <th>Information from customer</th>
                                                <th>Printing system</th>
                                            </tr>
                                            <tr>
                                                <td><input type='checkbox' checked={true} disabled /> {datafetch[0].substratetype}</td>
                                                <td><input type='checkbox' checked={true} disabled /> {datafetch[0].productgrouptype}</td>
                                                <td><input type='checkbox' checked={true} disabled /> {datafetch[0].informationtype == 'false' ? '! ยังไม่ได้ระบบ ข้อมูล' : datafetch[0].informationtype}</td>
                                                <td >
                                                    <input type='checkbox' checked={true} disabled /> {datafetch[0].printingtype == 'false' ? '! ยังไม่ได้ระบบ ข้อมูล  ( printing system ) ' : datafetch[0].printingtype}
                                                    {" "}<input type='text' value={datafetch[0].printingtypeother} disabled />
                                                    <tr >
                                                        <td style={{ width: '30%' }}><b>Last film Code:</b></td>
                                                        <td style={{ width: '100%' }}>{datafetch[0].filmcode}</td>
                                                    </tr>
                                                    <tr >
                                                        <td style={{ width: '30%' }}><b>Ref FG Last film Code :</b></td>
                                                        <td style={{ width: '100%' }}>{datafetch[0].refFgcode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {datafetch[0].filmtype == 'Scrap' ? <div><input type='checkbox' checked={true} disabled /> Scrap</div> : <div><input type='checkbox' checked={false} disabled /> Scrap</div>}
                                                        </td>
                                                        <td>
                                                            {datafetch[0].filmtype == 'Maintain' ? <div><input type='checkbox' checked={true} disabled /> Maintain</div> : <div><input type='checkbox' checked={false} disabled /> Maintain</div>}
                                                        </td>
                                                    </tr>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>}
                                <hr></hr>
                                <div>

                                    <div><b>Coating system</b></div>
                                    <div className='row-page' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}>
                                            <b>Body :</b>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form'>
                                                    <th><center>Product shape</center></th>
                                                    <th><center>Side</center></th>
                                                    <th><center>Coating system</center></th>
                                                    <th><center>White Coat</center></th>
                                                    <th><center>Other</center></th>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingLeft: 5, width: '15%' }}>

                                                        {coatingbody.groupBobyType.map((item, i) => (
                                                            <div style={{ marginLeft: 20 }}><label>{i + 1}. {item}</label></div>
                                                        ))}
                                                    </td>
                                                    <td style={{ width: '10%' }}><center><b>Int .</b></center></td>
                                                    <td style={{ paddingLeft: 5, width: '20%' }}>{coatingbody.intcoating}</td>
                                                    <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.intwhite}</td>
                                                    <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.intother}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingLeft: 5, width: '15%' }}></td>
                                                    <td style={{ width: '10%' }}><center><b>Ext .</b></center></td>
                                                    <td style={{ paddingLeft: 5, width: '20%' }}>{coatingbody.extcoating}</td>
                                                    <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.extwhite}</td>
                                                    <td style={{ paddingLeft: 5, width: '30%' }}>{coatingbody.extother}</td>
                                                </tr>
                                            </table>
                                            <div>
                                                <b>FG component : </b>
                                                {coatingbody.fgcomponent}
                                            </div>
                                            <div><b> Note : </b>{coatingbody.note}</div>
                                        </div>
                                    </div>

                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}>
                                            <b>Top end :</b>
                                            <div># : <b>{coatingtop.number}</b></div>
                                        </div>
                                        <div style={{ width: '100%' }}>
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Product Substrate</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Product shape</center></th>
                                                    <th style={{ width: '10%' }}><center>Side</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                    <th style={{ paddingLeft: 5, width: '30%' }}><center>White Coat</center></th>
                                                    <th style={{ paddingLeft: 5, width: '30%' }}><center>Other</center></th>
                                                </tr>
                                                <tr>
                                                    <td ><center>{coatingtop.substrate}</center></td>
                                                    <td ><center>{coatingtop.shape}</center></td>
                                                    <td ><center><b>Int .</b></center></td>
                                                    <td >{coatingtop.intcoating}</td>
                                                    <td >{coatingtop.intwhite}</td>
                                                    <td >{coatingtop.intother}</td>
                                                </tr>
                                                <tr>
                                                    <td ></td>
                                                    <td ></td>
                                                    <td ><center><b>Ext .</b></center></td>
                                                    <td >{coatingtop.extcoating}</td>
                                                    <td >{coatingtop.extwhite}</td>
                                                    <td >{coatingtop.extother}</td>
                                                </tr>
                                            </table>
                                            <div><b>For sale order:</b> {coatingtop.forsaleorder}</div>
                                            <div>
                                                <b>FG component : </b>
                                                {coatingtop.fgcomponent}
                                            </div>
                                            <div><b>Note : </b>{coatingtop.note}</div>
                                        </div>
                                    </div>
                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}>
                                            <b>Bottom end :</b>
                                            <div># : <b>{coatingbottom.number}</b></div>
                                        </div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Product Substrate</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Product shape</center></th>
                                                    <th style={{ width: '10%' }}><center>Side</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                    <th style={{ paddingLeft: 5, width: '30%' }}><center>White Coat</center></th>
                                                    <th style={{ paddingLeft: 5, width: '30%' }}><center>Other</center></th>
                                                </tr>
                                                <tr>
                                                    <td ><center>{coatingbottom.substrate}</center></td>
                                                    <td ><center>{coatingbottom.shape}</center></td>
                                                    <td ><center><b>Int .</b></center></td>
                                                    <td >{coatingbottom.intcoating}</td>
                                                    <td >{coatingbottom.intwhite}</td>
                                                    <td >{coatingbottom.intother}</td>
                                                </tr>
                                                <tr>
                                                    <td ></td>
                                                    <td ></td>
                                                    <td ><center><b>Ext .</b></center></td>
                                                    <td >{coatingbottom.extcoating}</td>
                                                    <td >{coatingbottom.extwhite}</td>
                                                    <td >{coatingbottom.extother}</td>
                                                </tr>
                                            </table>
                                            <div><b>For sale order: </b> {coatingbottom.forsaleorder}</div>
                                            <div>
                                                <b>FG component : </b>
                                                {coatingbottom.fgcomponent}
                                            </div>
                                            <div><b>Note : </b> {coatingbottom.note}</div>
                                        </div>
                                    </div>

                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}>
                                            <b>Ring :</b>
                                        </div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Dia</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Ring Type</center></th>
                                                    <th style={{ paddingLeft: 5, width: '20%' }}><center>Code</center></th>
                                                    <th style={{ width: '10%' }}><center>Side</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                    <th style={{ paddingLeft: 5, width: '20%' }}><center>White Coat</center></th>
                                                    <th style={{ paddingLeft: 5, width: '20%' }}><center>Other</center></th>
                                                </tr>
                                                <tr>
                                                    <td ><center>{coatingring.dia}</center></td>
                                                    <td ><center>{coatingring.ring_eartype}</center></td>
                                                    <td><center>{coatingring.ring_earcode}</center></td>
                                                    <td ><center><b>Int .</b></center></td>
                                                    <td >{coatingring.intcoating}</td>
                                                    <td >{coatingring.intwhite}</td>
                                                    <td >{coatingring.intother}</td>
                                                </tr>
                                                <tr>
                                                    <td ></td>
                                                    <td ></td>
                                                    <td></td>
                                                    <td ><center><b>Ext .</b></center></td>
                                                    <td >{coatingring.extcoating}</td>
                                                    <td >{coatingring.extwhite}</td>
                                                    <td >{coatingring.extother}</td>
                                                </tr>
                                            </table>
                                            <div><b>For sale order : </b> {coatingring.forsaleorder}</div>
                                        </div>
                                    </div>

                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}>
                                            <b>Ear :</b>
                                        </div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Code</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Ring Type</center></th>
                                                    <th style={{ width: '10%' }}><center>Side</center></th>
                                                    <th style={{ paddingLeft: 5, width: '10%' }}><center>Coating system</center></th>
                                                    <th style={{ paddingLeft: 5, width: '20%' }}><center>White Coat</center></th>
                                                    <th style={{ paddingLeft: 5, width: '20%' }}><center>Other</center></th>
                                                </tr>
                                                <tr>
                                                    <td ><center>{coatingear.ring_earcode}</center></td>
                                                    <td ><center>{coatingear.ring_eartype}</center></td>
                                                    <td ><center><b>Int .</b></center></td>
                                                    <td >{coatingear.intcoating}</td>
                                                    <td >{coatingear.intwhite}</td>
                                                    <td >{coatingear.intother}</td>
                                                </tr>

                                            </table>

                                        </div>
                                    </div>

                                    <hr></hr>
                                </div>

                                <div>
                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}><b>Can & end forming</b></div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ width: '10%' }}><center>Internal side stripe</center></th>
                                                    <th style={{ width: '15%' }}><center>Internal Other</center></th>
                                                    <th style={{ width: '10%' }}><center>Seaming </center></th>
                                                    <th style={{ width: '15%' }}><center>Seaming Other</center></th>
                                                    <th style={{ width: '20%' }}><center>Machine no </center></th>
                                                    <th style={{ width: '20%' }}><center>Packaging </center></th>


                                                </tr>
                                                <tr>
                                                    <td ><center>{forming.internalside}</center></td>
                                                    <td ><center>{forming.internalother}</center></td>
                                                    <td ><center>{forming.seaming}</center></td>
                                                    <td >{forming.seamingother}</td>
                                                    <td >{forming.machineno}</td>
                                                    <td >{forming.packaging}</td>

                                                </tr>
                                            </table>
                                            <div><b>Note : </b> {forming.note}</div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>

                                <div>
                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}><b>Guideline for product pack</b></div>
                                        <div style={{ width: '100%' }} >
                                            <div className='row-form'>
                                                <div style={{ marginRight: 20 }}>
                                                    <b>product shelf-life</b>  : <label style={{ color: 'red' }}>( {guideline.shelfyears} ) years / at</label></div>
                                                <div> <label style={{ color: 'blue' }}>( {guideline.shelfdeg} ) degC</label></div>
                                            </div>
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ width: '10%' }}><center># No .</center></th>
                                                    <th style={{ width: '15%' }}><center>Detail</center></th>
                                                    <th style={{ width: '10%' }}><center>Other </center></th>



                                                </tr>
                                                <tr>
                                                    <td ><b>1. Product group</b></td>
                                                    <td >{guideline.groupother}</td>
                                                    <td >{guideline.groupother}</td>

                                                </tr>
                                                <tr>
                                                    <td ><b>2. Details of product </b></td>
                                                    <td >{guideline.detailproduct}</td>
                                                    <td ></td>

                                                </tr>
                                                <tr>
                                                    <td ><b>3. pH</b></td>
                                                    <td >{guideline.ph}</td>
                                                    <td ></td>

                                                </tr>
                                                <tr>
                                                    <td ><b>4. Ingredients </b></td>
                                                    <td >{guideline.ingredients}</td>
                                                    <td >{guideline.ingredientother}</td>

                                                </tr>
                                                <tr>
                                                    <td ><b>5.Seamer condition </b></td>
                                                    <td >{guideline.seamercondition}</td>
                                                    <td ></td>

                                                </tr>
                                                <tr>
                                                    <td ><b>6.Sterilization process</b></td>
                                                    <td >{guideline.sterilization}</td>
                                                    <td >{guideline.sterilizationother}</td>

                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>

                                <div>
                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}><b>Regulation</b></div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ width: '10%' }}><center>Regulation type</center></th>
                                                    <th style={{ width: '15%' }}><center>Detail</center></th>



                                                </tr>
                                                <tr>
                                                    <td >{regulationcheck.map((item, i) => (
                                                        <div>{i + 1} . {item}</div>
                                                    ))}</td>
                                                    <td >{regulationOter}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>

                                <div style={{ marginBottom: 40 }}>
                                    <div className='row-between-form' style={{ width: '100%' }}>
                                        <div style={{ width: '10%' }}><b>Request sample</b></div>
                                        <div style={{ width: '100%' }} >
                                            <table style={{ width: '100%' }} >
                                                <tr className='borderWidth-form' style={{ backgroundColor: '' }}>
                                                    <th style={{ width: '10%' }}><center>Proof plate</center></th>
                                                    <th style={{ width: '15%' }}><center> Can (pcs)</center></th>
                                                    <th style={{ width: '15%' }}><center>  End (pcs)</center></th>

                                                </tr>
                                                <tr>
                                                    <td >{requestsample.proofplate}</td>
                                                    <td >{requestsample.requestcan}</td>
                                                    <td >{requestsample.requestend}</td>
                                                </tr>
                                            </table>
                                            <div style={{ margin: 10, padding: 10, backgroundColor: '#e9e9e9' }} > <label style={{ fontSize: 16 }}>
                                                <b> Note : </b></label>{requestsample.requestnote}
                                            </div>
                                        </div>
                                    </div>
                                    <hr></hr>
                                </div>

                                <div className='row-page'>
                                    <div className='border_green' style={{ width: '30%', height: 'auto', padding: 10 }}>
                                        <div style={{ backgroundColor: '#989898' }}>
                                            <center>Component Code </center>
                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>หูอ๊อก/ถ้วยอ๊อก :</b></div>
                                            <div>{compoData.earwelding}</div>

                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>หู :</b></div>
                                            <div>{compoData.ear}</div>

                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>ห่วง :</b></div>
                                            <div>{compoData.ring}</div>

                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>ฝาใน :</b></div>
                                            <div>{compoData.innerlid}</div>
                                        </div>
                                        {/* <div className='row-between-page '>
                                            <div><b>ฝานอก :</b></div>
                                            <div>{compoData.outerlid}</div>
                                        </div> */}
                                        <div className='row-between-page '>
                                            <div><b>ฝาบน :</b></div>
                                            <div>{compoData.toplid}</div>
                                        </div>
                                        {/* <div className='row-between-page '>
                                            <div><b>ฝาล่าง :</b></div>
                                            <div>{compoData.bottomlid}</div>
                                        </div> */}
                                        <div className='row-between-page '>
                                            <div><b>ฝาพลาสติก :</b></div>
                                            <div>{compoData.plasticlid}</div>
                                        </div>
                                        <div className='row-between-page '>
                                            <div><b>ฝาอลูมิเนียม :</b></div>
                                            <div>{compoData.aluminumlid}</div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='row-page' style={{ marginLeft: 20 }}>
                                            <label><b>ตัวอย่าง Product </b></label> <ViewImages urlImages={datafetch[0].uploadfilename} />
                                        </div>

                                        <div style={{ margin: 10, padding: 5 }} className='row-page'>

                                            {datafetch[0].uploadfilename != "" ? <img src={host + '/images/files/' + datafetch[0].uploadfilename} style={{ width: 100, hidden: 100 }} /> : "ไม่มีข้อมูล รูป ประกอบ"}


                                        </div>

                                    </div>

                                </div>

                            </div>
                        </Tab>
                    </Tabs>

                </form >}

        </div >

    )
}



const Newjournals = (id) => {


    const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(value) {
        // setFullscreen(breakpoint);
        setShow(value);
    }

    return (
        <div>
            <Button size="sm" style={{ fontSize: 10 }} onClick={() => handleShow(true)} >เพิ่ม รายละเอียด</Button>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>เพิ่ม TECHBICAL DATA SHEET PART A </Modal.Title>
                </Modal.Header>
                <Modal.Body><FormNew handleShow={handleShow} ids={id} /></Modal.Body>
            </Modal>
        </div>
    )
}

export default Newjournals