import React, { useState, useEffect, useRef } from 'react'
import Showdetailjournal from '../../../form01/components/showdetailjournal';
import FetchApi from '../../../customhooks/functionFetchApi';
import generatePDF from 'react-to-pdf';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import Reportv2 from '../reportpdf/reportv2';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'

function PreViewPrinting({id}) {
    return (
        <div style={{ height: 750 }}>
            <PDFViewer width="100%" height="100%" >
                <Document>
                <Reportv2 jourId={id}/>
                </Document>
            </PDFViewer>
        </div>
    )
}


const PdfpartA = ({ idjour }) => {
    const targetRef = useRef();
    const FetchApis = new FetchApi();
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);


    function getjournalbyid() {
        FetchApis.FethcGet(`/journal/journalbyId/${idjour}`).then(res => {
            if (res) {
                let tdscodeid = res.data.data[0].tdscode;
                generatePDF(targetRef, { filename: `${tdscodeid}_partA_page.pdf` })
            }

        })
    }

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }


    return (
        <div>
            <Button onClick={() => handleShow()} size='sm' variant="light"><FaFileDownload />Preview Printing</Button>
            <div ref={targetRef} style={{ padding: 20 }}>
                <Showdetailjournal id={idjour} />
                <img src={require('../../../images/draft-icon-27.jpg')} style={{ width: 50, height: 'auto', borderRadius: 3 }} />
            </div>

            <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview part A</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <PDFViewer width="100%" height="100%"> */}
                    <PreViewPrinting id={idjour} />
                    {/* </PDFViewer> */}


                </Modal.Body>
            </Modal>

        </div>
    )
}

export default PdfpartA