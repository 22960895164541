import React, { useState, useEffect } from 'react'

import FetchApi from '../../../customhooks/functionFetchApi';
import { Routes, Route, useParams } from 'react-router-dom';
import { statusflowall } from '../../object-default/journalobject';
import Journalrejectapprove from './journalrejectapprove';
import Journalrecalltapprove from './journalrecalltapprove';

import Spinner from 'react-bootstrap/Spinner';


const datasub = {
  spectype: "", sheetsize: "", flatscroll: "", straightscroll: "", tin: "",
  temper: "", bright: "", row: "", filmcode: "", itemno: "", journalId: "", tdscode: "",
}

function FormEditJournal(props) {
  const id = props.journalId;


  const FetchApis = new FetchApi();

  const emCode = sessionStorage.getItem('EmCode');

  const [key, setKey] = useState('jouranl');

  const [datafetch, setDatafetch] = useState([]);


  // data update crate task
  const [newTask, setNewTask] = useState([]);

  //data update journal
  const [newjournalUpdate, setNewjournalUpdate] = useState([]);
  const [laodingdata, setLaodingdata] = useState(true);

  useEffect(() => {
    setLaodingdata(true);

    var intdataconvert = 0;
    FetchApis.FethcGet(`/journal/journalbyId/${id}`).then((datafetch) => {
      if (datafetch.data.status == 200) {
        setDatafetch(datafetch.data.data)
        let codeid = datafetch.data.data[0].tdscode;
        // setIdcode(codeid)
        intdataconvert = datafetch.data.data[0].stateflow - 1;

        var dataobject = {
          journalid: id,
          emcode: emCode,
          stateflow: intdataconvert,
          approvecode: statusflowall.sendjournal,
          dedicatecode: ""
        }

        setNewTask(dataobject)

        var updatejournal = {
          statusflow: statusflowall.sendjournal,
          stateflow: intdataconvert
        }

        setNewjournalUpdate(updatejournal);
        setTimeout(() => {
          setLaodingdata(false)
        }, 800);

      }
    })

  }, [])


  return (
    <div>
      {laodingdata ? <div><Spinner
        as="span"
        animation="grow"
        variant="success"
        size={10}
        role="status"
        aria-hidden="true" /> LOADIN ....
      </div> :
        <div >
            <div className='end-flex-row'>
              <Journalrejectapprove newTask={newTask} newjournalUpdate={newjournalUpdate} />
              <Journalrecalltapprove newTask={newTask} newjournalUpdate={newjournalUpdate} />
            </div>
            <div>
            
            </div>
        
        </div>}

    </div>
  )
}


const Actionapprove = (props) => {

  return (
    <div>
      <FormEditJournal journalId={props.journalId} />
    </div>
  )
}

export default Actionapprove