import React, { useEffect, useState } from 'react';
import FetchApi from '../customhooks/functionFetchApi';
import Tablelistallprovebyuser from './components/componentallistallpprovebyuser/tablelistallprovebyuser';
import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';
import { FaList, FaFileSignature, FaArchive, FaExclamationTriangle, FaCheck } from "react-icons/fa";
const Journallistallpprovebyuser = () => {

  const FetchApis = new FetchApi();
  const emcode = sessionStorage.getItem("EmCode")

  const [datalist, setDatalist] = useState([]);
  const [loadingdata, setLoadingdata] = useState(true);
  const [searhcText, setSearchText] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    laodingList();
  }, [])


  function laodingList() {
    setLoadingdata(true)
    FetchApis.FethcGet(`/runflow/getJournalflowlist/${emcode}`).then((res) => {

      if (res.status == 200) {

        setDatalist(res.data.data);
        setSearchText(res.data.data);
        
        console.log(res.data.data);

        setTimeout(() => {
          setLoadingdata(false)
        }, 1300);

      }
    })
  }


  //ค้นหาข้อมูลทั้งหมด
  function heandleSearch(e) {
    const { value, name } = e.target;

    // setSearchInput(searchValue)
    const filteredData = datalist.filter((item) => {
      return Object.values(item).join('').toLowerCase().includes(value.toLowerCase())
    })

    setSearchText(filteredData)
  }



  function filterSuccesfull(e) {
    setLoading(true)
    const filterData = datalist.filter((item) => item.flowSuccesfull == '1');
    setSearchText(filterData)

    setTimeout(() => {
        setLoading(false)
    }, 1000);
}

function filterNotSuccesfull(e) {
    setLoading(true)
    const filterData = datalist.filter((item) => item.flowSuccesfull == null);

    setSearchText(filterData)

    setTimeout(() => {
        setLoading(false)
    }, 1000);

}

function allListData() {
    setLoading(true)
    setSearchText(datalist)

    setTimeout(() => {
        setLoading(false)
    }, 1000);
}

const sumapprove = datalist.filter((item) => item.flowSuccesfull == '1');
const sumpreapprove = datalist.filter((item) => item.flowSuccesfull == null);


  return (
    <div>
      <div className='row-page'>
        <div>
          <label style={{ fontSize: 20, margin: 5, padding: 5 }}><FaFileSignature />เอกสารที่อนุมัติแล้ว</label>
          <label style={{ fontSize: 20, margin: 5, padding: 5 }}>( {datalist.length} ) รายการ</label>
          <div className='row-page'>
                        <div><label style={{ fontSize: 14, color: 'blue', margin: 5 }}><Button onClick={() => allListData()}><FaList /> เอกสารทั้งหมด ( {datalist.length} )</Button></label></div>
                        <div><label style={{ fontSize: 14, color: 'green', margin: 5 }}> <Button variant="success" onClick={() => filterSuccesfull()}><FaCheck /> อนุมัติแล้ว ( {sumapprove != null && sumapprove.length} )</Button></label></div>
                        <div><label style={{ fontSize: 14, color: 'orange', margin: 5 }}><Button variant="warning" onClick={() => filterNotSuccesfull()}><FaArchive /> รออนุมัติ ( {sumpreapprove != null && sumpreapprove.length} )</Button></label></div>
                    </div>
        </div>

        <div style={{ fontSize: 20, margin: 5, padding: 5 }}>
          <input type='text' name='TextSearch' placeholder='ค้นหาข้อมูล' onChange={(e) => heandleSearch(e)} />
        </div>
      </div>
      {loadingdata ? <div className='body-center-page'><Spinner
        as="span"
        animation="grow"
        variant="success"
        size={200}
        role="status"
        aria-hidden="true" /> LOADIN ....</div> : <div><Tablelistallprovebyuser datatable={searhcText} /></div>}
    </div>
  )

}

export default Journallistallpprovebyuser