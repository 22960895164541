import React, { useState, useEffect } from 'react'
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'
import { Table, TD, TH, TR } from "@ag-media/react-pdf-table";
import FetchApi from '../../../customhooks/functionFetchApi';
import { styles } from "../../../css/style";

const Reportv2 = (props) => {
  const FetchApis = new FetchApi()

  const [JournalHead, setJournalHead] = useState([])
  // coating all
  const [Coatingbody, setCoatingbody] = useState([])
  const [Coatingtop, setCoatingtop] = useState([])
  const [Coatingbottom, setCoatingbottom] = useState([])
  const [Coatingring, setCoatingring] = useState([])
  const [Coatingear, setCoatingear] = useState([])

  const [Formingall, setFormingall] = useState([])
  const [Guidelineall, setGuidelineaall] = useState([])
  const [Regulationall, setRegulationall] = useState([])
  const [Requestsampleall, setRequestsampleall] = useState([])
  const [Componentall, setComponentall] = useState([])
  // spectplanning

  const [Specbody, setSpecbody,] = useState([])
  const [Spectop, setSpectop] = useState([])
  const [Specbottom, setSpecbottom] = useState([])
  const [Spectab, setSpectab] = useState([])
  const [loading,setLoading]=useState(true)

  // const [journalHead,setJournalHead]=useState([])

  useEffect(() => {
    setLoading(true)
    FetchApis.FethcGet(`/reportjournal/journalPartA/${props.jourId}`).then(res => {
      if (res) {

        setJournalHead(res.data.data.journaldata[0])
        setFormingall(res.data.data.formingdata[0])

        setGuidelineaall(res.data.data.guidelinedata[0])
        setRegulationall(res.data.data.regulationdata[0])
        setRequestsampleall(res.data.data.requestsample[0])
        setComponentall(res.data.data.componentdata[0])

        res.data.data.coatingdata[0].forEach(element => {
          // console.log(element)
          if (element.producttype == 'body') {
            setCoatingbody(element)
          }

          if (element.producttype == 'top') {
            setCoatingtop(element)
          }

          if (element.producttype == 'bottom') {
            setCoatingbottom(element)
          }
          if (element.producttype == 'ring') {
            setCoatingring(element)
          }

          if (element.producttype == 'ear') {
            setCoatingear(element)
          }
        });

        // specplanning key
        res.data.data.specplanningdata[0].forEach(element => {

          if (element.spectype == 'body') {
            setSpecbody(element);

          }
          if (element.spectype == 'top') {
            setSpectop(element)

          }
          if (element.spectype == 'bottom') {
            setSpecbottom(element)

          }
          if (element.spectype == 'tab') {
            setSpectab(element)
          }

        });

        setTimeout(() => {
          setLoading(false)
        }, 1200);
      
      }

    })
  }, [])
  return (<>
    {loading==false &&
      <Page size="A4" style={styles.page}>
        <View style={{
          with: '100%', alignContent: 'center', backgroundColor: '#f2f1f1',
          flexDirection: 'row', justifyContent: 'center', display: 'flex'
        }}>
          <View style={[styles.textBoldfonts14, { alignItems: 'center', flexDirection: 'row' }]}>
            <Text>( A ) TECHNICAL DATA : </Text>
            <Text>{JournalHead[0].typeproduct == 'A1' ? "Food can Product" : "Dry foods ,Arosol can and General can Product"}</Text>
          </View>

        </View>
        <Table style={[styles.textBoldfonts10, { width: '100%', marginBottom: 0, border: '0.5' }]}>
          <TH >
            <TD weighting={0.4}>
              <Text style={{ marginLeft: 2 }}>Create prodcut : {JournalHead[0].journaltypename}</Text>
            </TD>
            <TD style={{ width: '20%' }} weighting={0.2}>
              <Text style={{ marginLeft: 2 }}>Due Date : {JournalHead[0].duedate}</Text>
            </TD>
            <TD style={{ width: '20%' }} weighting={0.2}>
              <Text style={{ marginLeft: 2 }}>MK NO : {JournalHead[0].tdscode}</Text>
            </TD>
            <TD style={{ width: '20%' }} weighting={0.2}>
              <Text style={{ marginLeft: 2 }}>Date Create : {JournalHead[0].createdatetime}</Text>
            </TD>
          </TH>
        </Table>

        <Table style={[styles.textBoldfonts10, { width: '100%', marginBottom: 1, border: "0.5" }]}>
          <TR >
            <TD weighting={0.1}  >
              <Text style={{ marginLeft: 2, fontWeight: 'bold' }}>Product Name</Text>
            </TD>
            <TD >
              <Text style={{ marginLeft: 2 }}>{JournalHead[0].productname}</Text>
            </TD>
            <TD weighting={0.1}>
              <Text style={{ marginLeft: 2, fontWeight: 'bold' }}>Customer Name</Text>
            </TD>
            <TD>
              <Text style={{ marginLeft: 2 }}>{JournalHead[0].customername}</Text>
            </TD>
          </TR>
          <TR>
            <TD weighting={0.1}>
              <Text style={{ marginLeft: 2, fontWeight: 'bold' }}>Can Size / Dia</Text>
            </TD>
            <TD >
              <TD style={{ border: '0.1' }}>  <Text style={{ marginLeft: 2 }}>{JournalHead[0].cansize}</Text></TD>
              <TD style={{ border: '0.1' }}>  <Text style={{ marginLeft: 2 }}>Shape : {JournalHead[0].shape}</Text></TD>

            </TD>
            <TD weighting={0.1}>
              <Text style={{ marginLeft: 2, fontWeight: 'bold' }}>Contact</Text>
            </TD>
            <TD >
              <TD style={{ border: '0.1' }}>
                <Text style={{ marginLeft: 2 }}>{JournalHead[0].contact}</Text>
              </TD>
              <TD style={{ border: '0.1' }}>
                <Text style={{ marginLeft: 2 }}>Tel : {JournalHead[0].tel}</Text>
              </TD>

            </TD>
          </TR>
          <TR >
            <TD weighting={0.1}>
              <Text style={{ marginLeft: 2, fontWeight: 'bold' }}>Item Name</Text>
            </TD>
            <TD >
              <Text style={{ marginLeft: 2 }}>{JournalHead[0].itemname}</Text>
            </TD>
            <TD weighting={0.1}>
              <Text style={{ marginLeft: 2, fontWeight: 'bold' }}>Item No</Text>
            </TD>
            <TD>
              <Text style={{ marginLeft: 2 }}> {JournalHead[0].itemcode} </Text>
            </TD>
          </TR>

        </Table>

        <Table style={[styles.textBoldfonts10, { width: '100%', marginBottom: 1, border: "0.5" }]}
          weightings={[0.1, 0.1, 0.2, 0.6]}>
          <TH>
            <TD style={{ justifyContent: 'center' }}>
              <Text>Substrate</Text></TD>
            <TD style={{ justifyContent: 'center' }}>
              <Text>Product group</Text></TD>
            <TD style={{ justifyContent: 'center' }}>
              <Text>
                Information from customer
              </Text>
            </TD>
            <TD style={{ justifyContent: 'center' }}>
              <Text>Printing System</Text>
            </TD>
          </TH>
          <TR >
            <TD style={{ justifyContent: 'center' }} >{JournalHead[0].substratetype}</TD>
            <TD style={{ justifyContent: 'center' }}>{JournalHead[0].productgrouptype}</TD>
            <TD style={{ flexDirection: 'column', justifyContent: 'center' }}>
              <Text>{JournalHead[0].informationtype == 'false' ? '-' : JournalHead[0].informationtype}</Text>
              <Text>{JournalHead[0].informationnote == "" ? "" : "note :" + JournalHead[0].informationnote}</Text>
            </TD>
            <TD style={{ flexDirection: 'column' }}>

              <Table style={{ width: '100%', border: "0.5" }}
                weightings={[0.2, 0.2, 0.2, 0.2, 0.2]}
              >
                <TH >
                  <TD style={{ justifyContent: 'center' }} >
                    <Text style={{ marginLeft: 2, fontWeight: 'bold' }}>Printing System</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} >
                    <Text style={{ marginLeft: 2 }}>Printing Machine</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} >
                    <Text style={{ marginLeft: 2, fontWeight: 'bold' }}>Last film Code</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} >
                    <Text style={{ marginLeft: 2 }}>Ref.item FG</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} >
                    <Text style={{ marginLeft: 2 }}>Scrap/Maintain</Text>
                  </TD>
                </TH>
                <TR>
                  <TD >
                    <Text style={{ marginLeft: 1 }}>{JournalHead[0].printingtype == 'false' ? '-' : JournalHead[0].printingtype}</Text>
                  </TD>
                  <TD>
                    <Text style={{ marginLeft: 1 }}>{JournalHead[0].printingmachine == 'false' ? '-' : JournalHead[0].printingmachine}</Text>
                  </TD>
                  <TD >
                    <Text style={{ marginLeft: 1 }}>{JournalHead[0].filmcode}</Text>
                  </TD>
                  <TD>
                    <Text style={{ marginLeft: 1 }}>{JournalHead[0].refFgcode}</Text>
                  </TD>
                  <TD>
                    <Text style={{ marginLeft: 1 }}>{JournalHead[0].filmtype}</Text>
                  </TD>
                </TR>

              </Table>

            </TD>
          </TR>
        </Table>
        <View style={[styles.textBoldfonts10, { flexDirection: 'row', width: '100%', backgroundColor: '#f2f1f1' }]}>
          <View style={{ width: '30%', flexDirection: 'row' }}>

            <Text style={{ fontWeight: 'bold', marginLeft: 2 }}> STATUS PROOF JOB : </Text>

            <Text style={{ marginLeft: 10, fontWeight: 'bold' }}>{JournalHead[0].statusprove_Tds != "1" ? " " : "( PROOF )"}</Text>

          </View>
          <View style={{ backgroundColor: '#e7e5e4', width: '100%', flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold', marginLeft: 2 }}>NOTE : </Text>
            <Text style={{ marginLeft: 2 }}>{JournalHead[0].noteapproveTds}</Text>
          </View>
        </View>


        {/* coating system ---------->*/}
        <View style={styles.textBoldfonts10}>
          <View>
            <Text style={{ fontWeight: 'bold' }}>Coating System</Text>
          </View>

          {/* body -----------> */}
          <View style={{ marginBottom: 1 }}>

            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '10%', backgroundColor: '#e7e5e4', justifyContent: 'center', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 'bold', alignItems: 'center' }}>BODY</Text>
              </View>

              <Table style={{ border: "0.5" }}>
                <TH >
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>Product shape</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Side</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Coatring System</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>White Coat</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.4}>
                    <Text>Other</Text>
                  </TD>
                </TH>

                <TR >
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>{Coatingbody.groupBobyType}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text style={{ fontWeight: 'bold' }}>Int</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingbody.intcoating}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>{Coatingbody.intwhite}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.4}>
                    <Text>{Coatingbody.intother}</Text>
                  </TD>
                </TR>

                <TR >
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text></Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text style={{ fontWeight: 'bold' }}>Ext</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingbody.extcoating}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>

                    {Coatingbody.exttypewhite != "" && <Text style={{ marginRight: 10 }}>{Coatingbody.exttypewhite} </Text>}
                    {Coatingbody.extwhite != "" && <Text>{Coatingbody.extwhite}</Text>}
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.4}>
                    <Text>{Coatingbody.extother}</Text>
                  </TD>
                </TR>
              </Table>
            </View>

            <View style={{ width: '100%', flexDirection: 'row' }}>
              <View style={{ width: '30%' }}>
                <Text style={{ fontWeight: 'bold' }}>FG component : {Coatingbody.fgcomponent}</Text>
              </View>
              <View style={{ width: '70%' }}>
                <Text style={{ fontWeight: 'bold' }}>Note : {Coatingbody.note}</Text>
              </View>
            </View>

          </View>


          {/*Top ------------>*/}
          <View style={{ marginBottom: 3 }}>

            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '10%', backgroundColor: '#e7e5e4', justifyContent: 'center', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 'bold', alignItems: 'center' }}>TOP END</Text>
              </View>
              <Table style={{ border: "0.5" }}>
                <TH>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Substrate</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Shape</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Side</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Coatring System</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>White Coat</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.4}>
                    <Text>Other</Text>
                  </TD>
                </TH>

                <TR>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingtop.substrate}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingtop.shape}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text style={{ fontWeight: 'bold' }}>Int</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingtop.intcoating}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>{Coatingtop.intwhite}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.4}>
                    <Text>{Coatingtop.intother}</Text>
                  </TD>
                </TR>

                <TR>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text></Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text></Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text style={{ fontWeight: 'bold' }}>Ext</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingtop.extcoating}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    {/* <Text>{Coatingtop.extwhite}</Text> */}
                    {Coatingtop.exttypewhite !== "" && <Text style={{ marginRight: 10 }}>{Coatingtop.exttypewhite} </Text>}
                    {Coatingtop.extwhite !== "" && <Text>{Coatingtop.extwhite}</Text>}
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.4}>
                    <Text>{Coatingtop.extother}</Text>
                  </TD>
                </TR>
              </Table>
            </View>

            <View style={{ width: '100%', flexDirection: 'row' }}>
              <View style={{ width: '30%' }}>
                <Text style={{ fontWeight: 'bold' }}>FG component : {Coatingtop.fgcomponent}</Text>
              </View>
              <View style={{ width: '30%' }}>
                <Text style={{ fontWeight: 'bold' }}>For sale order : {Coatingtop.forsaleorder}</Text>
              </View>
              <View style={{ width: '40%' }}>
                <Text style={{ fontWeight: 'bold' }}>Note : {Coatingtop.note}</Text>
              </View>
            </View>

          </View>

          {/* Botton End ------------>*/}
          <View style={{ marginBottom: 1 }}>

            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '10%', backgroundColor: '#e7e5e4', justifyContent: 'center', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 'bold', alignItems: 'center' }}>BOTTON END</Text>
              </View>
              <Table style={{ border: "0.5" }}>
                <TH>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Substrate</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Shape</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Side</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Coatring System</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>White Coat</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.4}>
                    <Text>Other</Text>
                  </TD>
                </TH>

                <TR>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingbottom.substrate}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingbottom.shape}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text style={{ fontWeight: 'bold' }}>Int</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingbottom.intcoating}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>{Coatingbottom.intwhite}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.4}>
                    <Text>{Coatingbottom.intother}</Text>
                  </TD>
                </TR>

                <TR>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    {/* <Text></Text> */}
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    {/* <Text>xxx</Text> */}
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text style={{ fontWeight: 'bold' }}>Ext</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingbottom.extcoating}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    {Coatingbottom.exttypewhite !== "" && <Text style={{ marginRight: 10 }}>{Coatingbottom.exttypewhite} </Text>}
                    {Coatingbottom.extwhite !== "" && <Text>{Coatingbottom.extwhite}</Text>}
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.4}>
                    <Text>{Coatingbottom.extother}</Text>
                  </TD>
                </TR>
              </Table>
            </View>

            <View style={{ width: '100%', flexDirection: 'row' }}>
              <View style={{ width: '30%' }}>
                <Text style={{ fontWeight: 'bold' }}>FG component : {Coatingbottom.fgcomponent}</Text>
              </View>
              <View style={{ width: '30%' }}>
                <Text style={{ fontWeight: 'bold' }}>For sale order : {Coatingbottom.forsaleorder}</Text>
              </View>
              <View style={{ width: '40%' }}>
                <Text style={{ fontWeight: 'bold' }}>Note : {Coatingbottom.note}</Text>
              </View>
            </View>

          </View>

          {/* Ring ------------>*/}
          <View style={{ marginBottom: 1 }}>

            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: '10%', backgroundColor: '#e7e5e4', justifyContent: 'center', flexDirection: 'row' }}>
                <Text style={{ fontWeight: 'bold', alignItems: 'center' }}>RING</Text>
              </View>
              <Table style={{ border: "0.5" }}>
                <TH>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Dia</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Ring Type</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Code</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Side</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>Coatring System</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>White Coat</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>Other</Text>
                  </TD>
                </TH>

                <TR>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingring.dia}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingring.ring_eartype}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>{Coatingring.ring_earcode}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Int</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>{Coatingring.intcoating}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>{Coatingring.intwhite}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>{Coatingring.intother}</Text>
                  </TD>
                </TR>

                <TR>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text></Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text></Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text></Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                    <Text>Ext</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>{Coatingring.extcoating}</Text>
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    {Coatingring.exttypewhite !== "" && <Text>{Coatingring.exttypewhite}</Text>}
                    {Coatingring.extwhite !== "" && <Text> {Coatingring.extwhite}</Text>}
                  </TD>
                  <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                    <Text>{Coatingring.extother}</Text>
                  </TD>
                </TR>
              </Table>
            </View>

            <View style={{ width: '100%', flexDirection: 'row' }}>
              <View style={{ width: '30%' }}>
                <Text style={{ fontWeight: 'bold' }}>FG component : {Coatingring.fgcomponent}</Text>
              </View>
            </View>

          </View>


          {/* Ear ------------>*/}
          <View style={{ flexDirection: 'row', marginBottom: 1 }}>
            <View style={{ width: '10%', backgroundColor: '#e7e5e4', justifyContent: 'center', flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold', alignItems: 'center' }}>Ear</Text>
            </View>
            <Table style={{ border: "0.5" }}>
              <TH>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text>Code</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text>Ring Type</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text>Side</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                  <Text>Coatring System</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                  <Text>White Coat</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.4}>
                  <Text>Other</Text>
                </TD>
              </TH>

              <TR>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text>{Coatingear.ring_earcode}</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text>{Coatingear.ring_eartype}</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text style={{ fontWeight: 'bold' }}>Int</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                  <Text>{Coatingear.intcoating}</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                  <Text>{Coatingear.intwhite}</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.4}>
                  <Text>{Coatingear.intother}</Text>
                </TD>
              </TR>
            </Table>
          </View>

        </View>

        {/*can & end forming*/}
        <View style={[styles.textBoldfonts10, { marginBottom: 1, width: '100%' }]}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '10%', backgroundColor: '#e7e5e4', justifyContent: 'center', flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold', alignItems: 'center' }}>Can & end forming</Text>
            </View>
            <Table style={{ border: "0.5" }}>
              <TH>
                <TD style={{ justifyContent: 'center' }} >
                  <Text>Internal side strip</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }}>
                  <Text>Internal Other</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }}>
                  <Text>Seaming</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }}>
                  <Text>Seaming Other</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }}>
                  <Text>Machine No</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }}>
                  <Text>packaging</Text>
                </TD>
              </TH>
              
              <TR>
                <TD style={{ justifyContent: 'center' }} >
                  <Text>{Formingall[0].internalside}</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }}>
                  <Text style={{ fontWeight: 'bold' }}>{Formingall[0].internalother}</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }}>
                  <Text>{Formingall[0].seaming}</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }}>
                  <Text>{Formingall[0].seamingother}</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }}>
                  <Text>{Formingall[0].machineno}</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }}>
                  <Text>{Formingall[0].packaging}</Text>
                </TD>
              </TR>
              
            </Table>
          </View>

          <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={{ fontWeight: 'bold' }}>Note : {Formingall[0].note}</Text>
            </View>
          </View>

        </View>

        {/* guldeline for product pack*/}

        <View style={{ flexDirection: 'row' }}>
          <Text style={[styles.textBoldfonts10, { fontWeight: 'bold' }]}>
            Product shelf-life : __{Guidelineall[0].shelfyears == null ? "_______" : Guidelineall[0].shelfyears}__
            years / at  __{Guidelineall[0].shelfdeg != null ? Guidelineall[0].shelfdeg : "_______"}__ Deg C</Text>
        </View>
        <View style={[styles.textBoldfonts10, { flexDirection: 'row', marginBottom: 1, width: '100%' }]}>
          <View style={{ width: '10%', backgroundColor: '#e7e5e4', justifyContent: 'center', flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold', alignItems: 'center' }}>Guideline for product pack</Text>
          </View>
          <Table style={{ border: "0.5" }} weightings={[0.3, 0.3, 0.4]}>
            <TH>
              <TD style={{ justifyContent: 'center' }} >
                <Text>#NO</Text>
              </TD>
              <TD style={{ justifyContent: 'center' }}>
                <Text>Detail</Text>
              </TD>
              <TD style={{ justifyContent: 'center' }}>
                <Text>Other</Text>
              </TD>
            </TH>

            <TR>
              <TD style={{ justifyContent: 'flex-start' }} >
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>1.Product group</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }}>
                <Text style={{ marginLeft: 3 }}>{Guidelineall[0].groupother}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }}>
                <Text style={{ marginLeft: 3 }}>{Guidelineall[0].groupother}</Text>
              </TD>
            </TR>

            <TR>

              <TD style={{ justifyContent: 'flex-start' }} >
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>2.Detail of product</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }}>
                <Text style={{ marginLeft: 3 }}>{Guidelineall[0].detailproduct}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }}>
                <Text style={{ marginLeft: 3 }}></Text>
              </TD>
            </TR>

            <TR>

              <TD style={{ justifyContent: 'flex-start' }} >
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>3.PH</Text>
              </TD>
              <TD style={{ justifyContent: 'cflex-start' }}>
                <Text style={{ marginLeft: 3 }}>{Guidelineall[0].ph}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }}>
                <Text style={{ marginLeft: 3 }}></Text>
              </TD>
            </TR>

            <TR>

              <TD style={{ justifyContent: 'flex-start' }} >
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>4.Ingredients</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }}>
                <Text style={{ marginLeft: 3 }}>{Guidelineall[0].ingredients}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }}>
                <Text style={{ marginLeft: 3 }}>
                  {Guidelineall[0].ingredientother}{Guidelineall[0].ingredients_acid}{Guidelineall[0].ingredients_chioride}
                </Text>
              </TD>
            </TR>

            <TR>

              <TD style={{ justifyContent: 'flex-start' }} >
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>5.Seamer conditin</Text>
              </TD>
              <TD style={{ justifyContent: 'cenflex-startter' }}>
                <Text style={{ marginLeft: 3 }}>{Guidelineall[0].seamercondition}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }}>
                <Text style={{ marginLeft: 3 }}></Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ justifyContent: 'flex-start' }} >
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>6.Sterilization process</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }}>
                <Text style={{ marginLeft: 3 }}>{Guidelineall[0].sterilization}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }}>
                <Text style={{ marginLeft: 3 }}>{Guidelineall[0].sterilizationother}{Guidelineall[0].sterilization_cooker}{Guidelineall[0].sterilization_retort}</Text>
              </TD>
            </TR>

          </Table>
        </View>


        {/* Regulation */}
        <View style={[styles.textBoldfonts10, { marginBottom: 1, width: '100%' }]}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '10%', backgroundColor: '#e7e5e4', justifyContent: 'center', flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold', alignItems: 'center' }}>Regulation</Text>
            </View>
            <Table style={{ border: "0.5" }}>
              <TH>
                <TD style={{ justifyContent: 'center' }} >
                  <Text>Regulation typ</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }}>
                  <Text>Detail</Text>
                </TD>
              </TH>

              <TR>
                <TD style={{ justifyContent: 'flex-start' }} >
                  <Text style={{ marginLeft: 3 }}>{Regulationall[0].regulationtype}</Text>
                </TD>
                <TD style={{ justifyContent: 'flex-start' }}>
                  <Text style={{ marginLeft: 3 }}>{Regulationall[0].regulationother}</Text>
                </TD>
              </TR>
            </Table>
          </View>
        </View>

        {/* Request sample ----> */}

        <View style={[styles.textBoldfonts10, { marginBottom: 1, width: '100%' }]}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '10%', backgroundColor: '#e7e5e4', justifyContent: 'center', flexDirection: 'row' }}>
              <Text style={{ fontWeight: 'bold', alignItems: 'center' }}>Request sample</Text>
            </View>
            <Table style={{ border: '0.5' }}>
              <TH>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text>Proof plate</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text>Can ( pcs )</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text>End (pcs)</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.7}>
                  <Text>Note</Text>
                </TD>
              </TH>

              <TR>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text style={{ marginLeft: 3 }}>{Requestsampleall[0].proofplate}</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text style={{ marginLeft: 3 }}>{Requestsampleall[0].requestcan}</Text>
                </TD>
                <TD style={{ justifyContent: 'center' }} weighting={0.1}>
                  <Text> {Requestsampleall[0].requestend} </Text>
                </TD>
                <TD style={{ justifyContent: 'flex-start' }} weighting={0.7}>
                  <Text> {Requestsampleall[0].requestnote}</Text>
                </TD>

              </TR>
            </Table>
          </View>
        </View>

        {/* spect planning  */}

        <View style={[styles.textBoldfonts10, { flexDirection: 'row', marginBottom: 1, width: '100%' }]}>

          <Table style={{ border: "0.5" }}>
            <TH>
              <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                <Text>Substrate</Text>
              </TD>
              <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                <Text>BODY</Text>
              </TD>
              <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                <Text>TOP END</Text>
              </TD>
              <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                <Text>BOTTON END</Text>
              </TD>
              <TD style={{ justifyContent: 'center' }} weighting={0.2}>
                <Text>TAB</Text>
              </TD>
              <TD style={{ justifyContent: 'center' }} weighting={0.3}>
                <Text>COMPONENT CODE</Text>
              </TD>

            </TH>

            <TR>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>Sheet size (T x W x L)</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.sheetsize}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectop.sheetsize}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbottom.sheetsize}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectab.sheetsize}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'space-between' }} weighting={0.3}>
                <Text style={{ marginLeft: 2 }} >หูอ๊อก/ถ้วยอ๊อก : </Text>
                <Text style={{ marginRight: 2 }}> {Componentall[0].earwelding}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>Straight / Scall</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.straightscroll}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectop.straightscroll}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbottom.straightscroll}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectab.straightscroll}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'space-between' }} weighting={0.3}>
                <Text style={{ marginLeft: 2 }} >หู : </Text>
                <Text style={{ marginRight: 2 }}>{Componentall[0].ear}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>Tin coating /Alloy</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.tin}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectop.tin}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbottom.tin}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectab.tin}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'space-between' }} weighting={0.3}>
                <Text style={{ marginLeft: 2 }} >ห่วง : </Text>
                <Text style={{ marginRight: 2 }}>  {Componentall[0].ring}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>Temper / Hardness</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.temper}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectop.temper}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbottom.temper}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectab.temper}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'space-between' }} weighting={0.3}>
                <Text style={{ marginLeft: 2 }}>ฝาใน : </Text>
                <Text style={{ marginLeft: 2 }}>  {Componentall[0].innerlid}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>Bright / Stone / Mate</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.bright}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.bright}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.bright}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.bright}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'space-between' }} weighting={0.3}>
                <Text style={{ marginLeft: 2 }}>ฝาบน :</Text>
                <Text style={{ marginRight: 2 }}>{Componentall[0].toplid}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>Row x Layer</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.row}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectop.row}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbottom.row}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectab.row}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'space-between' }} weighting={0.3}>
                <Text style={{ marginLeft: 2 }}>ฝาพลาสติก : </Text>
                <Text style={{ marginRight: 2 }}> {Componentall[0].plasticlid}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>Film Code</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.filmcode}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectop.filmcode}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbottom.filmcode}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectab.filmcode}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'space-between' }} weighting={0.3}>
                <Text style={{ marginLeft: 2 }}>ฝาอลูมิเนียม : </Text>
                <Text style={{ marginRight: 2 }}>{Componentall[0].aluminumlid}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>Item No</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.itemno}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectop.itemno}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbottom.itemno}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectab.itemno}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.3}>
                {/* <Text style={{ marginLeft: 2 }}>{Specbody.itemno}</Text> */}
              </TD>
            </TR>

            <TR>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3, fontWeight: 'bold' }}>Ref . Item WC .</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbody.refitemwc}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectop.refitemwc}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Specbottom.refitemwc}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>{Spectab.refitemwc}</Text>
              </TD>
              <TD style={{ justifyContent: 'flex-start' }} weighting={0.3}>

              </TD>
            </TR>

          </Table>
        </View>


        <Text style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />

        <View render={({ pageNumber }) => (
          pageNumber % 2 === 0 && (
            <View style={{ background: 'red', }}>
              <Text>I'm only visible in odd pages!</Text>
            </View>
          )
        )} />

      </Page>
    }
  </>
  )
}

export default Reportv2