import React, { useState, useEffect, useRef } from 'react'
// import Showdetailjournal from '../../../form01/components/showdetailjournal';
import AllDetailpartb from '../../components/allDetailpartb';
import generatePDF from 'react-to-pdf';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import Reportv3 from '../reportpdf/reportv3';
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer'


function PreViewPrinting({ id }) {
    return (
        <div style={{ height: 750 }}>
            <PDFViewer width="100%" height="100%" >
                <Document>
                    <Reportv3 jourId={id} />
                </Document>
            </PDFViewer>
        </div>
    )
}


const PdfpartBsuccess = ({ idjour }) => {
    const targetRef = useRef();
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }

    return (
        <div>
            <Button onClick={() => handleShow()} size='sm' variant="light"><FaFileDownload /> PreView Printing</Button>
            <div ref={targetRef}>
                <AllDetailpartb journalId={idjour} />
                <img src={require('../../../images/approve_8622624.png')} style={{ width: 50, height: 'auto', borderRadius: 3 }} />
            </div>

            <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview part B</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {/* <PDFViewer width="100%" height="100%"> */}
                    <PreViewPrinting id={idjour} />
                    {/* </PDFViewer> */}


                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PdfpartBsuccess