import DataTable from 'react-data-table-component';
import React from 'react'
import Actionlist from './actionlistprepass';
import { FaFilePdf, FaFileSignature, FaCheckCircle } from "react-icons/fa";
const columns = [
    {
        name: 'TDS code',
        sortable: true,
        selector: row => <div>
        <div>{row.tdscode} || v.{row.revision}</div>
        {row.salerunnumber!=""?<div>({row.salerunnumber})</div>:<div></div>}</div>,
        grow: 0.7,
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltype,
        grow: 0.7,
    },
    {
        name: 'customer / product ',
        grow: 1.5,
        selector: row => <div>
            <div>customer : {row.customername}</div>
            <div>product : {row.productname}</div>
            </div>,
    },
    // {
    //     name: 'product name',
    //     grow: 0.5,
    //     selector: row => row.productname,
    // },
    {
        name: 'film code',
        grow: 0.5,
        selector: row => row.filmcode,
    },
    {
        name: 'job status',
        selector: row => <div>{row.statusprove_Tds == 1 && <label style={{ color: '#0e9a07' }}><FaCheckCircle /> proof job</label>}</div>,
        grow: 0.5,
    },
    // {
    //     name: 'confirm job',
    //     selector: row =><div>{row.statusConfirm==1&&<label style={{color:'#0e9a07'}}><FaCheckCircle />ยืนยันแล้ว</label>}</div> ,
    // },
    {
        name: 'createdate',
        selector: row => row.createdate,
        grow: 0.5,
    },
    {
        name: 'Action',
         grow: 1,
        cell: row =><Actionlist id={row.journalId} journalcode={row.tdscode} typeproduct={row.typeproduct}/>,
    },

];

const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};



const Journaltableprepass = ({datatable}) => {
    var dataresult = datatable;

    return (
    
        <DataTable
            columns={columns}
            data={dataresult}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover
        />
    )
}

export default Journaltableprepass