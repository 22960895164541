import React from 'react'
import DataTable from 'react-data-table-component';
import PopUpDetailReject from './PopUpDetailReject';
import ViewJournaldetail from '../componentallistallpprovebyuser/viewJournaldetail';

const columns = [
    {
        name: 'TDS code',
        sortable: true,
        grow: 0.7,
        selector: row => row.tdscode,
    },
    {
        name: 'Journal Type',
        selector: row => row.journaltypes,
        grow: 1.5,
    },
    {
        name: 'customer/productname',
        grow: 1.5,
        selector: row => <div style={{ flexDirection: 'column' }}>
            <div>: {row.customername}</div>
            <div>: {row.productname}</div>
        </div>,
    },
    {
        name: 'ผู้รับผิดชอบ',
        selector: row =><div>
           <div>คุณ :{row.to_reject}</div> 
        <div>{row.departments}</div>
        </div>
             ,
    },
    // {
    //     name: 'แผนก',
    //     selector: row => row.departmentUser,
    // },
    {
        name: 'createdate',
        selector: row => row.createdate,
    },
    // {
    //     name: 'statusflow',
    //     selector: row => row.statusflow,
    // },
    {
        name: 'Action',
        grow: 1.5,
        cell: row =><div style={{flexDirection:'row',display:'flex'}}>
         <div style={{marginRight:5}}>
            <ViewJournaldetail jourId={row.journalId} codeId={row.tdscode}/>
        </div>
        <div>
        <PopUpDetailReject  
        journalId={row.journalId} 
        emcode={row.user_reject} 
        statusFlow={row.statusflow}
        tdscode={row.tdscode}
        />
        </div>
        </div>,
    },

];


const customStyles = {
    headRow: {
        style: {
            border: 'none',
            backgroundColor: '#28B463'
        },
    },
    headCells: {
        style: {
            color: '#ffff',
            fontSize: '14px',
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 244, 244)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

const Tablelistrejectstatus = ({ values }) => {

    var data = values;

    return (
        <DataTable
            columns={columns}
            data={data}
            // selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover

        />
    )

}

export default Tablelistrejectstatus