import React, { useState, useEffect } from 'react'
import FetchApi from '../../../customhooks/functionFetchApi'
import { FaCheck } from "react-icons/fa";

const Statusall = ({ id, statusNow, stateSuccesfull }) => {
    // console.log(statusNow)
    // console.log(stateSuccesfull)
    const FetchApis = new FetchApi();
    const [journallist, setJournallist] = useState([])

    useEffect(() => {
        loadingdata();
    }, [id]);

    async function loadingdata() {
        await FetchApis.FethcGet(`/journal/getStatejournalById/${id}`).then((res) => {
            if (res) {
                setJournallist(res.data.data)
            }
        })
    }
    if (journallist.length == 0) {
        <div></div>
    } else {
        return (
            <div className='row-page'>
                {statusNow > stateSuccesfull ? <div><label style={{ color: 'green' }}><FaCheck /> เสร็จเรียบร้อย</label></div> : 
                <div className='row-page'>
                    
                    {journallist.map((item, i) => (
                        <div>
                            {item.active == 1 ?
                                <div style={{
                                    color: 'green', textTransform: 'uppercase', borderRadius: 100,
                                    backgroundColor: 'green', width: 8, height: 8 , marginRight: 2
                                }}>
                                </div>
                                : <div style={{
                                    color: 'red', borderRadius: 100, textTransform: 'uppercase',
                                    backgroundColor: 'red', width: 8, height: 8 , marginRight: 2
                                }}>
                                </div>}
                        </div>
                    )
                    )}
                </div>}
            </div>

        )
    }
}

export default Statusall