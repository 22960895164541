import React, { useState, useEffect } from 'react'
import { Document, Page, Text, View, PDFViewer, Image } from '@react-pdf/renderer'
import { Table, TD, TH, TR } from "@ag-media/react-pdf-table";
import FetchApi from '../../../../customhooks/functionFetchApi';

import { styles } from "../../../../css/style";
import iconcheck from '../../../../images/9044063_checkbox_checked_icon.png';
import iconuncheck from '../../../../images/9044062_checkbox_icon.png';


const Journalpartb = (props) => {

  const FetchApis = new FetchApi();
  const [Sequence1, setSequence1] = useState([])
  const [Sequence2, setSequence2] = useState([])
  const [Sequence3, setSequence3] = useState([])
  const [Sequence4, setSequence4] = useState([])
  const [Sequence5, setSequence5] = useState([])
  const [Sequence6, setSequence6] = useState([])
  const [Sequence7, setSequence7] = useState([])

  

  const ojectdata = {
    packId: '',
    protype: '',
    sideitemnumber: '',
    sidename: '',
    sidewfw: '',
    com1itemnumber: '',
    com1name: '',
    com1wfw: '',
    com2itemnumber: '',
    com2name: '',
    com2wfw: '',
    journalId: ""
  }

  const [prodcutExt, setProdcutExt] = useState({ ...ojectdata })
  const [productInt, setProductInt] = useState({ ...ojectdata })
  const [productSpray, setProductSpray] = useState({ ...ojectdata })
  const [productCopoer, setProductCopoer] = useState({ ...ojectdata })
  const [prodcutPro_lacq, setprodcutPro_lacq] = useState({ ...ojectdata })
  const [packtestcheckbox, setPacktestcheckbox] = useState([])
  const [packercheckbox, setPackercheckbox] = useState([])
  const [jouranlheader, setJournalhead] = useState([])
  const [loading, setLoading] = useState(true)

  const [prodatahead, setProdatahead] = useState({ /// header
    sidetinext: '',
    sidetinint: '',
    com1tinext: '',
    com1tinint: '',
    com2tinext: '',
    com2tinint: '',
    activebom: "",
    auditdep: "",
    packtest: [],
    packer: [],
    refproduct: "",
    journalId: ""
  })


  useEffect(() => {
    setLoading(true)

    getjournalbyid()
    gettechnicalpartb()
    getproductlist()
    pakctestlist()

    // setTimeout(() => {
    //   setLoading(false)
    // }, 1200);
  }, [])

  function getjournalbyid() {
    FetchApis.FethcGet(`/journal/journalbyId/${props.jourId}`).then(res => {
      if (res) {
        setJournalhead(res.data.data[0])
        setLoading(false)
      }

    })
  }


  // ข้อมูล part b
  function gettechnicalpartb() {
    FetchApis.FethcGet(`/reportjournal/journalPartB/${props.jourId}`).then(res => {

      res.data.data.forEach(element => {
        // console.log(element)
        if (element.sequence == '1st') {
          setSequence1(element)
        }
        else if (element.sequence == '2nd') {
          setSequence2(element)
        }
        else if (element.sequence == '3rd') {
          setSequence3(element)
        }
        else if (element.sequence == '4th') {
          setSequence4(element)
        }
        else if (element.sequence == '5th') {
          setSequence5(element)
        }
        else if (element.sequence == '6th') {
          setSequence6(element)
        }
        else if (element.sequence == '7th') {
          setSequence7(element)
        }

      });
    })
  }

  //
  function getproductlist() {
    FetchApis.FethcGet(`/reportjournal/getpacktestByjournalid/${props.jourId}`).then(res => {
      if (res.status == 200) {

        if (res.data.data.length > 0) {
          setProdatahead({ ...res.data.data[0] })
          var packtestData = res.data.data[0].packtest;
          var packerData = res.data.data[0].packer;

          var packsplice = packtestData.split(',')

          setPacktestcheckbox(packsplice)

          var packersplit = packerData.split(',')
          setPackercheckbox(packersplit)
        }

      }
    })
  }


  function pakctestlist() {
    FetchApis.FethcGet(`/reportjournal/getproductByjournalid/${props.jourId}`).then(res => {

      if (res.status == 200) {

        const prolist = res.data.data
        if (res.data.data.length > 0) {
          for (let index = 0; index < prolist.length; index++) {
            const datalist = prolist[index];

            if (datalist.protype == 'ext') {
              setProdcutExt(datalist)
            }

            if (datalist.protype == 'int') {
              setProductInt(datalist)
            }

            if (datalist.protype == 'spray') {
              setProductSpray(datalist)
            }
            if (datalist.protype == 'lacq') {
              setprodcutPro_lacq(datalist)
            }
            if (datalist.protype == 'copper') {
              setProductCopoer(datalist)
            }

          }
        }



      }
    })
  }

  return (<>
      <View>
        <View style={[styles.textBoldfonts95, { width: '100%' }]}>
          <Table style={{ marginBottom: 1, border: "0.5" }}>
            <TH>
              <TD weighting={0.6} style={[{ flexDirection: 'row', justifyContent: 'center' }]}>
                <Text style={{ fontWeight: 'bold', fontSize: 12 }}>TECHNICAL DATA SHEET PART ( B )</Text>
              </TD>
              <TD weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>Ref. MK. NO : {jouranlheader.tdscode}</Text>
              </TD>
              <TD weighting={0.2}>
                <Text style={{ marginLeft: 3 }}>Date : {jouranlheader.createdatetime}</Text>
              </TD>
            </TH>
          </Table>
          {/* technical data 1st*/}

          <Table style={{ border: '0.5' }}>
            <TH style={{
              border: '1px solid #909497',
              backgroundColor: '#e5e7e9'
            }} tdStyle={{ padding: '1px' }}
            >
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text> Sequence </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.2}>
                <Text> Coating System </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.2}>
                <Text> Body </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.2}>
                <Text>Top end</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.2}>
                <Text> Botton end </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.2}>
                <Text> other </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.2}>
                <Text> Ref .FG </Text>
              </TD>
            </TH>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}> 1st </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Side </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence1.bodyside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence1.topside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence1.bottomside}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence1.otherdetail}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Body : {Sequence1.rfFgbody} </Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Color shade </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence1.bodycolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence1.topcolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence1.bottomcolor}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Top : {Sequence1.rfFgtop}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>FM. code </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence1.bodyfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence1.topfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence1.bottomfm}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Bottom : {Sequence1.rfFgbottom}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>DFW(gsm)/wfw(Kg/1000 Sht) </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence1.bodydfw_min != "" ? <Text>{Sequence1.bodydfw_min} - {Sequence1.bodydfw_max} / {Sequence1.bodywfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence1.topdfw_min != "" ? <Text>{Sequence1.bottomdfw_min} - {Sequence1.bottomdfw_max} / {Sequence1.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence1.bottomdfw_min != "" ? <Text>{Sequence1.bottomdfw_min} - {Sequence1.bottomdfw_max} / {Sequence1.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Temp (C)</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence1.bodytemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence1.toptemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence1.bottomtemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Speed (sph) / No.Rubber</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence1.bodyspeed != "" ? <Text>{Sequence1.bodyspeed} / {Sequence1.bodyrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence1.topspeed != "" ? <Text>{Sequence1.topspeed} / {Sequence1.toprubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence1.bottomspeed != "" ? <Text>{Sequence1.bottomspeed} / {Sequence1.bottomrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
          </Table>

          {/* technical data 2nd */}
          <Table style={{ border: '0.5' }}>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}> 2nd </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Side </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence2.bodyside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence2.topside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence2.bottomside}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence2.otherdetail}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Body : {Sequence2.rfFgbody} </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Color shade </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence2.bodycolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence2.topcolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence2.bottomcolor}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Top : {Sequence2.rfFgtop}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>FM. code </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence2.bodyfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence2.topfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence2.bottomfm}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Bottom : {Sequence2.rfFgbottom}</Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>DFW(gsm)/wfw(Kg/1000 Sht) </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence2.bodydfw_min != "" ? <Text>{Sequence2.bodydfw_min} - {Sequence2.bodydfw_max} / {Sequence2.bodywfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence2.topdfw_min != "" ? <Text>{Sequence2.bottomdfw_min} - {Sequence2.bottomdfw_max} / {Sequence2.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence2.bottomdfw_min != "" ? <Text>{Sequence2.bottomdfw_min} - {Sequence2.bottomdfw_max} / {Sequence2.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Temp (C)</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence2.bodytemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence2.toptemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence2.bottomtemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Speed (sph) / No.Rubber</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence2.bodyspeed != "" ? <Text>{Sequence2.bodyspeed} / {Sequence2.bodyrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence2.topspeed != "" ? <Text>{Sequence2.topspeed} / {Sequence2.toprubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence2.bottomspeed != "" ? <Text>{Sequence2.bottomspeed} / {Sequence2.bottomrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
          </Table>

          {/* technical data 3rd */}
          <Table style={{ border: '0.5' }}>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}> 3rd </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Side </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence3.bodyside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence3.topside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence3.bottomside}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence3.otherdetail}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Body : {Sequence3.rfFgbody} </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Color shade </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence3.bodycolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence3.topcolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence3.bottomcolor}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Top : {Sequence3.rfFgtop}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>FM. code </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence3.bodyfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence3.topfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence3.bottomfm}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Bottom : {Sequence3.rfFgbottom}</Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>DFW(gsm)/wfw(Kg/1000 Sht) </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence3.bodydfw_min != "" ? <Text>{Sequence3.bodydfw_min} - {Sequence3.bodydfw_max} / {Sequence3.bodywfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence3.topdfw_min != "" ? <Text>{Sequence3.bottomdfw_min} - {Sequence3.bottomdfw_max} / {Sequence3.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence3.bottomdfw_min != "" ? <Text>{Sequence3.bottomdfw_min} - {Sequence3.bottomdfw_max} / {Sequence3.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Temp (C)</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence3.bodytemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence3.toptemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence3.bottomtemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Speed (sph) / No.Rubber</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence3.bodyspeed != "" ? <Text>{Sequence3.bodyspeed} / {Sequence3.bodyrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence3.topspeed != "" ? <Text>{Sequence3.topspeed} / {Sequence3.toprubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence3.bottomspeed != "" ? <Text>{Sequence3.bottomspeed} / {Sequence3.bottomrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
          </Table>

          {/* technical data 4th */}
          <Table style={{ border: '0.5' }}>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}> 4th </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Side </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence4.bodyside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence4.topside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence4.bottomside}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence4.otherdetail}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Body : {Sequence4.rfFgbody} </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Color shade </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence4.bodycolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence4.topcolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence4.bottomcolor}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Top : {Sequence4.rfFgtop}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>FM. code </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence4.bodyfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence4.topfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence4.bottomfm}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Bottom : {Sequence4.rfFgbottom}</Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>DFW(gsm)/wfw(Kg/1000 Sht) </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence4.bodydfw_min != "" ? <Text>{Sequence4.bodydfw_min} - {Sequence4.bodydfw_max} / {Sequence4.bodywfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence4.topdfw_min != "" ? <Text>{Sequence4.bottomdfw_min} - {Sequence4.bottomdfw_max} / {Sequence4.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence4.bottomdfw_min != "" ? <Text>{Sequence4.bottomdfw_min} - {Sequence4.bottomdfw_max} / {Sequence4.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Temp (C)</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence4.bodytemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence4.toptemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence4.bottomtemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Speed (sph) / No.Rubber</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence4.bodyspeed != "" ? <Text>{Sequence4.bodyspeed} / {Sequence4.bodyrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence4.topspeed != "" ? <Text>{Sequence4.topspeed} / {Sequence4.toprubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence4.bottomspeed != "" ? <Text>{Sequence4.bottomspeed} / {Sequence4.bottomrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
          </Table>

          {/* technical data 5th */}
          <Table style={{ border: '0.5' }}>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}> 5th </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Side </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence5.bodyside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence5.topside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence5.bottomside}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence5.otherdetail}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Body : {Sequence5.rfFgbody} </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Color shade </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence5.bodycolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence5.topcolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence5.bottomcolor}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Top : {Sequence5.rfFgtop}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>FM. code </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence5.bodyfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence5.topfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence5.bottomfm}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Bottom : {Sequence5.rfFgbottom}</Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>DFW(gsm)/wfw(Kg/1000 Sht) </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence5.bodydfw_min != "" ? <Text>{Sequence5.bodydfw_min} - {Sequence5.bodydfw_max} / {Sequence5.bodywfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence5.topdfw_min != "" ? <Text>{Sequence5.bottomdfw_min} - {Sequence5.bottomdfw_max} / {Sequence5.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence5.bottomdfw_min != "" ? <Text>{Sequence5.bottomdfw_min} - {Sequence5.bottomdfw_max} / {Sequence5.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Temp (C)</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence5.bodytemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence5.toptemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence5.bottomtemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Speed (sph) / No.Rubber</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence5.bodyspeed != "" ? <Text>{Sequence5.bodyspeed} / {Sequence5.bodyrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence5.topspeed != "" ? <Text>{Sequence5.topspeed} / {Sequence5.toprubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence5.bottomspeed != "" ? <Text>{Sequence5.bottomspeed} / {Sequence5.bottomrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
          </Table>

          {/* technical data 6th */}
          <Table style={{ border: '0.5' }}>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}> 6th </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Side </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence6.bodyside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence6.topside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence6.bottomside}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence6.otherdetail}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Body : {Sequence6.rfFgbody} </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Color shade </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence6.bodycolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence6.topcolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence6.bottomcolor}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Top : {Sequence6.rfFgtop}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>FM. code </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence6.bodyfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence6.topfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence6.bottomfm}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Bottom : {Sequence6.rfFgbottom}</Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>DFW(gsm)/wfw(Kg/1000 Sht) </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence6.bodydfw_min != "" ? <Text>{Sequence6.bodydfw_min} - {Sequence6.bodydfw_max} / {Sequence6.bodywfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence6.topdfw_min != "" ? <Text>{Sequence6.bottomdfw_min} - {Sequence6.bottomdfw_max} / {Sequence6.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence6.bottomdfw_min != "" ? <Text>{Sequence6.bottomdfw_min} - {Sequence6.bottomdfw_max} / {Sequence6.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Temp (C)</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence6.bodytemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence6.toptemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence6.bottomtemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Speed (sph) / No.Rubber</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence6.bodyspeed != "" ? <Text>{Sequence6.bodyspeed} / {Sequence6.bodyrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence6.topspeed != "" ? <Text>{Sequence6.topspeed} / {Sequence6.toprubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence6.bottomspeed != "" ? <Text>{Sequence6.bottomspeed} / {Sequence6.bottomrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
          </Table>

          {/* technical data 7th */}
          <Table style={{ border: '0.5' }}>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}> 7th </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Varnish top coat </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence7.bodyside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence7.topside}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence7.bottomside}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> {Sequence7.otherdetail}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Body : {Sequence7.rfFgbody} </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Color shade </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence7.bodycolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence7.topcolor}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence7.bottomcolor}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Top : {Sequence7.rfFgtop}</Text>
              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>FM. code </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence7.bodyfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence7.topfm}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence7.bottomfm}</Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> Bottom : {Sequence7.rfFgbottom}</Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>DFW(gsm)/wfw(Kg/1000 Sht) </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence7.bodydfw_min != "" ? <Text>{Sequence7.bodydfw_min} - {Sequence7.bodydfw_max} / {Sequence7.bodywfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence7.topdfw_min != "" ? <Text>{Sequence7.bottomdfw_min} - {Sequence7.bottomdfw_max} / {Sequence7.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence7.bottomdfw_min != "" ? <Text>{Sequence7.bottomdfw_min} - {Sequence7.bottomdfw_max} / {Sequence7.bottomwfw}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text></Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text> </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Temp (C)</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence7.bodytemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence7.toptemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>{Sequence7.bottomtemp}</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold' }}>  </Text></TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text style={{ fontWeight: 'bold' }}>Speed (sph) / No.Rubber</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence7.bodyspeed != "" ? <Text>{Sequence7.bodyspeed} / {Sequence7.bodyrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence7.topspeed != "" ? <Text>{Sequence7.topspeed} / {Sequence7.toprubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                {Sequence7.bottomspeed != "" ? <Text>{Sequence7.bottomspeed} / {Sequence7.bottomrubber}</Text> : <Text></Text>}
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.2}>
                <Text>  </Text>
              </TD>
            </TR>
          </Table>

          {/* Tin - Coating */}
          <Table style={{ border: '0.5', marginTop: 1 }}>
            <TH style={{ backgroundColor: '#e5e7e9' }} >
              <TD weighting={0.1}><Text>Tin-Coating</Text></TD>
              <TD weighting={0.3}>
                <Text style={{ marginRight: 50 }}>Ext :{prodatahead.sidetinext} </Text>
                <Text>Int :{prodatahead.sidetinint}</Text>
              </TD>
              <TD weighting={0.3}>
                <Text style={{ marginRight: 50 }}>Ext :{prodatahead.com1tinext} </Text>
                <Text>Int :50</Text>
              </TD>
              <TD weighting={0.3}>
                <Text style={{ marginRight: 50 }}>Ext :{prodatahead.com1tinint} </Text>
                <Text>Int :50</Text>
              </TD>
            </TH>

            <TH>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text>Production System</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <Text>Side Seams</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <Text>Compound</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <Text>Compound</Text>
              </TD>
            </TH>

            <TH style={{ backgroundColor: '#e5e7e9' }}>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.1}>
                <Text> </Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>Item Number</TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>Name</TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>WFW(kg/1000 pcs)</TD>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>Item Number</TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>Name</TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>WFW(kg/1000 pcs)</TD>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>Item Number</TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>Name</TD>
                <TD style={{ flexDirection: 'row', justifyContent: 'center' }}>WFW(kg/1000 pcs)</TD>
              </TD>
            </TH>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold', marginLeft: 1 }}>Ext</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{prodcutExt.sideitemnumber}</Text>
                  <Text>{prodcutExt.sidename}</Text>
                  <Text>{prodcutExt.sidewfw}</Text>
                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{prodcutExt.com1itemnumber}</Text>
                  <Text>{prodcutExt.com1name}</Text>
                  <Text>{prodcutExt.com1wfw}</Text>
                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{prodcutExt.com2itemnumber}</Text>
                  <Text>{prodcutExt.com2name}</Text>
                  <Text>{prodcutExt.com2wfw}</Text>
                </View>

              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold', marginLeft: 1 }}>Int</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{productInt.sideitemnumber}</Text>
                  <Text>{productInt.sidename}</Text>
                  <Text>{productInt.sidewfw}</Text>
                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{productInt.com1itemnumber}</Text>
                  <Text>{productInt.com1name}</Text>
                  <Text>{productInt.com1wfw}</Text>
                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{productInt.com2itemnumber}</Text>
                  <Text>{productInt.com2name}</Text>
                  <Text>{productInt.com2wfw}</Text>
                </View>

              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold', marginLeft: 1 }}>Spray lacq.</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{productSpray.sideitemnumber}</Text>
                  <Text>{productSpray.sidename}</Text>
                  <Text>{productSpray.sidewfw}</Text>
                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{productSpray.com1itemnumber}</Text>
                  <Text>{productSpray.com1name}</Text>
                  <Text>{productSpray.com1wfw}</Text>
                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>

                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{productSpray.com2itemnumber}</Text>
                  <Text>{productSpray.com2name}</Text>
                  <Text>{productSpray.com2wfw}</Text>
                </View>

              </TD>
            </TR>


            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold', marginLeft: 1 }}>Copper wire(kg/1000 pcs)</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{productCopoer.sideitemnumber}</Text>
                  <Text>{productCopoer.sidename}</Text>
                  <Text>{productCopoer.sidewfw}</Text>
                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{productCopoer.com1itemnumber}</Text>
                  <Text>{productCopoer.com1name}</Text>
                  <Text>{productCopoer.com1wfw}</Text>
                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>

                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{productCopoer.com2itemnumber}</Text>
                  <Text>{productCopoer.com2name}</Text>
                  <Text>{productCopoer.com2wfw}</Text>
                </View>

              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold', marginLeft: 1 }}>Lacq Stemp rivet(kg/1000 pcs)</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{prodcutPro_lacq.sideitemnumber}</Text>
                  <Text>{prodcutPro_lacq.sidename}</Text>
                  <Text>{prodcutPro_lacq.sidewfw}</Text>
                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{prodcutPro_lacq.com1itemnumber}</Text>
                  <Text>{prodcutPro_lacq.com1name}</Text>
                  <Text>{prodcutPro_lacq.com1wfw}</Text>
                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>

                <View style={{ flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                  <Text>{prodcutPro_lacq.com2itemnumber}</Text>
                  <Text>{prodcutPro_lacq.com2name}</Text>
                  <Text>{prodcutPro_lacq.com2wfw}</Text>
                </View>

              </TD>
            </TR>

            <TR>
              <TD style={{ flexDirection: 'row', justifyContent: 'flex-start' }} weighting={0.1}>
                <Text style={{ fontWeight: 'bold', marginLeft: 1 }}>Product Pack test</Text>
              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'column', justifyContent: 'space-around', width: '100%' }}>
                  <Text style={{ fontWeight: 'bold' }}> Pack test : {packtestcheckbox[0]}</Text>
                  <Text style={{ fontWeight: 'bold' }}> Packer : {packercheckbox[0]}</Text>

                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>
                <View style={{ flexDirection: 'column', justifyContent: 'space-around', width: '100%' }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold' }}> Audit Dep :</Text>
                    {prodatahead.auditdep == 1 ? <Image src={iconcheck} cache={false} style={{ width: 10, height: 10 }} /> : <Image src={iconuncheck} cache={false} style={{ width: 10, height: 10 }} />}
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold' }}> Active Bom :</Text>
                    {prodatahead.activebom == 1 ? <Image src={iconcheck} cache={false} style={{ width: 10, height: 10 }} /> : <Image src={iconuncheck} cache={false} style={{ width: 10, height: 10 }} />}
                  </View>


                </View>

              </TD>
              <TD style={{ flexDirection: 'row', justifyContent: 'center' }} weighting={0.3}>

                <View style={{ flexDirection: 'column', justifyContent: 'space-around', width: '100%' }}>
                  <Text style={{ fontWeight: 'bold' }} > Ref.Porduct</Text>
                  <Text>{prodatahead.refproduct}</Text>
                </View>

              </TD>
            </TR>
          </Table>

        </View>
    
      </View>

      <Text style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} fixed />
    </>
  )
}

export default Journalpartb