import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FetchApi from '../../../customhooks/functionFetchApi';
import Showdetailjournal from '../../../form01/components/showdetailjournal';
import Addproductionsystem from './addproductionsystem';
// import Journaldetialpartb from '../../components/componentrdnewpartb/journaldetailpartb';
import AllDetailpartb from '../../components/allDetailpartb';
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Actionapprove from '../../components/componentapprove/actionapprove';

import Rdaddnew1stcoating from '../../components/componentrdnewpartb/rdaddnew1stcoating';
import Rdaddnew2ndcoating from '../../components/componentrdnewpartb/rdaddnew2ndcoating';
import Rdaddnew3rdcoating from '../../components/componentrdnewpartb/rdaddnew3rdcoating';
import Rdaddnew4thcoating from '../../components/componentrdnewpartb/rdaddnew4thcoating';
import Rdaddnew5thcoating from '../../components/componentrdnewpartb/rdaddnew5thcoating';
import Rdaddnew6thcoating from '../../components/componentrdnewpartb/rdaddnew6thcoating';
import Rdaddnew7thcoating from '../../components/componentrdnewpartb/rdaddnew7thcoating';

const datasub = {
  spectype: "",
  sheetsize: "",
  flatscroll: "",
  straightscroll: "",
  tin: "",
  temper: "",
  bright: "",
  row: "",
  filmcode: "",
  itemno: "",
  journalId: "",
  tdscode: ""
}


function FormEditJournal({ jourId, handleShow }) {

  //coating 1 st
  const coanting1st_body = useSelector((state) => state.coating1st.body);
  const coanting1st_top = useSelector((state) => state.coating1st.top);
  const coanting1st_bottom = useSelector((state) => state.coating1st.bottom);
  const coanting1st_note = useSelector((state) => state.coating1st.notedail);

  // coating 2 nd
  const coanting1nd_body = useSelector((state) => state.coating2nd.body);
  const coanting1nd_top = useSelector((state) => state.coating2nd.top);
  const coanting1nd_bottom = useSelector((state) => state.coating2nd.bottom);
  const coanting1nd_note = useSelector((state) => state.coating2nd.notedail);

  // coating 3 rd 
  const coanting1rd_body = useSelector((state) => state.coating3rd.body);
  const coanting1rd_top = useSelector((state) => state.coating3rd.top);
  const coanting1rd_bottom = useSelector((state) => state.coating3rd.bottom);
  const coanting1rd_note = useSelector((state) => state.coating3rd.notedail);

  // coating 4 th
  const coanting1th_body = useSelector((state) => state.coating4th.body);
  const coanting1th_top = useSelector((state) => state.coating4th.top);
  const coanting1th_bottom = useSelector((state) => state.coating4th.bottom);
  const coanting1th_note = useSelector((state) => state.coating4th.notedail);

  //coating 5 th
  const coanting2th_body = useSelector((state) => state.coating5th.body);
  const coanting2th_top = useSelector((state) => state.coating5th.top);
  const coanting2th_bottom = useSelector((state) => state.coating5th.bottom);
  const coanting2th_note = useSelector((state) => state.coating5th.notedail);

  // coanting 6 th 
  const coanting3th_body = useSelector((state) => state.coating6th.body);
  const coanting3th_top = useSelector((state) => state.coating6th.top);
  const coanting3th_bottom = useSelector((state) => state.coating6th.bottom);
  const coanting3th_note = useSelector((state) => state.coating6th.notedail);

  //coanting 7 th
  const coanting4th_body = useSelector((state) => state.coating7th.body);
  const coanting4th_top = useSelector((state) => state.coating7th.top);
  const coanting4th_bottom = useSelector((state) => state.coating7th.bottom);
  const coanting4th_note = useSelector((state) => state.coating7th.notedail);
  // notedetail

  // product
  const productExt = useSelector((state) => state.product.dataext);
  const productInt = useSelector((state) => state.product.dataint);
  const productSpray = useSelector((state) => state.product.dataspray);
  const productCopper = useSelector((state) => state.product.datacopper);
  const prodcutLacq = useSelector((state) => state.product.datallacq);

  const productItem = useSelector((state) => state.product.productitem);


  const FetchApis = new FetchApi()
  const [key, setKey] = useState('jouranl');

  const [adddatatype, setAdddatatype] = useState({ ...datasub })

  useEffect(() => {

    FetchApis.FethcGet(`/journal/journalbyId/${jourId}`).then((datafetch) => {
      if (datafetch.data.status == 200) {
        var codeid = datafetch.data.data[0].tdscode;
        var JournalIds = datafetch.data.data[0].journalId;

        setAdddatatype({
          ...adddatatype,
          tdscode: codeid,
          journalId: JournalIds
        })

      }
    })
  }, [])



  // บันทึกข้อมูล
  const submitform = (e) => {
    // e.preventDefault();

    Swal.fire({
      title: "บันทึกเอกสาร",
      text: "คุณแน่ใจที่บันทึกเอกสาร ใช่หรือไม่ ! ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ใช่",
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {

      if (result.isConfirmed) {
        InsertPartB();
        toast.success('บันทึกเรียบร้อยแล้ว', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // transition: Bounce,
        });
        console.log(productItem)
        setTimeout(() => {
          //  handleShow(false)
          window.location.reload();
        }, 1800);

      }
    });

  }


  function InsertPartB() {
    //coating 1 st

    FetchApis.FethcPartbPost('/technical/createtechnical', '1st', `${coanting1st_note}`, `${adddatatype.tdscode}`, `${adddatatype.journalId}`, coanting1st_body, coanting1st_top, coanting1st_bottom)

    // // coating 2 nd

    FetchApis.FethcPartbPost('/technical/createtechnical', '2nd', `${coanting1nd_note}`, `${adddatatype.tdscode}`, `${adddatatype.journalId}`, coanting1nd_body, coanting1nd_top, coanting1nd_bottom)

    // // coating 3 rd

    FetchApis.FethcPartbPost('/technical/createtechnical', '3rd', `${coanting1rd_note}`, `${adddatatype.tdscode}`, `${adddatatype.journalId}`, coanting1rd_body, coanting1rd_top, coanting1rd_bottom)


    // // coating 4 th

    FetchApis.FethcPartbPost('/technical/createtechnical', '4th', `${coanting1th_note}`, `${adddatatype.tdscode}`, `${adddatatype.journalId}`, coanting1th_body, coanting1th_top, coanting1th_bottom)


    // // coating 5 th

    FetchApis.FethcPartbPost('/technical/createtechnical', '5th', `${coanting2th_note}`, `${adddatatype.tdscode}`, `${adddatatype.journalId}`, coanting2th_body, coanting2th_top, coanting2th_bottom)


    // // coating 6 th

    FetchApis.FethcPartbPost('/technical/createtechnical', '6th', `${coanting3th_note}`, `${adddatatype.tdscode}`, `${adddatatype.journalId}`, coanting3th_body, coanting3th_top, coanting3th_bottom)


    // // coating 7 th

    FetchApis.FethcPartbPost('/technical/createtechnical', '7th', `${coanting4th_note}`, `${adddatatype.tdscode}`, `${adddatatype.journalId}`, coanting4th_body, coanting4th_top, coanting4th_bottom)

    FetchApis.FethcPost(`/packtest/createPacktest`, productItem[0])

    if (productExt != "") {
      FetchApis.FethcPost(`/productionsystem/creatproduc`, productExt)
    }
    if (productInt != "") {
      FetchApis.FethcPost(`/productionsystem/creatproduc`, productInt)
    }
    if (productSpray != "") {
      FetchApis.FethcPost(`/productionsystem/creatproduc`, productSpray)
    }
    if (productCopper != "") {
      FetchApis.FethcPost(`/productionsystem/creatproduc`, productCopper)
    }
    if (prodcutLacq != "") {
      FetchApis.FethcPost(`/productionsystem/creatproduc`, prodcutLacq)
    }

  }

  return (
    <div className='form-body-page'>
      <form >
        <div className='end-flex-row'>
          <Button size='sm' variant="success" onClick={() => submitform()} style={{ marginRight: 10, fontSize: 12 }}>บันทึกข้อมูล</Button>
         
          <Actionapprove journalId={jourId}/>
        </div>
        <hr></hr>
  
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="jouranl" title="TECHNICAL DATA SHEET A" style={{ backgroundColor: 'aliceblue' }}>

            <Showdetailjournal id={jourId} />

          </Tab>
          <Tab eventKey="journaldetailpartb" title="TECHNICAL DATA SHEET B" style={{ backgroundColor: 'aliceblue' }}>
            {/* <Journaldetialpartb /> */}

            <AllDetailpartb journalId={jourId} />

          </Tab>
          <Tab eventKey="tincoating" title="Production System" style={{ backgroundColor: 'aliceblue' }}>
            <Addproductionsystem journalId={jourId} />
          </Tab>

          <Tab eventKey="rdaddnew1stcoating" title="เพิ่มข้อมูล ( 1 ST )" style={{ backgroundColor: 'aliceblue' }}>
            {<Rdaddnew1stcoating jourId={jourId} />}
          </Tab>

          <Tab eventKey="rdaddnew2ndcoating" title="เพิ่มข้อมูล( 2 ND )" style={{ backgroundColor: 'aliceblue' }}>
            <Rdaddnew2ndcoating />
          </Tab>
          <Tab eventKey="rdaddnew3rdcoating" title="เพิ่มข้อมูล( 3 RD )" style={{ backgroundColor: 'aliceblue' }}>
            <Rdaddnew3rdcoating />
          </Tab>
          <Tab eventKey="rdaddnew4thcoating" title="เพิ่มข้อมูล( 4 th )" style={{ backgroundColor: 'aliceblue' }}>
            <Rdaddnew4thcoating />
          </Tab>
          <Tab eventKey="rdaddnew5thcoating" title="เพิ่มข้อมูล( 5 th )" style={{ backgroundColor: 'aliceblue' }}>
            <Rdaddnew5thcoating />
          </Tab>
          <Tab eventKey="rdaddnew6thcoating" title="เพิ่มข้อมูล( 6 th )" style={{ backgroundColor: 'aliceblue' }}>
            <Rdaddnew6thcoating />
          </Tab>
          <Tab eventKey="rdaddnew7thcoating" title="เพิ่มข้อมูล( 7 th )" style={{ backgroundColor: 'aliceblue' }}>
            <Rdaddnew7thcoating />
          </Tab>

        </Tabs>
      </form>
    </div>
  )
}


const Rdcreatenewpart = ({ datavalues }) => {

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(value) {
    setShow(value);
  }

  return (
    <div >

      <Button variant="primary" size="sm" style={{ fontSize: 10 }} onClick={() => handleShow(true)}
      >จัดการ เอกสาร</Button>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>สร้างเอกสาร ( Part B )</Modal.Title>
        </Modal.Header>
        <Modal.Body><FormEditJournal jourId={datavalues} handleShow={handleShow} /></Modal.Body>
      </Modal>
    </div>
  )
}

export default Rdcreatenewpart